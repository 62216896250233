import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const SearchIcon = ({ size }) => (
  <Svg viewBox="0 0 20 20" size={size}>
    <path
      id="Icon"
      d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

SearchIcon.propTypes = {
  size: PropTypes.number,
};

SearchIcon.defaultProps = {
  size: 24,
};
