import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const MagnifyingMinusIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      d="M7 10H13M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

MagnifyingMinusIcon.propTypes = {
  size: PropTypes.number,
};

MagnifyingMinusIcon.defaultProps = {
  size: 24,
};
