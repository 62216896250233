import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  leftSection: {
    height: '100vh',
    backgroundColor: `${theme.palette.primary[1400]}!important`,
    borderRadius: 0,
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    padding: '2rem',
  },
  copyRight: {
    position: 'absolute',
    bottom: 20,
    left: 30,
    color: theme.palette.primary[400],
    display: 'flex',
  },
  logo: {
    width: '40ch',
  },
  rightSection: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: theme.palette.primary[1300],
  },
  loginContent: {
    padding: '2rem',
    width: '40ch',
    height: 'max-content',
  },
  signInButton: {
    color: `${theme.palette.white}!important`,
  },
  divider: {
    color: theme.palette.primary[800],
    fontWeight: 400,
    margin: '2rem 0!important',
    '&:after, &:before': {
      borderTop: `1px solid ${theme.palette.primary[800]}!important`,
    },
  },
  subtitle: {
    fontWeight: `${400}!important`,
    color: theme.palette.primary[300],
  },
  loginWidgets: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    marginBottom: '2rem',
    alignItems: 'center',
  },
  formButton: {
    marginTop: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
    color: theme.palette.primary[600],
  },
  block: {
    display: 'block',
    marginTop: `${theme.spacing(1)}!important`,
  },
  link: {
    marginLeft: '10px',
  },
});
