import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const PoweredBySamIcon = ({ size }) => (
  <Svg viewBox="0 0 88 10" size={size}>
    <path
      id="Vector"
      d="M80.9102 0.743164H82.1683L84.3557 6.08426H84.4362L86.6237 0.743164H87.8818V7.61412H86.8954V2.64207H86.8317L84.8053 7.60406H83.9867L81.9603 2.63872H81.8965V7.61412H80.9102V0.743164Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_2"
      d="M73.8358 7.61412H72.7354L75.208 0.743164H76.4057L78.8783 7.61412H77.7779L75.8353 1.99121H75.7817L73.8358 7.61412ZM74.0203 4.92345H77.59V5.79574H74.0203V4.92345Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_3"
      d="M69.9392 2.54832C69.9034 2.23072 69.7558 1.98469 69.4963 1.81023C69.2369 1.63354 68.9103 1.54519 68.5167 1.54519C68.2348 1.54519 67.9911 1.58992 67.7853 1.67939C67.5795 1.76662 67.4196 1.88739 67.3055 2.04172C67.1937 2.19381 67.1378 2.36715 67.1378 2.56174C67.1378 2.72502 67.1758 2.86592 67.2518 2.98447C67.3301 3.10301 67.4319 3.20254 67.5571 3.28306C67.6846 3.36134 67.8211 3.42732 67.9664 3.481C68.1118 3.53244 68.2516 3.57494 68.3858 3.60849L69.0568 3.78295C69.276 3.83663 69.5008 3.90932 69.7312 4.00102C69.9615 4.09272 70.1751 4.2135 70.372 4.36335C70.5688 4.51321 70.7276 4.69885 70.8484 4.92028C70.9714 5.1417 71.0329 5.40675 71.0329 5.7154C71.0329 6.10458 70.9322 6.45014 70.7309 6.75208C70.5319 7.05403 70.2422 7.29223 69.862 7.46669C69.484 7.64115 69.0266 7.72838 68.4898 7.72838C67.9754 7.72838 67.5303 7.64674 67.1545 7.48347C66.7788 7.32019 66.4847 7.0887 66.2722 6.78899C66.0597 6.48704 65.9423 6.12918 65.9199 5.7154H66.96C66.9801 5.96367 67.0606 6.17056 67.2015 6.33607C67.3447 6.49934 67.5269 6.62124 67.7484 6.70176C67.972 6.78004 68.2169 6.81918 68.4831 6.81918C68.7761 6.81918 69.0367 6.77333 69.2648 6.68163C69.4952 6.58769 69.6764 6.45797 69.8083 6.29246C69.9403 6.12471 70.0063 5.929 70.0063 5.70534C70.0063 5.5018 69.9481 5.33517 69.8318 5.20545C69.7177 5.07572 69.5623 4.96837 69.3655 4.88337C69.1709 4.79838 68.9506 4.72345 68.7045 4.65859L67.8926 4.43716C67.3424 4.28731 66.9063 4.067 66.5842 3.77624C66.2644 3.48547 66.1044 3.10077 66.1044 2.62213C66.1044 2.22624 66.2118 1.88068 66.4265 1.58545C66.6412 1.29021 66.932 1.06096 67.2988 0.897681C67.6656 0.73217 68.0794 0.649414 68.5401 0.649414C69.0054 0.649414 69.4158 0.731051 69.7714 0.894326C70.1293 1.0576 70.4111 1.28238 70.6169 1.56867C70.8226 1.85273 70.93 2.17928 70.9389 2.54832H69.9392Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_4"
      d="M56.7486 9.54661C56.6301 9.54661 56.5193 9.53543 56.4165 9.51306C56.3136 9.4907 56.2319 9.46609 56.1715 9.43925L56.3326 8.91252C56.5361 8.97515 56.7173 8.99752 56.8761 8.97962C57.0349 8.96397 57.1758 8.89239 57.2988 8.76491C57.4218 8.63965 57.5314 8.4473 57.6276 8.18785L57.8155 7.66448L55.9199 2.46094H56.5674L58.0973 6.82574H58.1443L59.6741 2.46094H60.3216L58.1409 8.42941C58.0492 8.67768 57.9362 8.88457 57.8021 9.05008C57.6679 9.21783 57.5124 9.34196 57.3357 9.42248C57.1613 9.50523 56.9656 9.54661 56.7486 9.54661Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_5"
      d="M49.8076 7.61412V0.743164H50.4082V3.44391H50.4618C50.5289 3.28958 50.6251 3.13078 50.7504 2.9675C50.8778 2.80423 51.0445 2.66779 51.2502 2.5582C51.4583 2.44637 51.7199 2.39045 52.0353 2.39045C52.458 2.39045 52.8293 2.50228 53.1492 2.72595C53.469 2.94737 53.7184 3.25827 53.8973 3.65862C54.0762 4.05675 54.1657 4.52085 54.1657 5.05093C54.1657 5.58325 54.0762 6.04959 53.8973 6.44995C53.7206 6.85031 53.4724 7.16232 53.1525 7.38598C52.8349 7.60965 52.4659 7.72148 52.0454 7.72148C51.7322 7.72148 51.4706 7.66556 51.2603 7.55373C51.0523 7.4419 50.8846 7.30435 50.7571 7.14107C50.6296 6.97556 50.5312 6.81452 50.4618 6.65796H50.388V7.61412H49.8076ZM50.3981 5.04087C50.3981 5.45688 50.4596 5.82593 50.5826 6.148C50.7079 6.46784 50.8868 6.71947 51.1194 6.90287C51.3543 7.08404 51.6383 7.17462 51.9716 7.17462C52.3138 7.17462 52.6034 7.08068 52.8405 6.89281C53.0776 6.70269 53.2576 6.4466 53.3807 6.12452C53.5037 5.80244 53.5652 5.44123 53.5652 5.04087C53.5652 4.64498 53.5037 4.28824 53.3807 3.97064C53.2599 3.65303 53.0809 3.40141 52.8439 3.21577C52.6068 3.02789 52.316 2.93395 51.9716 2.93395C51.6361 2.93395 51.3509 3.02454 51.1161 3.2057C50.8834 3.38464 50.7056 3.6329 50.5826 3.95051C50.4596 4.26587 50.3981 4.62933 50.3981 5.04087Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_6"
      d="M41.009 7.72148C40.5863 7.72148 40.2161 7.60965 39.8985 7.38598C39.5809 7.16232 39.3326 6.85031 39.1537 6.44995C38.977 6.04959 38.8887 5.58325 38.8887 5.05093C38.8887 4.52085 38.977 4.05675 39.1537 3.65862C39.3326 3.25827 39.582 2.94737 39.9019 2.72595C40.2217 2.50228 40.5941 2.39045 41.0191 2.39045C41.3322 2.39045 41.5928 2.44637 41.8008 2.5582C42.0088 2.66779 42.1754 2.80423 42.3007 2.9675C42.4259 3.13078 42.5232 3.28958 42.5926 3.44391H42.6462V0.743164H43.2468V7.61412H42.663V6.65796H42.5926C42.5232 6.81452 42.4248 6.97556 42.2973 7.14107C42.1698 7.30435 42.001 7.4419 41.7907 7.55373C41.5827 7.66556 41.3221 7.72148 41.009 7.72148ZM41.0795 7.17462C41.4127 7.17462 41.6968 7.08404 41.9316 6.90287C42.1665 6.71947 42.3454 6.46784 42.4684 6.148C42.5937 5.82593 42.6563 5.45688 42.6563 5.04087C42.6563 4.62933 42.5948 4.26587 42.4718 3.95051C42.3488 3.6329 42.1698 3.38464 41.935 3.2057C41.7001 3.02454 41.415 2.93395 41.0795 2.93395C40.735 2.93395 40.4443 3.02789 40.2072 3.21577C39.9723 3.40141 39.7934 3.65303 39.6704 3.97064C39.5496 4.28824 39.4892 4.64498 39.4892 5.04087C39.4892 5.44123 39.5507 5.80244 39.6737 6.12452C39.7967 6.4466 39.9757 6.70269 40.2105 6.89281C40.4476 7.08068 40.7373 7.17462 41.0795 7.17462Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_7"
      d="M34.7088 7.72166C34.2279 7.72166 33.8119 7.60982 33.4608 7.38616C33.1096 7.16026 32.8379 6.84825 32.6455 6.45013C32.4554 6.04977 32.3604 5.58902 32.3604 5.06788C32.3604 4.54898 32.4554 4.08823 32.6455 3.68564C32.8379 3.28081 33.1029 2.96432 33.4406 2.73619C33.7806 2.50581 34.1731 2.39062 34.6182 2.39062C34.8978 2.39062 35.1673 2.44207 35.4268 2.54495C35.6862 2.6456 35.9188 2.80105 36.1246 3.01129C36.3326 3.2193 36.497 3.4821 36.6178 3.79971C36.7386 4.11507 36.799 4.48859 36.799 4.92026V5.2155H32.773V4.68877H36.1884C36.1884 4.35775 36.1213 4.06028 35.9871 3.79635C35.8551 3.53019 35.6706 3.31995 35.4335 3.16562C35.1987 3.01129 34.9269 2.93413 34.6182 2.93413C34.2917 2.93413 34.0043 3.02136 33.756 3.19582C33.5077 3.37027 33.3132 3.60065 33.1723 3.88694C33.0336 4.17323 32.9631 4.48636 32.9609 4.82633V5.14169C32.9609 5.551 33.0313 5.90886 33.1723 6.21528C33.3154 6.51946 33.5178 6.75543 33.7795 6.92318C34.0412 7.09092 34.351 7.1748 34.7088 7.1748C34.9526 7.1748 35.1662 7.13678 35.3496 7.06073C35.5353 6.98468 35.6907 6.88292 35.816 6.75543C35.9435 6.6257 36.0396 6.48368 36.1045 6.32935L36.6715 6.51387C36.5932 6.73082 36.4646 6.931 36.2857 7.11441C36.109 7.29781 35.8875 7.44543 35.6214 7.55726C35.3575 7.66686 35.0533 7.72166 34.7088 7.72166Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_8"
      d="M28.375 7.61417V2.46095H28.9554V3.25272H28.999C29.1019 2.99327 29.2808 2.78415 29.5358 2.62535C29.793 2.46431 30.0838 2.38379 30.4081 2.38379C30.4573 2.38379 30.5121 2.38491 30.5725 2.38714C30.6329 2.38938 30.6832 2.39162 30.7235 2.39385V3.0011C30.6966 2.99663 30.6497 2.98992 30.5826 2.98097C30.5155 2.97203 30.4428 2.96755 30.3645 2.96755C30.0961 2.96755 29.8568 3.02459 29.6465 3.13865C29.4385 3.25049 29.2741 3.40593 29.1534 3.60499C29.0326 3.80405 28.9722 4.03107 28.9722 4.28605V7.61417H28.375Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_9"
      d="M23.9295 7.72166C23.4487 7.72166 23.0326 7.60982 22.6815 7.38616C22.3303 7.16026 22.0586 6.84825 21.8662 6.45013C21.6761 6.04977 21.5811 5.58902 21.5811 5.06788C21.5811 4.54898 21.6761 4.08823 21.8662 3.68564C22.0586 3.28081 22.3236 2.96432 22.6614 2.73619C23.0013 2.50581 23.3939 2.39062 23.8389 2.39062C24.1185 2.39062 24.388 2.44207 24.6475 2.54495C24.9069 2.6456 25.1396 2.80105 25.3453 3.01129C25.5533 3.2193 25.7177 3.4821 25.8385 3.79971C25.9593 4.11507 26.0197 4.48859 26.0197 4.92026V5.2155H21.9937V4.68877H25.4091C25.4091 4.35775 25.342 4.06028 25.2078 3.79635C25.0758 3.53019 24.8913 3.31995 24.6542 3.16562C24.4194 3.01129 24.1476 2.93413 23.8389 2.93413C23.5124 2.93413 23.225 3.02136 22.9767 3.19582C22.7285 3.37027 22.5339 3.60065 22.393 3.88694C22.2543 4.17323 22.1838 4.48636 22.1816 4.82633V5.14169C22.1816 5.551 22.252 5.90886 22.393 6.21528C22.5361 6.51946 22.7385 6.75543 23.0002 6.92318C23.2619 7.09092 23.5717 7.1748 23.9295 7.1748C24.1733 7.1748 24.3869 7.13678 24.5703 7.06073C24.756 6.98468 24.9114 6.88292 25.0367 6.75543C25.1642 6.6257 25.2603 6.48368 25.3252 6.32935L25.8922 6.51387C25.8139 6.73082 25.6853 6.931 25.5064 7.11441C25.3297 7.29781 25.1082 7.44543 24.8421 7.55726C24.5782 7.66686 24.274 7.72166 23.9295 7.72166Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_10"
      d="M14.5245 7.61415L12.9678 2.46094H13.5985L14.8197 6.71167H14.8633L16.0812 2.46094H16.7153L17.9231 6.70161H17.97L19.1912 2.46094H19.822L18.2653 7.61415H17.6479L16.4267 3.39026H16.363L15.1418 7.61415H14.5245Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_11"
      d="M8.9369 7.72166C8.49181 7.72166 8.09816 7.60871 7.75595 7.38281C7.41598 7.15691 7.14982 6.84489 6.95747 6.44677C6.76512 6.04641 6.66895 5.58455 6.66895 5.06117C6.66895 4.53333 6.76512 4.06922 6.95747 3.66886C7.14982 3.26627 7.41598 2.95314 7.75595 2.72948C8.09816 2.50358 8.49181 2.39062 8.9369 2.39062C9.38199 2.39062 9.77452 2.50358 10.1145 2.72948C10.4545 2.95538 10.7206 3.26851 10.913 3.66886C11.1076 4.06922 11.2048 4.53333 11.2048 5.06117C11.2048 5.58455 11.1087 6.04641 10.9163 6.44677C10.724 6.84489 10.4567 7.15691 10.1145 7.38281C9.77452 7.60871 9.38199 7.72166 8.9369 7.72166ZM8.9369 7.1748C9.29476 7.1748 9.59782 7.07862 9.84609 6.88627C10.0944 6.69392 10.2822 6.43783 10.4097 6.11799C10.5394 5.79815 10.6043 5.44588 10.6043 5.06117C10.6043 4.67647 10.5394 4.32308 10.4097 4.00101C10.2822 3.67893 10.0944 3.4206 9.84609 3.22601C9.59782 3.03142 9.29476 2.93413 8.9369 2.93413C8.58127 2.93413 8.27821 3.03142 8.0277 3.22601C7.77944 3.4206 7.59044 3.67893 7.46072 4.00101C7.33323 4.32308 7.26948 4.67647 7.26948 5.06117C7.26948 5.44588 7.33323 5.79815 7.46072 6.11799C7.59044 6.43783 7.77944 6.69392 8.0277 6.88627C8.27597 7.07862 8.57904 7.1748 8.9369 7.1748Z"
      fill="#E1E1E1"
    />
    <path
      id="Vector_12"
      d="M0.169922 7.61412V0.743164H2.38755C2.87514 0.743164 3.28221 0.837103 3.60876 1.02498C3.93755 1.21062 4.1847 1.46336 4.35021 1.7832C4.51795 2.10304 4.60183 2.46314 4.60183 2.8635C4.60183 3.26386 4.51907 3.62508 4.35356 3.94715C4.18805 4.26699 3.94202 4.52085 3.61547 4.70873C3.28892 4.89437 2.88297 4.98719 2.39762 4.98719H0.653037V4.42356H2.38084C2.74094 4.42356 3.03953 4.35646 3.27662 4.22226C3.5137 4.08582 3.6904 3.90018 3.8067 3.66534C3.92524 3.43049 3.98452 3.16321 3.98452 2.8635C3.98452 2.56379 3.92524 2.29651 3.8067 2.06166C3.6904 1.82682 3.51258 1.64229 3.27326 1.5081C3.03618 1.3739 2.73535 1.3068 2.37078 1.3068H0.7973V7.61412H0.169922Z"
      fill="#E1E1E1"
    />
  </Svg>
);

PoweredBySamIcon.propTypes = {
  size: PropTypes.number,
};

PoweredBySamIcon.defaultProps = {
  size: 88,
};
