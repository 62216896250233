/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form } from 'lib/form';
import exportFromJSON from 'export-from-json';
import tokml from '@maphubs/tokml';
import GeoJSON from 'geojson';
import { pdf } from '@react-pdf/renderer';
import { useFormik } from 'formik/dist';
import moment from 'moment';
import { Time } from 'lib/time';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { useParams } from 'react-router';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { SharingIcon } from 'components/Icons';
import { ArrowUpIcon } from 'components/Icons/ArrowUpIcon';
import { v4 } from 'uuid';
import { DisclaimerExporting } from 'components/DisclaimerExporting/DisclaimerExporting';
import { PDFDocument } from './PDFDocument';
import { pdfStyles } from './style';

const validationSchema = Yup.object({
  name: Yup.string().min(1).required('Name is required'),
  type: Yup.string().min(1).required('Type is required'),
});

const options = [
  { value: 'csv', label: 'CSV' },
  { value: 'kml', label: 'KML' },
  { value: 'pdf', label: 'PDF' },
];

export const ObservationExport = ({ observations, simpleButton, isLoading }) => {
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });

  const handleClose = () => {
    setOpen(false);
  };

  const PDFContent = (fileName) => (
    <PDFDocument fileName={fileName} observations={observations} projectId={params.projId} />
  );

  // ---------------------- send data to API
  const submitFormData = async (values) => {
    const fileName = values.name;
    const auxObservation = [...observations].map((item) => {
      const sequenceId = `&sequenceId=${
        item.sequence?.asset?.type !== 'VIDEO' ? item.projectAssetSequenceId : item.time - 1
      }`;
      const url = `https://${window.location.hostname}/projects?projectId=${item.projectId || params.projId}&pastId=${
        item.projectAssetId
      }&observationId=${item.id}${sequenceId}`;
      return {
        [values.type === 'kml' ? 'name' : 'Name']: item.name,
        ...(values.type !== 'kml' && { Description: item.description }),
        ...(values.type === 'kml' && {
          description: `<![CDATA[<a href="${url}">Click to Open Observation</a><img src="${
            item.signedUrl || item.imagePath
          }">${item.name}]]>`,
        }),
        'Date Created': moment(item.createdAt).format('llll'),
        'Date Updated': moment(item.updatedAt).format('llll'),
        'Timeline Marker': Time.humanReadable({ seconds: +item.time + 1 }),
        Latitude: item.lat.toFixed(5),
        Longitude: item.lng.toFixed(5),
        ...(values.type !== 'kml' && {
          'Launch in CartoVid': url,
          Img: item.signedUrl || item.imagePath,
        }),
      };
    });
    switch (values.type) {
      case 'pdf':
        const blob = await pdf(PDFContent(fileName)).toBlob();
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `${fileName}.pdf`;
        link.href = data;
        link.click();
        break;
      case 'csv':
        auxObservation.unshift({ 'Date Exported': moment().format('llll') });
        exportFromJSON({ data: auxObservation, fileName, exportType: values.type });
        break;
      case 'kml':
        const geojson = GeoJSON.parse(auxObservation, {
          Point: ['Latitude', 'Longitude'],
        });
        let kml = tokml(geojson, {
          documentName: fileName,
          documentDescription: `Observations - Date Exported: ${moment().format('llll')}`,
          name: 'name',
          description: 'description',
        });
        kml = kml.replaceAll('&quot;', '"');
        kml = kml.replaceAll('&lt;', '<');
        kml = kml.replaceAll('&lt;', '<');
        kml = kml.replaceAll('&gt;', '>');
        const file = new Blob([kml], { type: 'kml' });
        const element = document.createElement('a');
        element.download = `${fileName}.kml`;
        element.href = URL.createObjectURL(file);
        element.click();
        break;

      default:
        break;
    }
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <>
      <Button
        color="primary"
        variant={!simpleButton && 'contained'}
        style={simpleButton ? pdfStyles.buttonGrapper : {}}
        onClick={() =>
          setValidateRole({
            open: true,
            action: [Permissions.PROJ_OBSERVATION],
            callBack: () => {
              setOpen(true);
              values.name = '';
              values.type = '';
            },
          })
        }
        disabled={isLoading}
      >
        {simpleButton ? (
          <>
            <Button color="secondary" variant="contained" style={pdfStyles.smallButton} size="small">
              <ArrowUpIcon size={15} />
            </Button>
            Export Observations
          </>
        ) : (
          <>
            <SharingIcon size={20} />
            <Typography ml={0.3}>Export Observations</Typography>
          </>
        )}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>
          <Typography variant="h4">Export Observations</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputLabel>File Name *</InputLabel>
              <TextField
                variant="outlined"
                value={values.name}
                id="name"
                name="name"
                {...Form.fieldErrorHelper(formik, 'name')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={formik.touched.name && formik.errors.name}
                helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Export Type *</InputLabel>
              <FormControl error={formik.touched.type && formik.errors.type} fullWidth>
                <Select
                  name="type"
                  onChange={(e) => formik.handleChange('type')(e.target.value)}
                  required
                  size="small"
                  fullWidth
                >
                  {options.map((item) => (
                    <MenuItem value={item.value} key={`${v4()}${new Date().getMilliseconds()}`}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.type && formik.errors.type ? formik.errors.type : null}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={handleClose} size="small" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" variant="contained" onClick={handleSubmit} size="small" fullWidth>
                Export
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DisclaimerExporting />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: observations?.length > 0 ? observations[0].projectId : '' }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );
};

ObservationExport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  observations: PropTypes.array,
  isLoading: PropTypes.bool,
  simpleButton: PropTypes.bool,
};

ObservationExport.defaultProps = {
  observations: [],
  isLoading: false,
  simpleButton: false,
};
