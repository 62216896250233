import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Form } from 'lib/form';
import * as Yup from 'yup';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import ProjectService from 'services/ProjectService';
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessage } from 'components/DialogMessage';
import { getUsers } from 'slices/userReducer';
import { fetchTeams } from 'slices/adminTeamsReducer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { InfoIcon } from 'components/Icons';
import { DisclaimerExporting } from 'components/DisclaimerExporting/DisclaimerExporting';
import { FormTextArea } from 'components/FormTextArea';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';
import { useStyles } from './styles';
import { ItemList } from './ItemList';

const validationSchema = Yup.object({
  // emails: Yup.array()
  message: Yup.string().min(1).required('Message is required'),
});

export const ObservationEmail = ({ observation, screenshot, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    projectAsset: { projId },
  } = useSelector((state) => state.profileProjectAssets);
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const { filters: userFilers } = useSelector((state) => state.users);
  const usersList = useSelector((state) => state.users.data);
  const teamsList = useSelector((state) => state.adminTeams.teams);
  const [recipient, setRecipient] = useState({
    id: '',
    name: '',
    type: '',
  });
  const users = usersList.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.email,
  }));
  const teams = teamsList.map((team) => ({
    label: team.name,
    value: team.id,
  }));

  // -------------------------- convert image to a blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // ----------------------------------- send data to API
  const submitFormData = (values) => {
    const proId = !projId ? observation.projectId : projId;
    setLoading(true);
    ProjectService.singUrlObservationPoint(proId, observation.id)
      .then((res) => res.data.data)
      .then((url) =>
        Promise.all([
          ProjectService.uploadObservationPointAsset(url, dataURItoBlob(screenshot)),
          ProjectService.emailObservationPoint(proId, observation.id, {
            emails: values.emails
              .filter((email) => email.type !== 'team')
              .map((email) => ({ label: email.name, value: email.id })),
            teams: values.emails.filter((team) => team.type === 'team').map((item) => item.id),
            message: values.message,
            email: 'true',
            projectId: proId,
          }),
        ])
      )
      .then(() => {
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        setDialogMessage('An error occurred while trying to send the observation by email.');
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      emails: [],
      message: '',
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  const { handleChange, handleBlur, handleSubmit } = formik;

  useEffect(() => {
    const sendFilters = {
      ...userFilers,
      sort_by: Object.values(userFilers.sort_by),
      page_size: 500,
    };
    dispatch(getUsers({ ...sendFilters, include: [] }));
    dispatch(fetchTeams({ page_size: 1000 }));
  }, []);

  const onAddEmail = () => {
    const setValues = () => {
      const prev = formik.values.emails;
      if (prev.find((el) => el.id === recipient.id)) {
        setDialogMessage('Email already added');
        return prev;
      }
      const newVal = recipient;
      if (recipient.type === 'user') {
        newVal.name = users.find((usr) => usr.value === recipient.id).label;
        newVal.description = newVal.id;
      }

      if (recipient.type === 'team') {
        newVal.name = teams.find((team) => team.value === recipient.id).label;
        newVal.description = '';
      }
      return [...prev, newVal];
    };
    formik.setFieldValue('emails', setValues());
    setRecipient({ type: '', id: '', name: '', description: '' });
  };

  const isDisabled = () => {
    if (recipient.type === 'email') {
      return !(recipient.id && recipient.name);
    }
    return !recipient.id;
  };

  const removeEmail = (emailId) => {
    const emails = formik.values.emails.filter((email) => email.id !== emailId);
    formik.setFieldValue('emails', emails);
  };

  return (
    <>
      <Dialog open onClose={handleClose} maxWidth="md">
        <DialogContent className={classes.content}>
          <Grid container direction="row" spacing={1}>
            <Grid container item direction="column" xs={6}>
              <div className={classes.title}>
                <div className={classes.addUserIcon}>
                  <UserPlusIcon size={25} />
                </div>
                <Typography mt={0.5} fontSize={16}>
                  Email Observation (or Inspection)
                </Typography>
                <span>An email will be sent to the following recipients:</span>
              </div>
              <ItemList items={formik.values.emails} size={160} onRemove={removeEmail} />
              <div>
                {!formik.values.emails && formik.touched.emails && (
                  <FormControl error={!formik.values.emails && formik.touched.emails} fullWidth>
                    <FormHelperText>Email is required</FormHelperText>
                  </FormControl>
                )}
                <br />
                <InputLabel>
                  <Typography mt={0.5}>Custom Message *</Typography>
                </InputLabel>
                <FormControl fullWidth>
                  <FormTextArea
                    placeholder="Custom Message"
                    rows="5"
                    value={formik.values.message}
                    id="message"
                    name="message"
                    {...Form.fieldErrorHelper(formik, 'message')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={loading}
                    required
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid container item direction="column" xs={6}>
              <div className={classes.form}>
                <InputLabel>Select Recipient Type</InputLabel>
                <FormControl fullWidth>
                  <Select
                    defaultValue=""
                    value={recipient.type}
                    size="small"
                    onChange={(el) => setRecipient({ ...recipient, type: el.target.value })}
                  >
                    <MenuItem id="users" value="user">
                      User
                    </MenuItem>
                    <MenuItem id="teams" value="team">
                      Team
                    </MenuItem>
                    <MenuItem id="emails" value="email">
                      Email
                    </MenuItem>
                  </Select>
                </FormControl>

                {recipient.type === 'user' && (
                  <>
                    <InputLabel>Select user</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={recipient.id}
                        size="small"
                        onChange={(el) => setRecipient({ ...recipient, id: el.target.value })}
                      >
                        {users.map((usr) => (
                          <MenuItem id={usr.value} value={usr.value}>
                            {usr.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                {recipient.type === 'team' && (
                  <>
                    <InputLabel>Select Team</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={recipient.id}
                        size="small"
                        onChange={(el) => setRecipient({ ...recipient, id: el.target.value })}
                      >
                        {teams.map((team) => (
                          <MenuItem id={team.value} value={team.value}>
                            {team.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                {recipient.type === 'email' && (
                  <>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      value={recipient.name}
                      size="small"
                      onChange={(el) => setRecipient({ ...recipient, name: el.target.value })}
                    />
                    <InputLabel>Email</InputLabel>
                    <TextField
                      value={recipient.id}
                      size="small"
                      onChange={(el) =>
                        setRecipient({ ...recipient, id: el.target.value, description: el.target.value })
                      }
                    />
                  </>
                )}

                <div className={classes.addButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    title="Add"
                    size="large"
                    onClick={onAddEmail}
                    disabled={isDisabled()}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClose}
                disabled={loading}
                size="large"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
                size="large"
                fullWidth
              >
                Send Now
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DisclaimerExporting />
            </Grid>
          </Grid>
        </DialogActions>
        <LoadingOverlay loading={loading} />
        <DialogMessage
          title={dialogMessage}
          isOpen={dialogMessage}
          icon={InfoIcon}
          confirmText="Ok"
          onConfirm={() => {
            setDialogMessage(false);
          }}
        />
      </Dialog>
    </>
  );
};

ObservationEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  observation: PropTypes.any,
  screenshot: PropTypes.string,
  handleClose: PropTypes.func,
};

ObservationEmail.defaultProps = {
  observation: { id: null },
  screenshot: '',
  handleClose: () => {},
};
