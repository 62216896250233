import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  containerDetail: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary[1400],
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 799,
    transform: `translateX('110%'})`,
    transition: 'transform 0.3s ease-out',
  },
  dialogLayout: {
    display: 'flex',
    gap: theme.spacing(2),
    // paddingTop: theme.spacing(1),
  },
  layoutCol1: {
    flex: '1 1 auto',
    width: '65vw',
    '& .tie-btn-delete': {
      display: 'none !important',
    },
    paddingRight: '40px',
  },
  layoutCol2: {
    flex: '1 0 auto',
    width: '23vw',
  },
  cropperControlContainer: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  assetContainer: {
    position: 'relative',
    '& .delete-btn': {
      position: 'absolute',
      top: '10px',
      left: '10px',
    },
  },
  playContainer: {
    position: 'absolute',
    top: '45%',
    zIndex: 1,
    display: 'flex',
    // background: theme.palette.white,
  },
  subTypography: {
    marginLeft: theme.spacing(0.2),
    color: '#848484',
    overflowWrap: 'break-word',
    textAlign: 'left',
  },
  buttonsContent: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: '#2B2B2B',
    alignItems: 'center',
  },
  cropperControlContainerV2: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '& #action-buttons': {
      marginLeft: '6px',
    },
    '& #zoom-buttons': {
      '& .MuiButtonBase-root': {
        marginRight: '4px',
      },
    },
  },
  column: { display: 'flex', flexDirection: 'column', padding: '20px' },
  row: { display: 'flex', flexDirection: 'row' },
  subTitleHeader: { color: 'gray', fontSize: '12px' },
  flexBetween: { display: 'flex', justifyContent: 'space-between' },
  flexMrTop: {
    display: 'flex',
    marginTop: '10px',
  },
});
