import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const SkipForwardIcon = ({ size }) => (
  <Svg viewBox="0 0 21 20" size={size}>
    <path
      id="Icon"
      d="M16.3327 4.16699V15.8337M6.83228 14.934L11.6979 11.0415C12.1428 10.6856 12.3652 10.5076 12.4457 10.2927C12.5164 10.1042 12.5164 9.89649 12.4457 9.70799C12.3652 9.49301 12.1428 9.31506 11.6979 8.95917L6.83228 5.06667C6.1388 4.51189 5.79206 4.2345 5.50025 4.23418C5.24646 4.23391 5.00639 4.34929 4.84806 4.54764C4.66602 4.7757 4.66602 5.21974 4.66602 6.10783V13.8928C4.66602 14.7809 4.66602 15.2249 4.84806 15.453C5.00639 15.6514 5.24646 15.7667 5.50025 15.7665C5.79206 15.7662 6.1388 15.4888 6.83228 14.934Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

SkipForwardIcon.propTypes = {
  size: PropTypes.number,
};

SkipForwardIcon.defaultProps = {
  size: 24,
};
