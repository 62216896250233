import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';
import { FilterIcon, SearchIcon } from 'components/Icons';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { setFilters } from 'slices/projectAssetActiveInspectionReducer';
import { SearchPanel } from './SearchPanel/SearchPanel';
import { useStyles } from '../styles';

export const ProjectAssetInspectionFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.projectAssetActiveInspections);

  // eslint-disable-next-line no-unused-vars
  const { displayPanel, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
        background: true,
      },
    },
  });

  const handleOpenFilters = () => {
    setFullWidth(false);
    displayPanel('search');
  };

  // functions to search in project asset inspections
  const filterOptions = (value) => {
    dispatch(
      setFilters({
        search: value,
      })
    );
  };

  return (
    <div className={classes.filters}>
      <TextField
        variant="outlined"
        placeholder="Search"
        className={classes.inputFilter}
        InputProps={{
          startAdornment: <SearchIcon size={20} />,
        }}
        value={filters.search}
        onChange={(e) => filterOptions(e.target.value)}
      />
      <Button
        size="medium"
        color="primary"
        variant="contained"
        className={`${classes.filterButton} ${filters.activated && classes.activatedFilter}`}
        onClick={() => handleOpenFilters()}
      >
        <FilterIcon size={15} /> Filters {filters.activated && 'On'}
      </Button>
    </div>
  );
};
