import PropTypes from 'prop-types';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Card } from 'components/Card/Card';
import { PlusIcon, DoubleCheckIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import SplashImage from 'images/accounts_splash.png';
import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { fetchUser, setPageFilter, setSearch, fetchUsers } from 'slices/adminAdminsReducer';
import { deleteUser } from 'slices/userReducer';
import { ModalInviteAccountLevelAdmin } from 'smartComponents/ModalInviteAccountLevelAdmin';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { adminOptions } from 'lib/topBarOptions';
import { TopBar } from 'components/TopBar/TopBar';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';
import { Modal, Box, Typography } from '@mui/material';
import { SearchPanel } from './SearchPanel';
import { InfoPanel } from './InfoPanel';
import { AdminPanel } from './AdminPanel';
import { createPortal } from 'react-dom';
import { Permissions as PermsSlide } from './Permissions';

const columns = [
  {
    Header: 'Name',
    // accessor: 'name', // accessor is the "key" in the data
    accessor: (row) => `${row.firstName} ${row.lastName}`,
  },
  {
    Header: 'Email',
    accessor: (row) => row.email,
  },
  {
    Header: 'Phone',
    accessor: (row) => row.phone,
  },
  {
    Header: 'Role(s)',
    accessor: (row) => row.roles.map((role) => role.displayName).join(', '),
  },
];

let debounceTimeout = null;

export const Admins = () => {
  const dispatch = useDispatch();
  const { users, loading, filters, usersCount } = useSelector((state) => state.adminAdmins);
  const search = filters.search || '';
  const [inviteAdminModalState, setInviteAdminModalState] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [uuidCopied, setUuidCopied] = useState('');
  const [openDelete, setOpenDelete] = useState({ status: false, details: {} });
  const [openRoles, setOpenRoles] = useState({ status: false, id: null });
  const [showPrivilegesModal, setShowPrivilegesModal] = useState({
    status: false,
    data: {},
  });

  const handleInviteAdminConfirm = () => {
    setInviteAdminModalState(false);
  };

  const handleInviteAdminCancel = () => {
    setInviteAdminModalState(false);
  };

  useEffect(() => {
    dispatch(setSearch());
  }, []);

  const table = useTable(
    {
      columns,
      data: users,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(usersCount / filters.page_size),
      totalCount: usersCount,
      pageLength: users.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const fetchData = (filterData) => {
    const sendFilters = {
      ...filterData,
      sort_by: Object.values(filterData.sort_by),
    };
    dispatch(fetchUsers(sendFilters));
    dispatch(fetchUser(sendFilters));
  };

  useEffect(() => {
    debounceTimeout = setTimeout(() => {
      fetchData(filters);
    }, 150);
    return () => clearTimeout(debounceTimeout);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      onClose: () => {
        // do nothing
      },
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
      actions: [
        {
          icon: PlusIcon,
          onClick: () => {
            setInviteAdminModalState(true);
          },
        },
      ],
    },
  });

  const handleRowClick = async (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    dispatch(fetchUser(rowData.original.id));
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
      {
        name: 'admin',
        component: AdminPanel,
      },
    ]);
    displayPanel('info');
  };

  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const handleUuidCopy = (row) => {
    navigator.clipboard.writeText(users[row.id].id);
    setUuidCopied(users[row.id].id);
  };

  const EmptyAdminsContainer = () => (
    <>
      <WidgetSplash
        alt="Projects Splash"
        title="Looks like you can use some help!"
        image={SplashImage}
        cta="Add another account admin."
        onClick={() => {
          setInviteAdminModalState(true);
        }}
      />
      {inviteAdminModalState && (
        <ModalInviteAccountLevelAdmin
          isOpen={inviteAdminModalState}
          onConfirm={handleInviteAdminConfirm}
          onCancel={handleInviteAdminCancel}
        />
      )}
    </>
  );

  // eslint-disable-next-line no-unused-vars
  const AdminActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => handleUuidCopy(row)}>Copy UUID</ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            setOpenRoles({ status: true, id: row.original.id });
          }}
        >
          Edit User Info & Participation
        </ContextMenuItem>
        <WidgetDivider />
        <ContextMenuItem
          onClick={() => {
            setOpenDelete({
              status: true,
              details: { id: row.original.id, name: `${row.original.firstName} ${row.original.lastName}` },
            });
          }}
        >
          Delete User
        </ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  AdminActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  const AdminsContainer = () => (
    <>
      <GeneralTable
        table={table}
        selectedRow={selectedRow}
        handleRowClick={handleRowClick}
        rowComponent={AdminActions}
      />
      <>{uuidCopied && <CopyUuidAlert id={uuidCopied} handleClose={() => setUuidCopied(false)} />}</>
      <TablePagination table={table} />
      {inviteAdminModalState && (
        <ModalInviteAccountLevelAdmin
          isOpen={inviteAdminModalState}
          onConfirm={handleInviteAdminConfirm}
          onCancel={handleInviteAdminCancel}
        />
      )}
    </>
  );

  const handleDeleteMember = () => {
    Promise((res) => {
      res(dispatch(deleteUser({ userId: openDelete.details.id })));
    }).then(() => {
      fetchData(filters);
      setOpenDelete(false);
    });
  };

  if (loading && users.length === 0) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="ADMINISTRATORS" />}
        actions={[
          {
            icon: UserPlusIcon,
            text: 'New',
            onClick: () => {
              setInviteAdminModalState(true);
            },
          },
        ]}
        showSidebar
      >
        <Loader loading={loading} height={250} />
      </AccountSettingsContainer>
    );
  }
  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="ADMINISTRATORS" />}
      actions={[
        {
          icon: UserPlusIcon,
          text: 'New',
          onClick: () => {
            setInviteAdminModalState(true);
          },
        },
      ]}
      showSidebar
    >
      <Card noPadding>
        <TopBar
          options={adminOptions}
          handleSearch={handleSearch}
          searchValue={search}
          activatedFilter={filters.activated}
        />
        {users.length > 0 ? <AdminsContainer /> : <EmptyAdminsContainer />}
        {showPrivilegesModal.status === true && (
          <Modal
            open={showPrivilegesModal.status}
            onClose={() => setShowPrivilegesModal({ status: false, data: {} })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                backgroundColor: '#333',
                width: 500,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Role
              </Typography>
            </Box>
          </Modal>
        )}
        <DialogMessage
          title={`Delete ${openDelete.details.name}`}
          content={`Are you sure you want to delete this ${openDelete.details.name}? This action cannot be undone`}
          icon={DoubleCheckIcon}
          isOpen={openDelete.status}
          onConfirm={handleDeleteMember}
          onCancel={() => setOpenDelete({ status: false, details: {} })}
          action={dialogMessageAction.delete}
        />
        {openRoles.status &&
          createPortal(
            <PermsSlide
              handleClose={(val) => {
                setOpenRoles({ status: false, id: null });
                if (val) fetchData(filters);
              }}
              userId={openRoles.id}
            />,
            document.getElementById('layout-area')
          )}
      </Card>
    </AccountSettingsContainer>
  );
};
