import React from 'react';
// import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { LayoutAuth } from 'components/LayoutAuth';
import AdminService from 'services/AdminService';

export const TGAdminAccountCreate = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [reqError, setReqError] = React.useState(false);
  // const error = useSelector((state) => state.auth.error);

  const formik = useFormik({
    initialValues: {
      email: '',
      fname: '',
      lname: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^\S*$/, 'Email can not contain space')
        .email('Invalid email address')
        .required('Required'),
      fname: Yup.string().required('Required'),
      lname: Yup.string().required('Required'),
    }),
    onSubmit: async ({ fname, lname, email }) => {
      setLoading(true);
      setReqError(false);
      try {
        const response = await AdminService.createTGAdmin({ fname, lname, email });
        if (response.status === 201) {
          history.push('/sa/accounts');
        } else {
          setLoading(false);
          setReqError(response);
        }
      } catch (err) {
        setLoading(false);
        if (err.response.data) {
          setReqError(`Error: ${err.response.data.message}`);
        } else if (err.message) {
          setReqError(`Error: ${err.message}`);
        } else {
          setReqError(`Error: ${err}`);
        }
      }
    },
  });

  return (
    <LayoutAuth title="Invite new Super-Admin" noReturn>
      <form onSubmit={formik.handleSubmit} noValidate>
        {reqError && (
          <Typography color="error" align="center" mb={1}>
            {reqError}
          </Typography>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel>First Name</InputLabel>
            <TextField
              value={formik.values.fname}
              error={!!(formik.touched.fname && formik.errors.fname)}
              helperText={formik.touched.fname && formik.errors.fname ? formik.errors.fname : null}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="First Name"
              name="fname"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Last Name</InputLabel>
            <TextField
              value={formik.values.lname}
              error={!!(formik.touched.lname && formik.errors.lname)}
              helperText={formik.touched.lname && formik.errors.lname ? formik.errors.lname : null}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Last Name"
              name="lname"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Email</InputLabel>
            <TextField
              value={formik.values.email}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Email"
              name="email"
              fullWidth
            />
          </Grid>
        </Grid>
        <Button type="submit" color="secondary" variant="contained" disabled={loading} fullWidth>
          Invite
        </Button>
      </form>
    </LayoutAuth>
  );
};
