import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  header: {
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
  },
  content: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  fullHeight: {
    height: '100%',
    width: '100%',
  },
  borderAssets: {
    borderTop: `1px solid ${theme.palette.primary[400]}`,
  },
  // classes for 2 columns
  input: {
    marginTop: theme.spacing(0.5),
  },
  listContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(0.5),
  },
  tableContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing(0.5),
    '& .MuiTable-root': {
      borderTop: 'none',
      backgroundColor: theme.palette.primary[1300],
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary[1200],
      },
      height: 'auto',
      '& th': {
        color: theme.palette.primary[200],
        fontSize: '12px',
        position: 'relative',
      },
      '& td': {
        color: theme.palette.primary[300],
        fontSize: '12px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      },
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.primary[1200],
      cursor: 'pointer',
    },
    '& .MuiButtonBase-root': {
      maxHeight: '10px',
    },
  },
  containerDetail: {
    paddingLeft: theme.spacing(0.5),
    overflowY: 'auto',
    height: '100%',
    position: 'relative',
    overflowX: 'hidden',
  },
  mapDetailContainer: {
    height: '47vh',
    borderRadius: '8px',
    overflow: 'hidden',
    marginTop: theme.spacing(0.5),
    '& > div': {
      height: '100%',
      width: '100%',
    },
    '& .mapDiv': {
      height: '100%',
      width: '100%',
    },
  },
  headerTabs: {
    minHeight: 'auto',
    '& .MuiTabs-scroller': {
      border: 'none',
      borderRadius: 'inherit',
      padding: 0,
    },
    '& .MuiTabs-flexContainer': {
      '& > :first-child': {
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(0),
      },
      '& > :last-child': {
        borderRight: 'none',
      },
    },
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      borderRight: `1px solid ${theme.palette.primary[500]}`,
      borderRadius: 'inherit',
      padding: `${theme.spacing(0.2)} ${theme.spacing(0.5)}`,
      maxHeight: '25px',
      minHeight: '25px',
      height: '25px',
      minWidth: 'auto',
      '&:hover': {
        color: theme.palette.secondary[500],
      },
      '&.Mui-selected': {
        color: theme.palette.secondary[500],
        borderRadius: 0,
      },
      '&.Mui-disabled': {
        color: theme.palette.primary[400],
        opacity: 0.4,
      },
    },
  },
  tabsDetail: {
    padding: `${theme.spacing(0.5)} 0`,
    '& .MuiTabs-root': {
      width: '100%',
    },
    '& .MuiTabs-flexContainer': {
      gap: '8px',
    },
    '& .MuiTab-root': {
      borderRight: 'none',
      padding: '8px 12px!important',
      '&.Mui-selected': {
        background: `var(--gray-800, ${theme.palette.primary[1200]})`,
        borderRadius: '6px',
      },
    },
  },
  bodyDetail: {
    padding: theme.spacing(0.5),
    overflowY: 'auto',
    maxHeight: '20vh',
    '& .MuiTableContainer-root': {
      height: 'auto',
    },
    '& .MuiTable-root': {
      borderTop: 'none',
      '& tbody tr': {
        cursor: 'default',
      },
    },
    '& .Mui-checked': {
      color: `${theme.palette.secondary.main}!important`,
    },
  },
  bodyDetail2: {
    maxHeight: '20vh',
  },
  imageContent: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    backgroundColor: theme.palette.primary[1300],
  },
  buttonGrapper: {
    padding: 0,
  },
  smallButton: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: '8px',
    marginRight: '5px',
  },
  filters: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    alignItems: 'center',
  },
  inputFilter: {
    width: 'auto!important',
    flex: '1',
  },
  filterButton: {
    marginLeft: `${theme.spacing(0.5)}!important`,
    height: 40,
    '& svg': {
      marginRight: `${theme.spacing(0.2)}!important`,
    },
  },
  activatedFilter: {
    color: theme.palette.secondary[500],
    border: `1px solid ${theme.palette.secondary[500]}`,
  },
  topButton: {
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      width: 'auto',
    },
  },
  participantContent: {
    borderTop: `1px solid ${theme.palette.primary[400]}`,
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  obsCloseButtonGrapper: {
    display: 'flex',
    gap: 8,
  },
  dropDown: {
    position: 'absolute',
    maxHeight: '20vh',
    backgroundColor: theme.palette.primary[1400],
    zIndex: 100,
    color: theme.palette.primary[100],
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    '& .option': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '15px',
      cursor: 'pointer',
      borderTop: `1px solid ${theme.palette.primary[400]}`,
    },
    '& .option:hover': {
      background: theme.palette.primary[1200],
    },
  },
});
