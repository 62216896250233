/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { useFormik } from 'formik';
import { Form } from 'lib/form';
import { Time } from 'lib/time';
import { useScreenshot } from 'use-react-screenshot';
import Cropper from 'react-cropper';
import { useDispatch, useSelector } from 'react-redux';
import ProjectService from 'services/ProjectService';
import { setSelectedSequence } from 'slices/profileProjectAssetReducer';
import * as Yup from 'yup';
import '../../../../node_modules/cropperjs/dist/cropper.css';
import { addObservation, setObservation as setObservationReduce, updateObservation } from 'slices/observationsReducer';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { ObservationMap } from 'components/Observation/ObservationMap';
import moment from 'moment';
import { DialogMessage } from 'components/DialogMessage';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { InfoIcon, SaveIcon, CirclePlusIcon2, SkipForwardIcon, SkipBackIcon, CloseIcon2 } from 'components/Icons';
import { DeleteButton } from './DeleteButton';
import { ImgEditor } from '../ImgEditor/ImgEditor';
import { CropperButtonsV2 } from './CropperButtonsV2';
import { EmailButtonV2 } from '../ObservationEmail/EmailButtonV2';
import { EmailButon } from '../ObservationEmail/EmailButon';
import { useStyles } from './styles';
import { createPortal } from 'react-dom';

const validationSchema = Yup.object({
  name: Yup.string().min(1).required('Name is required'),
  description: Yup.string().min(1).required('Description is required'),
});

export const ObservationModal = (params) =>
  createPortal(<Observation {...params} />, document.getElementById('sidebar-panel'));

export const Observation = ({ handleClose, time, selectedSequence, points, action, observationMap, pointMap }) => {
  const {
    projectAsset: { sequences, projId, id, name, updatedAt, createdAt, uploaders },
  } = useSelector((state) => state.profileProjectAssets);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dataUrl, setDataUrl] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [editorImageUrl, setEditorImageUrl] = useState({ enable: false, image: null });
  const [loading, setLoading] = useState(false);
  const [sequence, setSequence] = useState(null);
  const [index, setIndex] = useState(selectedSequence);
  const [type, setType] = useState(null);
  const [observation, setObservation] = useState(null);
  const [point, setPoint] = useState(null);
  const [operationType, setOperationType] = useState('');
  const [enabledEdit, setEnabledEdit] = useState(true);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [error, setError] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenshot] = useScreenshot();
  const editorRef = useRef();
  const cropperRef = useRef();
  const cropperEditRef = useRef();
  const [map, setMap] = useState(null);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });

  // ---------------------- get coordinates
  const getCoordinates = (lat, lng) => {
    setError('');
    setCoordinates({ lat: !lat ? 0 : lat, lng: !lng ? 0 : lng });
  };

  // ------------------------ get original image (original.jpg)
  const getOriginalImage = async (auxSequence, element, aseetType, assetId) => {
    const result = await ProjectService.getProjectAssetZoomFrame(
      observation?.projectId ? observation?.projectId : projId,
      assetId || auxSequence.assetId,
      element,
      aseetType || auxSequence.asset.type
    );
    const img = URL.createObjectURL(result.data);
    if (action === 'creation') setDataUrl(img);
    setOriginalImage(img);
  };

  // ----------------------- get sequence data
  const fetchImage = async (auxSequence, element) => {
    setIsImageLoading(true);
    try {
      if (action === 'creation') {
        getOriginalImage(auxSequence, element);
        setTimeout(() => {
          setIsImageLoading(false);
        }, 1000);
      } else {
        const auxObservation = auxSequence;
        const result = await ProjectService.getProjectAssetObservationImage(
          auxObservation.projectId ? auxObservation.projectId : projId,
          auxObservation.sequence.assetId,
          auxObservation.id
        );
        setDataUrl(URL.createObjectURL(result.data));
        getOriginalImage(
          auxObservation,
          +auxObservation.time - 1,
          auxObservation.sequence.asset.type,
          auxObservation.sequence.assetId
        );
        setTimeout(() => {
          setIsImageLoading(false);
        }, 1000);
      }
    } catch (err) {
      setIsImageLoading(false);
      // do something with err
    }
  };

  // -------------------------- convert image to a blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // ----------------------- show image editor
  const handleClickEditor = async () => {
    setIsImageLoading(true);
    const imgElement = document.querySelector('.cropper-wrap-box')
      ? document.querySelector('.cropper-wrap-box')
      : cropperEditRef?.current;
    const newImage = dataURItoBlob(await takeScreenshot(imgElement));
    setEditorImageUrl({ enable: true, image: URL.createObjectURL(newImage) });
    setIsImageLoading(false);
  };

  // ---------------------- send data to API
  const submitFormData = async (values) => {
    if (!enabledEdit) {
      return;
    }
    let auxIndex = 0;
    if (action === 'creation') {
      auxIndex = sequence.asset.type === 'VIDEO' ? index : time;
      auxIndex = auxIndex >= sequence?.spatialPoints.length ? sequence?.spatialPoints.length - 1 : auxIndex;
    }
    const body = {
      ...values,
      projectAssetSequenceId: action !== 'creation' ? observation.projectAssetSequenceId : sequence.id,
      type: action !== 'creation' ? observation.sequence.asset.type : sequence.asset.type,
      lat: coordinates.lat,
      lng: coordinates.lng,
      time: action !== 'creation' ? +observation.time : auxIndex + 1,
      projectAssetId: action !== 'creation' ? observation.projectAssetId : id,
      // eslint-disable-next-line prettier/prettier
      spatialPointId:
        action !== 'creation'
          ? observation.spatialPointId
          : sequence.asset.type !== 'VIDEO'
          ? sequence?.spatialPoints[0].id
          : point.spatialPointId,
    };
    if (!body.lat && !body.lng) {
      setError('Coordinates are required');
      return;
    }
    // --------------------- get canvas element
    const img = editorRef?.current?.getInstance();
    if ((editorImageUrl.enable && !img) || (!editorImageUrl.enable && !cropperEditRef?.current)) {
      setOpenDialog('Please wait the images is loaded.');
      return;
    }
    setLoading(true);
    try {
      let observationPoint = null;
      if (action !== 'creation') {
        const response = await ProjectService.updateObservationPoint(observation.projectId, observation.id, body);
        observationPoint = { ...response.data.data };
        observationPoint = { ...observation };
        observationPoint.lat = body.lat;
        observationPoint.lng = body.lng;
        observationPoint.name = body.name;
        observationPoint.description = body.description;
        observationPoint.projectId = observation.projectId;
        await ProjectService.uploadObservationPointAsset(
          response.data.data.signedUrl,
          dataURItoBlob(
            editorImageUrl.enable
              ? editorRef?.current?.getInstance().toDataURL()
              : await takeScreenshot(cropperEditRef?.current)
          )
        );
        const url = await ProjectService.getImageUrlObservationPoint(observation.projectId, observationPoint.id);
        observationPoint.signedUrl = url.data.data;
        dispatch(updateObservation(observationPoint));
        dispatch(setObservationReduce(observationPoint));
      } else {
        const response = await ProjectService.createObservationPoint(projId, body);
        observationPoint = { ...response.data.data };
        await ProjectService.uploadObservationPointAsset(
          observationPoint.signedUrl,
          dataURItoBlob(
            editorImageUrl.enable
              ? editorRef?.current?.getInstance().toDataURL()
              : await takeScreenshot(cropperEditRef?.current)
          )
        );
        observationPoint.projectId = projId;
        const url = await ProjectService.getImageUrlObservationPoint(projId, observationPoint.id);
        observationPoint.signedUrl = url.data.data;
        observationPoint.sequence = sequence;
        observationPoint.createdAt = new Date();
        dispatch(addObservation(observationPoint));
      }
      handleClose();
      setLoading(false);
    } catch (err) {
      if (err?.response) {
        const result = Form.parseApiValidationError(err);
        if (result) {
          // eslint-disable-next-line no-use-before-define
          formik.setErrors(result);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // ------------------ show point on map
  const getPointSequence = (sequenceObj, auxTime) => {
    const auxObservation = type === 'VIDEO' ? { ...points[auxTime] } : { ...sequenceObj.spatialPoints[auxTime] };
    auxObservation.id = 1;
    setPoint(auxObservation);
    auxObservation.lng = auxObservation.longitude;
    auxObservation.lat = auxObservation.latitude;
    setObservation(auxObservation);
  };

  // ------------------------ previous or next arrows
  const getSequence = (direction) => {
    const auxIndex = direction === 'left' ? index - 1 : index + 1;
    setIndex(auxIndex);
    setDataUrl(null);
    setEditorImageUrl({ enable: false });
    setCoordinates({ lat: 0, lng: 0 });
    if (type === 'VIDEO') {
      fetchImage(sequence, auxIndex);
      getPointSequence(sequence, auxIndex);
    } else {
      let auxSequence = points[auxIndex];
      sequences.forEach((item) => {
        if (auxSequence.sequenceId === item.id) {
          auxSequence = { ...item };
        }
      });
      setSequence(auxSequence);
      fetchImage(auxSequence, time);
      dispatch(setSelectedSequence(auxIndex));
      getPointSequence(auxSequence, time);
    }
  };

  useEffect(() => {
    setOperationType(action);
    if (action === 'creation') {
      // create observation
      setCoordinates({ lat: 0, lng: 0 });
      setEnabledEdit(true);
      let auxSequence = points[selectedSequence];
      sequences.forEach((item) => {
        if (auxSequence.sequenceId === item.id) {
          auxSequence = { ...item };
        }
      });
      const auxTime = auxSequence.asset.type === 'VIDEO' && time === null ? 0 : time;
      setIndex(auxSequence.asset.type === 'VIDEO' ? auxTime : selectedSequence);
      if (auxSequence.asset.type === 'VIDEO') {
        auxSequence.spatialPoints = [...points];
      }
      getPointSequence(auxSequence, auxTime);
      setSequence(auxSequence);
      setType(auxSequence.asset.type);
      fetchImage(auxSequence, auxTime);
    } else {
      // show detial of observation
      setEnabledEdit(true);
      fetchImage(observationMap);
      setPoint(pointMap);
      setObservation(observationMap);
      setCoordinates({
        lat: !observationMap.lat ? 0 : observationMap.lat,
        lng: !observationMap.lng ? 0 : observationMap.lng,
      });
    }
  }, [selectedSequence]);

  const formik = useFormik({
    initialValues: {
      name: action === 'creation' ? '' : observationMap.name,
      description: action === 'creation' ? '' : observationMap.description,
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  const FormatNumber = (num) => (!num ? 0 : num.toFixed(5));

  return (
    <div className={classes.containerDetail}>
      <div className={classes.dialogHeader}>
        <div>
          <Typography variant="h4" style={{ marginBottom: '5px' }}>
            {action !== 'creation' ? 'View Observation' : 'Create Observation'}{' '}
            <span className={classes.subTitleHeader}>{`   >   ${name}`}</span>
          </Typography>
          <Typography variant="h5" className={classes.subTitleHeader}>{`Captured ${
            createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found'
          } > Uploaded: ${updatedAt ? moment(updatedAt).format('MM/DD/YY') : 'date not found'} > Uploader: ${
            uploaders[0]?.name || 'Name not found'
          }`}</Typography>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          {action === 'creation' && (
            <Button
              color="secondary"
              onClick={() =>
                setValidateRole({ open: true, action: [Permissions.PROJ_OBSERVATION], callBack: handleSubmit })
              }
              disabled={loading || isImageLoading}
              variant="contained"
              size="small"
              style={{ marginRight: '10px', padding: '10px 14px' }}
            >
              <CirclePlusIcon2 size={22} />
              <Typography ml={0.2}>Save Now</Typography>
            </Button>
          )}
          {enabledEdit && action !== 'creation' && (
            <Button
              color="secondary"
              onClick={() =>
                setValidateRole({ open: true, action: [Permissions.PROJ_OBSERVATION], callBack: handleSubmit })
              }
              disabled={loading || isImageLoading}
              variant="contained"
              size="small"
              style={{ marginRight: '10px' }}
            >
              <SaveIcon size={20} />
              <Typography ml={0.2}>Save Changes</Typography>
            </Button>
          )}
          <Button
            color="primary"
            title="Close"
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{ padding: '10px' }}
          >
            <CloseIcon2 size={22} />
          </Button>
        </div>
      </div>
      <div className={classes.column}>
        <div className={classes.row}>
          <div className={`${classes.layoutCol1} ${classes.assetContainer}`}>
            {index > 0 && enabledEdit && (
              <Button
                variant="contained"
                color="primary"
                className={classes.playContainer}
                sx={{ left: '10px', padding: '0 10px', color: '#B1B1B1' }}
                onClick={() => getSequence('left')}
              >
                <SkipBackIcon size="22" />
              </Button>
            )}
            <div ref={cropperEditRef} style={{ display: editorImageUrl.enable ? 'none' : 'inherit' }}>
              <Cropper
                src={dataUrl}
                initialAspectRatio={16 / 9}
                guides={false}
                viewMode={3}
                ref={cropperRef}
                disabled={loading}
                dragMode="move"
                autoCropArea="1"
                highlight={false}
                cropBoxMovable={false}
                cropBoxResizable={false}
                toggleDragModeOnDblclick={false}
                style={{ width: '100%', height: '60vh', objectFit: 'contain' }}
              />
            </div>
            {editorImageUrl.enable && (
              <>
                <ImgEditor dataUrl={editorImageUrl.image} editorRef={editorRef} />
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className="delete-btn"
                  title="Edit Image"
                  onClick={() => setEditorImageUrl({ enable: false })}
                >
                  Cancel Edition
                </Button>
              </>
            )}
            {((type === 'PHOTO' && index < sequences.length - 1) ||
              (type === 'VIDEO' && index < sequence.spatialPoints.length - 1)) &&
              enabledEdit && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.playContainer}
                  sx={{ right: '45px', padding: '0 10px', color: '#B1B1B1' }}
                  onClick={() => getSequence('right')}
                >
                  <SkipForwardIcon size="22" />
                </Button>
              )}
          </div>
          <div className={classes.layoutCol2}>
            {observation?.id && (
              <ObservationMap
                observation={observation}
                point={point}
                enableEdit={enabledEdit}
                handleGetCoordinates={getCoordinates}
                type={operationType}
                imgHandler={(view) => setMap(view)}
              />
            )}
            <Typography color="error">{error}</Typography>
            <Typography variant="subtitle">
              {action === 'creation'
                ? 'Please click in the map to place your Observation in the best location'
                : enabledEdit
                ? 'Please click in the map to best reposition the location of your Observation'
                : ''}
            </Typography>
            <div style={{ marginTop: '10px' }} />
            <InputLabel>Name *</InputLabel>
            <TextField
              placeholder="Name"
              value={values.name}
              id="name"
              name="name"
              {...Form.fieldErrorHelper(formik, 'name')}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              disabled={loading || !enabledEdit}
              fullWidth
            />
            <InputLabel>
              <Typography mt={0.5}>Description *</Typography>
            </InputLabel>
            <FormTextArea
              placeholder="Description"
              rows="5"
              value={values.description}
              id="description"
              name="description"
              {...Form.fieldErrorHelper(formik, 'description')}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={loading || !enabledEdit}
              required
              style={{ height: '95px' }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.layoutCol1}>
            {enabledEdit && !editorImageUrl.enable && (
              <div className={classes.flexBetween} style={{ alignItems: 'center' }}>
                <CropperButtonsV2
                  cropperRef={cropperRef}
                  isImageLoading={isImageLoading}
                  handleClickEditor={handleClickEditor}
                />
                <div className={classes.flexMrTop} style={{ marginRight: '10px' }}>
                  <Typography variant="subtitle1">Lat,Long:</Typography>
                  <Typography variant="subtitle1" className={classes.subTypography}>
                    {FormatNumber(coordinates.lat)} , {FormatNumber(coordinates.lng)}
                  </Typography>
                </div>
                <div className={classes.flexMrTop}>
                  <Typography variant="subtitle1">Timeline Marker: </Typography>
                  <Typography variant="subtitle1" className={classes.subTypography}>
                    {action === 'creation'
                      ? Time.humanReadable({ seconds: sequence?.asset.type === 'VIDEO' ? index + 1 : time + 1 })
                      : Time.humanReadable({ seconds: +observationMap.time + 1 })}
                  </Typography>
                </div>
                {originalImage && (
                  <Button color="secondary" href={originalImage} target="_blank" rel="noreferrer">
                    Click to Open Original Image
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className={`${classes.layoutCol2} ${classes.flexMrTop}`} style={{ justifyContent: 'flex-end' }}>
            {enabledEdit && action !== 'creation' && observation?.id && (
              <div className={classes.flexBetween} style={{ width: '25%' }}>
                <EmailButtonV2 loading={loading} observation={observation} map={map} />
                <DeleteButton type="outlined" loading={loading} handleClose={handleClose} observation={observation} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Grid container spacing={1}>
          {!enabledEdit && action !== 'creation' && (
            <>
              <Grid item xs={12} sm={12} md={3} lg={3} />
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.buttonsContent}>
                <EmailButon loading={loading} observation={observation} map={map} />
                <Button
                  color="secondary"
                  onClick={() =>
                    setValidateRole({
                      open: true,
                      action: [Permissions.PROJ_OBSERVATION],
                      callBack: () => setEnabledEdit(true),
                    })
                  }
                  variant="contained"
                  size="small"
                >
                  Edit
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <LoadingOverlay loading={loading} />
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenDialog(false);
        }}
      />
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: observation?.projectId ? observation?.projectId : projId }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </div>
  );
};

Observation.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  time: PropTypes.number,
  selectedSequence: PropTypes.number,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sequenceId: PropTypes.number,
      sequenceIndex: PropTypes.number,
      spatialPoints: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
      asset: PropTypes.shape({
        type: PropTypes.string,
      }),
    })
  ),
  action: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  observationMap: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  pointMap: PropTypes.any,
};

Observation.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  time: 0,
  selectedSequence: 0,
  points: [],
  action: '',
  observationMap: { id: null },
  pointMap: { id: null, latitue: 0, longitude: 0 },
};
