import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useStyles } from './styles';
import { MagnifyingPlusIcon, MagnifyingMinusIcon, SelectAllIcon } from 'components/Icons';

export const CropperButtonsV2 = ({ cropperRef, isImageLoading, handleClickEditor }) => {
  const classes = useStyles();

  return (
    <div className={classes.cropperControlContainerV2}>
      <div id="zoom-buttons">
        <Button
          type="button"
          size="small"
          color="primary"
          variant="contained"
          title="Zoom In"
          onClick={() => {
            cropperRef.current.cropper.zoom(0.1);
          }}
        >
          <MagnifyingPlusIcon size={22} />
        </Button>
        <Button
          type="button"
          size="small"
          color="primary"
          variant="contained"
          title="Zoom Out"
          onClick={() => {
            cropperRef.current.cropper.zoom(-0.1);
          }}
        >
          <MagnifyingMinusIcon size={22} />
        </Button>
      </div>
      <div id="action-buttons">
        <Button
          type="button"
          size="small"
          color="primary"
          variant="outlined"
          title="Edit Image"
          onClick={() => handleClickEditor()}
          disabled={isImageLoading}
          style={{ color: 'white' }}
        >
          <SelectAllIcon size={22} />
        </Button>
      </div>
    </div>
  );
};

CropperButtonsV2.propTypes = {
  cropperRef: PropTypes.node,
  isImageLoading: PropTypes.bool,
  handleClickEditor: PropTypes.func,
};

CropperButtonsV2.defaultProps = {
  cropperRef: null,
  isImageLoading: false,
  handleClickEditor: () => {},
};
