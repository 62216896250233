import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  btn: (props) => ({
    display: 'flex',
    justifyContent: 'start',
    listStyle: 'none',
    alignItems: 'between',
    lineHeight: '24px',
    margin: 0,
    fontSize: '16px',
    fontWeight: 500,
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.6)}`,
    color: props.active ? 'black !important' : theme.palette.primary[300],
    backgroundColor: props.active ? theme.palette.secondary.main : theme.palette.primary[1200],
    ...(!props.active && {
      '&:hover, &:focus': {
        color: theme.palette.primary[300],
        backgroundColor: theme.palette.primary[900],
        '& svg': {
          color: theme.palette.primary[500],
        },
      },
    }),
    ...(props.active && {
      '&:hover, &:focus': {
        color: 'black !important',
        backgroundColor: theme.palette.secondary.main,
      },
    }),
    '& svg': {
      color: props.active ? 'black!important' : theme.palette.primary[500],
    },
    textDecoration: 'none',
    borderRadius: '10px',
  }),
});
