import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from 'components/Icons';
import { ProjectsIcon } from 'components/Icons/ProjectsIcon';
import { Typography } from 'components/Typography/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { getExtension } from '../../lib/content-helpers';

export const StyledDropZone = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin-bottom: ${(props) => props.theme.spacing(1)};
  margin-left: -20px;
  margin-right: -20px;
  background-color: ${(props) => props.theme.palette.primary.light};
`;

export const StyledInfo = styled.div`
  border: 1px solid white;
  border-radius: 20px;
  height: 140px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  background-color: ${(props) => props.theme.palette.primary.dark};
`;

export const HighlightText = styled.span`
  color: ${(props) => props.theme.palette.secondary.base};
`;

const text = 'Files not accepted: ';

const ACCEPTED_FILES = {
  'video/mp4': '.MP4',
  'video/quicktime': '.mov',
  'image/jpeg': '.jpeg',
  'image/jpg': '.jpg',
  'image/png': '.png',
  'video/ts': '.ts',
  'image/srt': 'srt',
  'image/SRT': 'SRT',
};

export const FileDropZone = ({ onAddFile, accept }) => {
  const inputRef = React.useRef(null);
  const [error, setError] = useState(text);
  const [openModalError, setOpenModalError] = useState(false);

  const isAccepted = (mime) => ACCEPTED_FILES[mime] || mime;
  // ------------------------------------ validate accepted files
  const validateFile = (file, auxError) => {
    setOpenModalError(false);
    let erroText = auxError;
    let flagError = true;
    accept.forEach((mime) => {
      if (mime === file.type || `.${getExtension(file.name)}` === isAccepted(mime).toLowerCase()) {
        flagError = false;
      }
    });
    if (flagError && accept.length > 0) {
      erroText += ` ${file.name}, `;
      setOpenModalError(true);
    }
    flagError = accept.length > 0 ? flagError : false;
    return { validate: !flagError, errorText: erroText };
  };

  const onInputChange = () => {
    const input = inputRef.current;
    let auxError = error;
    if (input.files) {
      for (let i = 0; i < input.files.length; i += 1) {
        const file = input.files[i];
        const { errorText, validate } = validateFile(file, auxError);
        auxError = errorText;
        if (validate) {
          onAddFile(file);
        }
      }
    }
    setError(auxError);
  };

  const dropHandler = (ev) => {
    ev.preventDefault();
    let auxError = error;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          const { errorText, validate } = validateFile(file, auxError);
          auxError = errorText;
          if (validate) {
            onAddFile(file);
          }
        }
      }
      setError(auxError);
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i += 1) {
        const file = ev.dataTransfer.files[i];
        const { errorText, validate } = validateFile(file, auxError);
        auxError = errorText;
        if (validate) {
          onAddFile(file);
        }
      }
      setError(auxError);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  // ----------------------- show the accepted files text
  const getAcceptedFilesT = () =>
    accept.map((item, idx) => (
      <span key={`${v4()}${new Date().getMilliseconds()}`}>{` ${isAccepted(item)}${
        idx === accept.length - 1 ? '' : ','
      }`}</span>
    ));

  return (
    <>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        id="fileInput"
        type="file"
        onChange={onInputChange}
        multiple="multiple"
        accept={accept}
      />
      <StyledDropZone
        onDrop={(e) => dropHandler(e)}
        onClick={() => handleClick()}
        onDragOver={(e) => e.preventDefault()}
      >
        <StyledInfo>
          <ProjectsIcon size={22} />
          <Typography style={{ marginTop: '15px' }}>
            <HighlightText>Click to browse your files</HighlightText> or drag and drop
          </Typography>
          {accept && accept.length > 0 && (
            <Typography>
              Accepted files:
              {getAcceptedFilesT()}
            </Typography>
          )}
        </StyledInfo>
      </StyledDropZone>
      <DialogMessage
        title={error}
        isOpen={openModalError}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => {
          setOpenModalError(false);
          setError(text);
        }}
      />
    </>
  );
};

FileDropZone.propTypes = {
  onAddFile: PropTypes.func.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string),
};

FileDropZone.defaultProps = {
  accept: [],
};
