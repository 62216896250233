import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const PencilIcon2 = ({ size }) => (
  <Svg viewBox="0 0 21 20" size={size}>
    <path
      id="Icon"
      d="M13.666 2.49993C13.8849 2.28106 14.1447 2.10744 14.4307 1.98899C14.7167 1.87054 15.0232 1.80957 15.3327 1.80957C15.6422 1.80957 15.9487 1.87054 16.2347 1.98899C16.5206 2.10744 16.7805 2.28106 16.9993 2.49993C17.2182 2.7188 17.3918 2.97863 17.5103 3.2646C17.6287 3.55057 17.6897 3.85706 17.6897 4.16659C17.6897 4.47612 17.6287 4.78262 17.5103 5.06859C17.3918 5.35455 17.2182 5.61439 16.9993 5.83326L5.74935 17.0833L1.16602 18.3333L2.41602 13.7499L13.666 2.49993Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

PencilIcon2.propTypes = {
  size: PropTypes.number,
};

PencilIcon2.defaultProps = {
  size: 24,
};
