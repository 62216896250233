import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { addExternalType, externalActionType, externalDataType } from 'lib/external-data';
import { DialogProjectInvite, typeInviteData } from 'components/DialogProjectInvite';
import { ModalEditProject } from 'smartComponents/ModalEditProject';
import { DeleteUuid } from 'components/AddUuid/DeleteUuid/DeleteUuid';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { useHistory } from 'react-router';
import { DeleteProject } from 'components/DeleteProject/DeleteProject';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { ModalProjectSettings } from 'smartComponents/ModalProjectSettings';
import { ModalComingSoon } from 'smartComponents/ModalComingSoon/ModalComingSoon';
import { AssignProjectInspection } from 'components/Inspections/AssignProjectInspection/AssignProjectInspection';
import { assignInspectionType } from 'components/Inspections/helpers';
import { NestedMenuList } from 'components/NestedMenuList/NestedMenuList';

const actions = {
  PROJ_EDIT: 'edit',
  PROJ_VISIBILITY: 'edit_visibility',
  PROJ_DELETE: 'delete',
  TEAM_TO_PROJ: 'team_to_proj',
  USER_TO_PROJ: 'user_to_proj',
  ORG_TO_PROJ: 'org_to_proj',
  comingSoon: 'coming_soon',
  PROJ_ASSIGN_INSPECTION: 'proj_assign_inspect',
};

export const ProjectActions = ({ row, external, handleConfirm }) => {
  const history = useHistory();
  const [openUuid, setOpenUuid] = useState(false);
  const [openEditProject, setOpenEditProject] = useState(false);
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });
  // const [open, setOpen] = useState();
  const handleCancel = (refreshFlag) => {
    setOpenEditProject(false);
    if (refreshFlag) {
      handleConfirm();
    }
  };

  const handleBeforeConfirm = () => {
    setOpenEditProject(false);
    handleConfirm();
  };

  // ------------------------------- UUID functions
  const handleCloseUuid = (flag) => {
    setOpenUuid(false);
    if (flag) handleConfirm();
  };

  // got to project page
  const navigateToPermissions = (project) => {
    handleConfirm(project);
  };

  return (
    <>
      <ContextMenu>
        <ContextMenuButton />
        <ContextMenuList position="bottom-right">
          <ContextMenuItem onClick={() => navigateToPermissions(external ? row.original.project : row.original)}>
            Open Project
          </ContextMenuItem>
          <NestedMenuList id="copy" item="Copy / Share">
            {!external && (
              <ContextMenuItem
                onClick={() =>
                  setValidateRole({
                    open: true,
                    action: [Permissions.PROJ_COPY_UUID],
                    callBack: () => setOpenUuid({ id: row.original.id, type: externalActionType.COPY }),
                  })
                }
              >
                Copy UUID
              </ContextMenuItem>
            )}
            <ContextMenuItem
              onClick={() =>
                setOpenEditProject({
                  type: actions.comingSoon,
                })
              }
            >
              Copy
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() =>
                setOpenEditProject({
                  type: actions.comingSoon,
                })
              }
            >
              Share
            </ContextMenuItem>
          </NestedMenuList>
          <WidgetDivider />
          <NestedMenuList id="participants" item="Add/Edit Participants">
            <ContextMenuItem
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
                  callBack: () =>
                    history.push(`/projects/${external ? row.original.project.id : row.original.id}/participant`),
                })
              }
            >
              Participant Coordination
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
                  callBack: () =>
                    setOpenEditProject({
                      id: external ? row.original.project.id : row.original.id,
                      project: external ? row.original.project : row.original,
                      type: actions.TEAM_TO_PROJ,
                    }),
                })
              }
            >
              Add Team To Project
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT, Permissions.PROJ_USER_MODIFY],
                  callBack: (bySelf) =>
                    setOpenEditProject({
                      id: external ? row.original.project.id : row.original.id,
                      project: external ? row.original.project : row.original,
                      type: actions.USER_TO_PROJ,
                      bySelf,
                    }),
                })
              }
            >
              Add User To Project
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.ORG_USER_ADD],
                  callBack: () =>
                    setOpenEditProject({
                      id: external ? row.original.project.id : row.original.id,
                      project: external ? row.original.project : row.original,
                      type: actions.ORG_TO_PROJ,
                    }),
                })
              }
            >
              Add Organization To Project
            </ContextMenuItem>
            {!external && (
              <>
                <ContextMenuItem
                  onClick={() =>
                    setValidateRole({
                      open: true,
                      action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
                      callBack: () =>
                        setOpenUuid({
                          ...row.original,
                          type: externalActionType.ADD,
                          variant: addExternalType.ADD_TEAM_TO_PROJ,
                          textDescription: externalDataType.TEAM,
                        }),
                    })
                  }
                >
                  Add Team by UUID
                </ContextMenuItem>
              </>
            )}
            <ContextMenuItem
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_USER_ADD, Permissions.PROJ_USER_GRANT],
                  callBack: () =>
                    setOpenUuid({
                      ...row.original,
                      type: externalActionType.ADD,
                      variant: addExternalType.ADD_USER_TO_PROJ,
                      textDescription: externalDataType.USER,
                    }),
                })
              }
            >
              Add User by UUID
            </ContextMenuItem>
          </NestedMenuList>

          <WidgetDivider />
          {!external && (
            <NestedMenuList id="inspections" item="Inspections">
              <ContextMenuItem
                onClick={() =>
                  navigateToPermissions({
                    ...(external ? row.original.project : row.original),
                    openActiveInspection: true,
                  })
                }
              >
                View Current Inspections
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() =>
                  setValidateRole({
                    open: true,
                    action: [Permissions.PROJ_INSPECTIONS],
                    callBack: () =>
                      setOpenEditProject({
                        project: external ? row.original.project : row.original,
                        type: actions.PROJ_ASSIGN_INSPECTION,
                      }),
                  })
                }
              >
                Assign Inspection to All Content
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() =>
                  setValidateRole({
                    open: true,
                    action: [Permissions.PROJ_INSPECTIONS],
                    callBack: () =>
                      navigateToPermissions({
                        ...(external ? row.original.project : row.original),
                        openInspection: true,
                      }),
                  })
                }
              >
                View Inspection Templates
              </ContextMenuItem>
            </NestedMenuList>
          )}
          <WidgetDivider />

          <NestedMenuList id="edit-proj-details" item="Edit Project Details">
            <ContextMenuItem
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_MODIFY],
                  callBack: () =>
                    setOpenEditProject({
                      id: external ? row.original.project.id : row.original.id,
                      type: actions.PROJ_VISIBILITY,
                    }),
                })
              }
            >
              Edit Project Visibility
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() =>
                setValidateRole({
                  open: true,
                  action: [Permissions.PROJ_MODIFY],
                  callBack: () =>
                    setOpenEditProject({
                      id: external ? row.original.project.id : row.original.id,
                      type: actions.PROJ_EDIT,
                    }),
                })
              }
            >
              Edit Project
            </ContextMenuItem>
          </NestedMenuList>

          <NestedMenuList id="advance-options" item="Advance Options">
            {!external && (
              <ContextMenuItem
                onClick={() =>
                  setValidateRole({
                    open: true,
                    action: [Permissions.PROJ_DELETE],
                    callBack: () =>
                      setOpenEditProject({
                        project: row.original,
                        type: actions.PROJ_DELETE,
                      }),
                  })
                }
              >
                Delete Project
              </ContextMenuItem>
            )}
            {external && (
              <ContextMenuItem
                onClick={() => setOpenUuid({ ...row.original.project, type: externalActionType.REMOVE })}
              >
                Remove from Account
              </ContextMenuItem>
            )}
          </NestedMenuList>
        </ContextMenuList>
      </ContextMenu>
      {openEditProject?.id && openEditProject.type === actions.PROJ_EDIT && (
        <ModalEditProject
          isOpen={openEditProject?.id}
          handleConfirm={handleBeforeConfirm}
          handleCancel={handleCancel}
          projId={openEditProject.id}
        />
      )}
      {openEditProject?.project?.id && openEditProject.type === actions.PROJ_DELETE && (
        <DeleteProject project={openEditProject.project} handleClose={handleCancel} />
      )}
      {openEditProject?.id &&
        (openEditProject.type === actions.USER_TO_PROJ ||
          openEditProject.type === actions.TEAM_TO_PROJ ||
          openEditProject.type === actions.ORG_TO_PROJ) && (
          <DialogProjectInvite
            isOpen={openEditProject?.id}
            handleClose={handleCancel}
            bySelf={openEditProject.bySelf}
            projectId={openEditProject.id}
            itemName={openEditProject?.project?.name}
            type={
              // eslint-disable-next-line no-nested-ternary
              openEditProject.type === actions.USER_TO_PROJ
                ? typeInviteData.USER
                : openEditProject.type === actions.ORG_TO_PROJ
                ? typeInviteData.ORG
                : typeInviteData.TEAM
            }
          />
        )}
      {openUuid?.id && openUuid?.type === externalActionType.REMOVE && (
        <DeleteUuid externalData={openUuid} type={externalDataType.PROJ} handleClose={handleCloseUuid} />
      )}
      {openUuid?.id && openUuid?.type === externalActionType.COPY && (
        <CopyUuidAlert id={openUuid.id} handleClose={() => setOpenUuid(false)} />
      )}
      {openUuid?.type === externalActionType.ADD && (
        <AddUuid
          handleClose={handleCloseUuid}
          type={externalDataType.PROJ}
          variant={openUuid.variant}
          item={openUuid}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={
            // eslint-disable-next-line no-nested-ternary
            validateRole.action.find((item) => item === Permissions.ORG_PROJ_CREATE)
              ? null
              : external
              ? row.original.project
              : row.original
          }
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
      {openEditProject?.id && openEditProject.type === actions.PROJ_VISIBILITY && (
        <ModalProjectSettings
          handleOpen={handleCancel}
          isOpenModal={openEditProject?.id}
          handleClose={handleCancel}
          projectId={openEditProject?.id}
        />
      )}
      {openEditProject.type === actions.comingSoon && <ModalComingSoon onCancel={() => setOpenEditProject(false)} />}
      {openEditProject?.project?.id && openEditProject.type === actions.PROJ_ASSIGN_INSPECTION && (
        <AssignProjectInspection
          handleClose={handleCancel}
          project={openEditProject.project}
          type={assignInspectionType.PROJ}
        />
      )}
    </>
  );
};

ProjectActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object,
  external: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

ProjectActions.defaultProps = {
  row: null,
  external: false,
  handleConfirm: () => {},
};
