import React from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  items: (props) => ({
    height: `${props.size}px`,
    overflowY: 'auto',
    margin: '12px 0',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 0',
      '& .MuiButton-root': {
        color: 'pink',
      },
    },
  }),
});

export const ItemList = ({ items, onRemove, size }) => {
  const classes = useStyles({ size });
  const removeItem = (id) => {
    onRemove(id);
  };
  return (
    <div className={classes.items}>
      {items.map((item) => (
        <div key={`${item.id}:${item.type}`}>
          <div>
            <Typography fontSize={14}>{item.name}</Typography>
            <span>{item.description}</span>
          </div>
          <Button type="primary" variant="oulined" size="small" onClick={() => removeItem(item.id)}>
            Remove
          </Button>
        </div>
      ))}
      <div />
    </div>
  );
};

ItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  size: PropTypes.number,
  onRemove: PropTypes.func,
};

ItemList.defaultProps = {
  items: [],
  size: 200,
  onRemove: () => {},
};
