import { Button, List, Typography } from '@mui/material';
import { ContextMenuContext } from 'components/ContextMenu';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { theme } from 'theme';
import { MoreThanIcon } from 'components/Icons/MoreThanIcon';

/**
 * Determine placement for the menu
 *
 * @param {object} positionData
 * @param {string} position
 */

const useStyles = makeStyles({
  list: (props) => ({
    width: '250px',
    border: `1px solid ${theme.palette.primary[1000]}`,
    borderRadius: '8px 0px 8px 8px',
    position: 'absolute !important',
    padding: 0,
    boxShadow: '0px rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.palette.primary.dark,
    zIndex: 1,
    top: `${props.top}px`,
    right: '248px',
    color: theme.palette.white,
  }),
});

/**
 * Render a list of menu items when the context value for isOpen is true
 */
export const NestedMenuList = ({ id, item, children }) => {
  const { isOpen, subMenu, setSubMenu } = useContext(ContextMenuContext);
  const btnRef = useRef(null);
  let positionStyle = {};

  if (btnRef.current) {
    positionStyle = { top: btnRef.current.offsetTop };
  }
  const classes = useStyles({ position: positionStyle });

  return (
    <>
      {isOpen && id === subMenu && <List className={classes.list}>{children}</List>}
      {item && (
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '15px',
            width: '100%',
          }}
          ref={btnRef}
          size="large"
          onClick={() => setSubMenu(subMenu !== id ? id : null)}
        >
          <Typography>{item}</Typography>
          <MoreThanIcon size={12} />
        </Button>
      )}
    </>
  );
};

NestedMenuList.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  item: PropTypes.any,
};

NestedMenuList.defaultProps = {
  item: 'Open',
};
