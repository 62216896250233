import React, { useEffect, useState } from 'react';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import EnumService from 'services/EnumService';
import { DescriptionRoles } from 'lib/roles';
import { useStyles } from './styles';
import { Avatar } from 'components/Avatar';
import { getInitials } from 'lib/generalFunctions';
import { Typography } from 'components/Typography/Typography';
import { DialogAddUser } from 'components/DialogAddUser';
import { DialogMessage } from 'components/DialogMessage';
import { InfoIcon } from '../../../../../components/Icons/InfoIcon';

const ROLE_TYPES = {
  org: {
    name: 'Organization memberships',
    buttonText: 'Add Admin Role',
    description: 'Manage existing organization memberships and roles/permissions',
  },
  team: {
    name: 'Team memberships',
    buttonText: 'Add to Team',
    description: 'Manage existing team memberships and roles/permissions',
  },
  project: {
    name: 'Project memberships',
    buttonText: 'Add to Project',
    description: 'Manage existing project memberships and roles/permissions',
  },
  account: {
    name: 'Account Admin Roles',
    buttonText: '',
    description: 'Manage existig account admin roles/permissions',
  },
};

const columns = [
  { id: 'name', label: 'Name', minWidth: '20%' },
  { id: 'role', label: 'Role', minWidth: '60%', align: 'right' },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: '20%',
    align: 'right',
  },
];

const accountColumns = [{ id: 'role_check', label: 'Role', minWidth: '20%' }];

const roleFetchFuncs = {
  org: () => EnumService.getOrgRoles(),
  project: () => EnumService.getProjectRoles(),
  team: () => EnumService.getTeamRoles(),
  account: () => EnumService.getAccountRoles(),
};

const tableLabelStyles = {
  color: '#cacaca',
  fontSize: '14px',
  fontWeight: 300,
  '&.Mui-active': {
    '&&': {
      color: 'white',

      '& * ': {
        color: 'white',
      },
    },
  },
};

const tableContainerStyles = {
  maxHeight: 200,
  borderRadius: '12px',
  backgroundColor: '#303030',
  border: '2px solid #404040',
  width: '75%',
};

export const PermissionsPanelV2 = ({ user, roles, roleType, onEdit, onAdd }) => {
  const [optionRoles, setOptionRoles] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('desc');
  const classes = useStyles();
  const [openAddTo, setOpenAddto] = useState(false);
  const [toRemove, setToRemove] = useState([]);
  const [openDialog, setOpenDialog] = useState({ state: false, callback: () => {} });

  const handleChange = (row, roleId) => {
    const newRoles = roles.filter((role) => role.id !== row.id && role.entity.id === row.entity.id);
    onEdit(row.entity, [...newRoles.map((role) => role.roleId), roleId]);
  };

  const sortProjectRoles = (arr) => {
    if (arr?.length > 2) {
      if (arr.length <= 1) {
        let k = 1 - arr.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(1, 0, arr.splice(2, 1)[0]);
    }
    const descriptions = Object.values(DescriptionRoles);
    arr.forEach((element, i) => {
      element.info = descriptions[i] || '';
    });
    return arr;
  };

  useEffect(() => {
    roleFetchFuncs[roleType]().then((res) => {
      let { data } = res.data;
      if (roleType === 'project') {
        data = sortProjectRoles(data);
      }
      if (roleType === 'account') {
        data = data.map((role) => ({ ...role, checked: !!roles.find((el) => el.roleId === role.id) }));
      }
      setOptionRoles(data);
    });
  }, []);

  const removeRole = (row) => {
    const newRoles = roles.filter((role) => role.id !== row.id && role.entity.id === row.entity.id);
    onEdit(row.entity, [...newRoles.map((role) => role.roleId)]);
    setToRemove([...toRemove, row.id]);
  };

  const changeAccountPermissions = (row) => {
    const newRoles = optionRoles.map((role) => ({
      ...role,
      checked: row.id === role.id ? !role.checked : role.checked,
    }));
    const newRolesP = [...newRoles.filter((el) => el.checked).map((el) => el.id)];
    const callback = () => {
      setOptionRoles(newRoles);
      onEdit({ id: roles[0].entity.id }, newRolesP);
    };
    if (newRolesP.length === 0) {
      setOpenDialog({
        state: true,
        callback,
      });
    } else {
      callback();
    }
  };

  return (
    <div className={classes.panelLayoutV2}>
      <div style={{ width: '20%' }}>
        <span>{ROLE_TYPES[roleType].name}</span>
        <p style={{ color: '#adadad' }}>{ROLE_TYPES[roleType].description}</p>
        {roleType !== 'account' && (
          <Button color="secondary" variant="contained" onClick={() => setOpenAddto(true)}>
            {ROLE_TYPES[roleType].buttonText}
          </Button>
        )}
      </div>
      {roles.length > 0 && (
        <TableContainer sx={tableContainerStyles}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                backgroundColor: '#2B2B2B',
                border: 'none',
              }}
            >
              <TableRow>
                {(roleType === 'account' ? accountColumns : columns).map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: '#2B2B2B', color: '#646464', border: 'none' }}
                    sortDirection={orderBy === column.id ? order : false}
                    onClick={() => setOrderBy(column.id)}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => setOrder(order === 'desc' ? 'asc' : 'desc')}
                      sx={tableLabelStyles}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.length > 0 &&
                (roleType === 'account' ? optionRoles : roles)
                  .filter((row) => !toRemove.includes(row.id))
                  .map((row) => (
                    <TableRow hover key={row.id} sx={{ border: 'none' }}>
                      {(roleType === 'account' ? accountColumns : columns).map((column) => (
                        <TableCell
                          key={`${column.id}_${row.id}`}
                          align={column.align}
                          sx={{ color: '#7f7f7f', border: 'none' }}
                        >
                          {column.id === 'role_check' && (
                            <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                checked={row.checked}
                                sx={{ backgroundColor: '#303030' }}
                                color="secondary"
                                onChange={() => changeAccountPermissions(row)}
                                size="small"
                              />{' '}
                              <div className="text">
                                <Typography>{row.description}</Typography>
                              </div>
                            </div>
                          )}
                          {column.id === 'role' && (
                            <Select
                              id="select-role"
                              defaultValue={row.roleId || optionRoles[0].id}
                              onChange={(el) => handleChange(row, el.target.value)}
                              sx={{ width: '350px', border: 'none', backgroundColor: '#303030' }}
                            >
                              {optionRoles.length > 0 &&
                                optionRoles.map((op) => <MenuItem value={op.id}>{op.name}</MenuItem>)}
                            </Select>
                          )}
                          {column.id === 'actions' && (
                            <Button style={{ color: '#d28984' }} onClick={() => removeRole(row)}>
                              Remove
                            </Button>
                          )}
                          {column.id === 'name' && (
                            <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                              <div className="avatar">
                                <Avatar key={row.id} initials={getInitials(row.entity.name)} size={40} />
                              </div>
                              <div className="text">
                                <Typography>{row.entity.name}</Typography>
                              </div>
                            </div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {roles.length === 0 && (
        <div className={classes.nothingToShow}>
          <span>There are no {roleType}s to show</span>
        </div>
      )}
      {roleType !== 'account' && openAddTo && (
        <DialogAddUser
          isOpen={openAddTo}
          handleClose={(variant) => {
            setOpenAddto(false);
            if (variant) {
              onAdd();
            }
          }}
          userId={user.id}
          userName={`${user.firstName} ${user.lastName}`}
          type={roleType}
          {...(roleType === 'org' && {
            initialValue: { id: roles[0].entity.id, roles } || [],
          })}
        />
      )}
      <DialogMessage
        title="You are about to remove permissions from the user's account, are you sure?"
        isOpen={openDialog.state}
        confirmText="Ok"
        icon={InfoIcon}
        onConfirm={() => {
          openDialog.callback();
          setOpenDialog({ state: false, callback: () => {} });
        }}
        onCancel={() => {
          setOpenDialog({ state: false, callback: () => {} });
        }}
      />
    </div>
  );
};

PermissionsPanelV2.propTypes = {
  user: PropTypes.any,
  roles: PropTypes.arrayOf([
    PropTypes.shape({
      id: PropTypes.string,
      roleId: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      entity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
  ]).isRequired,
  roleType: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
};

PermissionsPanelV2.defaultProps = {
  user: { id: null },
  onEdit: () => {},
  onAdd: () => {},
};
