import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  styledLi: {
    listStyle: 'none',
    margin: 0,
    padding: `${theme.spacing(0.1)} 0`,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  styledLink: (props) => ({
    display: 'flex',
    backgroundColor: props.active ? theme.palette.primary[1100] : theme.palette.primary[1200],
    color: theme.palette.primary[300],
    textDecoration: 'none',
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
    ...(!props.active && {
      '&:hover, &:focus': {
        color: theme.palette.primary[300],
        backgroundColor: theme.palette.primary[900],
        '& svg': {
          color: theme.palette.primary[500],
        },
      },
    }),
    '& svg': {
      color: theme.palette.primary[500],
    },
    borderRadius: props.active ? '1.5px 10px 10px 1.5px' : '10px',
    alignItems: 'center',
    ...(props.active && {
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
    }),
  }),
});
