import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  addUserIcon: {
    backgroundColor: 'white',
    color: theme.palette.secondary.base,
    borderRadius: '50%',
    padding: '10px',
    width: '45px',
    height: '45px',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: '20px !important',
  },
  actions: {
    padding: '20px !important',
  },
  form: {
    backgroundColor: theme.palette.primary[1300],
    padding: '30px',
    height: '100%',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  title: {
    '& > span': {
      fontSize: '13px',
      fontWeight: 'initial',
    },
  },
  addButton: {
    display: 'flex',
    justifyContent: 'end',
    padding: '10px 0',
  },
});
