import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { CirclePlusIcon, FilterIcon, SearchIcon } from 'components/Icons';
import { setFilters, setSearch } from 'slices/projectPermissionsReducer';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { SearchPanel } from '../SearchPanel';
import { useStyles } from './styles';

export const TopBar = ({ viewOption, viewOptions, detail, projectId, openParticipantModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.projectPermissions.filters);
  const search = useSelector((state) => state.projectPermissions.filters.search || '');
  const { setActivePanel, setFullWidth, setConfig } = useContext(SidebarContext);

  return (
    <div className={classes.header}>
      <Grid container spacing={0.5}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {detail ? (
            <Button color="secondary" variant="contained" onClick={openParticipantModal}>
              <CirclePlusIcon size={20} />
              <Typography ml={0.2}>Add New</Typography>
            </Button>
          ) : (
            <>
              <Typography variant="h4">Project User Permissions</Typography>
              <Typography>Change availability &amp; role settings for users and teams.</Typography>
            </>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={0.5}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    viewOption === 'teams' ? viewOptions[0] : viewOption === 'orgs' ? viewOptions[1] : viewOptions[2]
                  }
                  options={viewOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(props) => <TextField {...props} />}
                  onChange={(e, value) =>
                    dispatch(
                      setFilters({
                        sort_by: {},
                        organizationId: '',
                        accountId: '',
                        projectRole: '',
                        activated: false,
                        viewOption: value?.value ? value?.value : '',
                      })
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} className={classes.filters}>
              <TextField
                variant="outlined"
                placeholder="Search"
                className={classes.input}
                InputProps={{
                  startAdornment: <SearchIcon size={20} />,
                }}
                value={search}
                onChange={(e) => dispatch(setSearch(e.target.value))}
              />
              <Button
                size="medium"
                color="primary"
                variant="contained"
                className={`${classes.filterButton} ${filters.activated && classes.activatedFilter}`}
                onClick={() => {
                  setFullWidth(false);
                  setConfig({
                    search: {
                      show: true,
                      component: SearchPanel,
                      type: viewOption,
                      background: detail,
                      projectId,
                    },
                    header: [],
                    actions: [],
                  });
                  setFullWidth(false);
                  setActivePanel('search');
                }}
              >
                <FilterIcon size={15} /> Filters {filters.activated && 'On'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

TopBar.propTypes = {
  viewOption: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  viewOptions: PropTypes.array,
  detail: PropTypes.bool,
  projectId: PropTypes.string,
  openParticipantModal: PropTypes.func,
};

TopBar.defaultProps = {
  viewOption: '',
  detail: false,
  viewOptions: [],
  projectId: '',
  openParticipantModal: () => {},
};
