import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowRightIcon, CloseIcon } from 'components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { setObservation } from 'slices/observationsReducer';
import { Button, Typography, Tooltip } from '@mui/material';
import { ProjectAssetInspFindingSideBar } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/ProjectAssetInspFindingSideBar/ProjectAssetInspFindingSideBar';
import { useStyles } from './styles';

export const ObservationPopup = ({ observation, type, handleObservationClose }) => {
  const classes = useStyles({ type, src: observation.signedUrl || observation?.imagePath });
  const { observations } = useSelector((state) => state.assetObservations);
  const [openFinding, setOpenFinding] = useState(false);
  const observationRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // ------------------- hide popup clicking out side div
    function handleClickOutside(event) {
      if (observationRef.current && !observationRef.current.contains(event.target)) {
        handleObservationClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {((observation?.name && observations.find((item) => item.id === observation.id)) ||
        observation.finding ||
        (observation?.id && observation?.classification)) && (
        <div className={classes.observation} ref={observationRef}>
          <div className={classes.image} />
          <div className={classes.container}>
            <Typography mt={0.5}>{observation.name}</Typography>
            <Typography variant="captions" mt={0.5} mb={0.5}>
              {window.innerWidth < 1700 && observation?.description.length > 30 ? (
                <Tooltip title={observation?.description}>
                  <div className={classes.description}>{observation?.description}</div>
                </Tooltip>
              ) : (
                observation?.description
              )}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                title="Close"
                onClick={handleObservationClose}
                variant="contained"
                className={classes.primaryButton}
              >
                <CloseIcon size={20} />
              </Button>
              <Button
                color="secondary"
                title="Detail"
                onClick={() => {
                  if (observation.finding) {
                    setOpenFinding(observation.id);
                  } else {
                    dispatch(setObservation(observation));
                  }
                  handleObservationClose();
                }}
                variant="contained"
                className={classes.secondaryButton}
              >
                <ArrowRightIcon size={20} />
              </Button>
            </div>
          </div>
        </div>
      )}
      <ProjectAssetInspFindingSideBar findingId={openFinding} fixed handleClose={() => setOpenFinding(false)} />
    </>
  );
};

ObservationPopup.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  observation: PropTypes.object,
  type: PropTypes.string,
  handleObservationClose: PropTypes.func,
};

ObservationPopup.defaultProps = {
  observation: { title: '', description: '', signedUrl: null },
  type: null,
  handleObservationClose: () => {},
};
