import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';
import { FilterIcon, SearchIcon } from 'components/Icons';
import { setSearch } from 'slices/profileProjectAssetReducer';
import { useStyles } from './styles';

export const ProjectAssetsFilters = ({ handleOpenFilters }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const search = useSelector((state) => state.profileProjectAssets.filters.search || '');
  const { filters } = useSelector((state) => state.profileProjectAssets);

  return (
    <div className={classes.filters}>
      <TextField
        variant="outlined"
        placeholder="Search"
        className={classes.input}
        InputProps={{
          startAdornment: <SearchIcon size={20} />,
        }}
        value={search}
        onChange={(e) => {
          dispatch(setSearch(e.target.value));
        }}
      />
      <Button
        size="medium"
        color="primary"
        variant="contained"
        className={`${classes.filterButton} ${filters.activated && classes.activatedFilter}`}
        onClick={() => handleOpenFilters()}
      >
        <FilterIcon size={15} /> Filters {filters.activated && 'On'}
      </Button>
    </div>
  );
};

ProjectAssetsFilters.propTypes = {
  handleOpenFilters: PropTypes.func,
};

ProjectAssetsFilters.defaultProps = {
  handleOpenFilters: () => {},
};
