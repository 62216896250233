import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const EyeIcon2 = ({ size, className }) => (
  <Svg viewBox="0 0 21 16" size={size} className={className}>
    <path
      d="M1.33398 7.99967C1.33398 7.99967 4.66732 1.33301 10.5007 1.33301C16.334 1.33301 19.6673 7.99967 19.6673 7.99967C19.6673 7.99967 16.334 14.6663 10.5007 14.6663C4.66732 14.6663 1.33398 7.99967 1.33398 7.99967Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.5007 10.4997C11.8814 10.4997 13.0006 9.38039 13.0006 7.99967C13.0006 6.61896 11.8814 5.49967 10.5007 5.49967C9.11994 5.49967 8.00065 6.61896 8.00065 7.99967C8.00065 9.38039 9.11994 10.4997 10.5007 10.4997Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

EyeIcon2.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

EyeIcon2.defaultProps = {
  size: 24,
  className: '',
};
