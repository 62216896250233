import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DescriptionRoles, Roles } from 'lib/roles';
import { Checkbox, Typography } from '@mui/material';

const ROLE_OPTIONS = [
  { label: 'Administrator', value: Roles.ORG_ADMIN, info: DescriptionRoles.PROJ_ADMIN, checked: false },
  {
    label: 'Project Coordinator',
    value: Roles.ORG_PROJ_COORD,
    checked: false,
  },
  {
    label: 'Team Coordinator',
    value: Roles.ORG_TM_COORD,
    checked: false,
  },
  {
    label: 'Participant Coordinator',
    value: Roles.ORG_PART_COORD,
    checked: false,
  },
  { label: 'Participant', value: Roles.ORG_PART, checked: false },
  { label: 'View-Only', value: Roles.ORG_VIEW_ONLY, checked: false },
];

export const UserOrgRoleOptions = ({ initialRoles, handleChange }) => {
  const [roles, setRoles] = useState(
    ROLE_OPTIONS.map((el) => ({
      ...el,
      checked: initialRoles.includes(el.value),
    }))
  );
  const onChange = (op) => {
    const newRoles = [
      ...roles.map((el) => ({
        ...el,
        ...(el.value === op && {
          checked: !el.checked,
        }),
      })),
    ];
    setRoles(newRoles);
    handleChange([...newRoles.filter((el) => el.checked).map((el) => el.value)]);
  };

  return (
    <div>
      {roles.map((op) => (
        <div key={op.value} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={op.checked}
            sx={{ paddingLeft: '0' }}
            color="secondary"
            onChange={() => onChange(op.value)}
            size="small"
          />{' '}
          <Typography ml={0.5}>{op.label}</Typography>
        </div>
      ))}
    </div>
  );
};

UserOrgRoleOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  handleChange: PropTypes.func,
  initialRoles: PropTypes.array,
};

UserOrgRoleOptions.defaultProps = {
  handleChange: () => {},
  initialRoles: [],
};
