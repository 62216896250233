import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';
import { FilterIcon, GridIcon, ListIcon, SearchIcon } from 'components/Icons';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { useStyles as useTopBarStyles } from 'components/TopBar/styles';

export const MapGisTopBar = ({ handleSearch, handleToggle, searchValue, activatedFilter }) => {
  const externalClasses = useTopBarStyles({ flex: 'space-between' });
  const { setActivePanel, setFullWidth } = useContext(SidebarContext);
  const [tableToggle, setTableToggle] = useState(false);

  const handleLocalToggle = () => {
    setTableToggle(!tableToggle);
    handleToggle(!tableToggle);
  };

  return (
    <div className={externalClasses.bar}>
      <div>
        {!tableToggle ? (
          <Button
            color="primary"
            variant="contained"
            className={externalClasses.button}
            onClick={() => handleLocalToggle()}
          >
            <ListIcon size={15} />
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            className={externalClasses.button}
            onClick={() => handleLocalToggle()}
          >
            <GridIcon size={15} />
          </Button>
        )}
      </div>
      <div className={externalClasses.filters}>
        <TextField
          variant="outlined"
          placeholder="Search"
          className={externalClasses.input}
          InputProps={{
            startAdornment: <SearchIcon size={20} />,
          }}
          value={searchValue}
          onChange={handleSearch}
        />
        <Button
          size="medium"
          color="primary"
          variant="contained"
          className={`${externalClasses.filterButton} ${activatedFilter && externalClasses.activatedFilter}`}
          onClick={() => {
            setFullWidth(false);
            setActivePanel('search');
          }}
        >
          <FilterIcon size={15} /> Filters {activatedFilter && 'On'}
        </Button>
      </div>
    </div>
  );
};

MapGisTopBar.propTypes = {
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  handleToggle: PropTypes.func,
  activatedFilter: PropTypes.bool,
};

MapGisTopBar.defaultProps = {
  handleSearch: () => {},
  handleToggle: () => {},
  searchValue: '',
  activatedFilter: false,
};
