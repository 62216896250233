import { Typography } from 'components/Typography/Typography';
import styled from 'styled-components';

export const StyledUploadStatusContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 900;
  right: ${(props) => props.theme.spacing(4)};
  width: 500px;
  background-color: #3e3e3e;
  border: 2px solid #2c2c2c;
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing(0.5)};
`;

export const StyledUploadHeader = styled(Typography)`
  font-size: 20px;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing(0.8)};
  height: 20px;
`;

export const StyledUploadFileContainer = styled.div`
  background-color: #222526;
  border-radius: 7px;
  padding: ${(props) => props.theme.spacing(1)} 0;
`;

export const StyledUploadFileScroller = styled.div`
  padding: 0 ${(props) => props.theme.spacing(1)};
  max-height: 200px;
  overflow: auto;
`;

export const StyledUploadFileCounter = styled.div`
  display: block;
  text-align: right;
  margin-top: ${(props) => props.theme.spacing(0.2)};
`;

export const StyledButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
`;
