/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import localforage from 'localforage';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { Loader } from 'components/Loader';
import {
  getProjectAssets,
  setAssetsData,
  setMapExtent,
  setLoading,
  setSelectedSequence,
  clearProjectAsset,
  setFilters,
  setProjectAsset,
} from 'slices/profileProjectAssetReducer';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { PlusIcon } from 'components/Icons';
import { CreateContent } from 'components/CreateContent';
import { SearchPanel } from 'pages/Profile/SingleProject/SearchPanel';
import SplashImage from 'images/ContentSplash.png';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { setObservation, setObservations } from 'slices/observationsReducer';
import { MapSimplePoint } from 'components/MapSimplePoint/MapSimplePoint';
import { resetInspections } from 'slices/inspectionReducer';
import { ProjectAssetsTable } from './ProjectAssetsTable';
import { ProjectAssetsFilters } from './ProjectAssetsFilters';
import { useStyles as projectUseStyles } from '../styles';
import { useStyles } from './styles';
import { ProjectAssetContent } from './ProjectAssetContent';
import { useProjectAssetContentContext } from './ProjectAssetContentContext';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { externalActionType, externalDataType } from 'lib/external-data';

let debounceTimeout = null;

export const ProjectAssets = ({ project }) => {
  const classes = useStyles();
  const projectClasses = projectUseStyles();
  const dispatch = useDispatch();
  const { data, loading, refetchLoading, filters, totalCount } = useSelector((state) => state.profileProjectAssets);
  const { showObservationDetailFromUrl } = useSelector((state) => state.assetObservations);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const { unsavedChanges, setOpenUnsavedDialog, setContinueAction } = useProjectAssetContentContext();
  const [openAddContent, setOpenAddContent] = useState(false);
  const [openAddByUUID, setOpenAddByUUID] = useState(false);

  const loadData = (filterData) => {
    const auxFilters = { ...filterData, sort_by: Object.values(filters.sort_by) };
    dispatch(getProjectAssets({ projId: project.id, filters: auxFilters }));
  };

  const { displayPanel, setHeaderPanels, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
        projectId: project?.id,
        background: true,
      },
    },
  });

  const handleOpenFilters = () => {
    setFullWidth(false);
    displayPanel('search');
  };

  const handleCreateContent = async () => {
    setHeaderPanels([
      {
        name: 'createContent',
        component: CreateContent,
        projectId: project?.id,
      },
    ]);
    setFullWidth(true);
    displayPanel('createContent');
  };

  const setSelecetedAsset = (asset) => {
    // dispatch redux action to load asset data
    setSelectedAsset(asset);
    dispatch(setProjectAsset(asset));
    dispatch(setObservation({}));
    dispatch(setObservations([]));
    dispatch(setSelectedSequence(0));
    displayPanel(false);
  };

  const handleRowClick = (asset) => {
    if (selectedAsset?.id === asset?.id) return;
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
      setContinueAction(() => () => setSelecetedAsset(asset));
    } else {
      setSelecetedAsset(asset);
    }
  };

  const saveData = () => {
    localforage.setItem('data', JSON.stringify(data));
  };
  saveData();

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      loadData(filters);
    }, 500);
    return () => clearTimeout(debounceTimeout);
  }, [filters]);

  useEffect(() => {
    if (data.length > 0) {
      const asset = data.find((item) => item.isTranscodingComplete);
      if (asset?.id) {
        setSelectedAsset(asset);
        dispatch(setProjectAsset(asset));
      } else {
        setSelectedAsset(data[0]);
        dispatch(setProjectAsset(data[0]));
      }
    } else {
      dispatch(setProjectAsset({ id: null }));
      setSelectedAsset(null);
    }
  }, [data]);

  useEffect(() => {
    const auxFilters = {
      teamId: '',
      userId: '',
      pastId: '',
      ownership: '',
      contributorId: '',
      type: '',
      acctId: '',
      date_range: null,
      sort_by: {},
      getExtent: false,
      activated: false,
    };
    if (showObservationDetailFromUrl) {
      const params = new URLSearchParams(window.location.search);
      const pastId = params.get('pastId');
      if (pastId) {
        auxFilters.pastId = pastId;
        auxFilters.activated = true;
      }
    }
    dispatch(setFilters(auxFilters));
    setSelectedAsset(null);
    dispatch(setSelectedSequence(0));
    dispatch(setObservation({}));
    dispatch(setObservations([]));
    return () => {
      dispatch(setFilters(auxFilters));
      dispatch(setMapExtent([]));
      dispatch(setAssetsData([]));
      dispatch(setLoading(true));
      dispatch(clearProjectAsset());
      dispatch(resetInspections());
      dispatch(setProjectAsset({ id: null }));
    };
  }, []);

  const handleCloseUuid = () => {
    setOpenAddByUUID(false);
    loadData(filters);
  };

  return loading ? (
    <Loader height={50} loading />
  ) : (
    <>
      <Grid container spacing={0} className={projectClasses.fullHeight}>
        <Grid item lg={4} md={4} sm={12} xs={12} className={projectClasses.listContainer}>
          <div className={classes.addContentContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenAddContent(!openAddContent)}
              size="small"
              className={classes.button}
            >
              <PlusIcon size={20} /> Add Content
            </Button>
            {openAddContent && (
              <div className={projectClasses.dropDown}>
                <div className="option" key="add-content" onClick={handleCreateContent} aria-hidden="true">
                  New Content
                </div>
                <div className="option" key="by-uuid" onClick={() => setOpenAddByUUID(true)} aria-hidden="true">
                  Add Via UUID
                </div>
              </div>
            )}
          </div>

          {loading ? (
            <Loader loading height={32} />
          ) : (
            <MapSimplePoint
              project={project}
              selectedAsset={selectedAsset}
              handleRowClick={handleRowClick}
              // arcgis={data.filter((item) => item.type === '.arcgis.online')}
            />
          )}
          {refetchLoading ? (
            <Loader height={50} loading size={32} />
          ) : (
            <>
              <ProjectAssetsFilters handleOpenFilters={handleOpenFilters} />
              <div className={`${projectClasses.tableContainer} ${classes.assetsTable}`}>
                <ProjectAssetsTable
                  assets={data}
                  filters={filters}
                  totalCount={totalCount}
                  selectedAsset={selectedAsset?.id}
                  handleRowClick={handleRowClick}
                />
              </div>
            </>
          )}
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12} className={projectClasses.containerDetail}>
          {!selectedAsset?.id ? (
            data?.length > 0 ? (
              <Loader height={50} loading size={32} />
            ) : (
              <WidgetSplash alt="Project Asset Splash" title="There are currently no assets" image={SplashImage} />
            )
          ) : (
            <>
              <ProjectAssetContent
                selectedAsset={selectedAsset}
                project={project}
                openFullScreen={openFullScreen}
                setOpenFullScreen={(flag) => setOpenFullScreen(flag)}
              />
              <div className={`${classes.fullScreen} ${openFullScreen ? classes.openFullScreen : ''}`}>
                {openFullScreen && (
                  <ProjectAssetContent
                    selectedAsset={selectedAsset}
                    project={project}
                    openFullScreen={openFullScreen}
                    setOpenFullScreen={(flag) => setOpenFullScreen(flag)}
                    fullScreen
                  />
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
      {openAddByUUID && (
        <AddUuid
          handleClose={handleCloseUuid}
          type={externalDataType.ASSET}
          item={{ type: externalActionType.ADD, projId: project.id }}
        />
      )}
    </>
  );
};

ProjectAssets.propTypes = {
  project: PropTypes.object,
};

ProjectAssets.defaultProps = {
  project: null,
};
