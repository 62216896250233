import React, { useState } from 'react';
import * as msal from '@azure/msal-browser';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Button,
  Divider,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { userLogin, userLoginAD } from 'slices/authReducer';
import AuthService from 'services/AuthService';
import Logo from '../../../images/CartovidTransparent.png';
import { SignInButton } from './SignInButton/SignInButton';
import { SamIcon } from 'components/Icons';
import { useStyles } from './styles'; // , PasswordWrraper

export const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const error = useSelector((state) => state.auth.error);
  const loginStatus = useSelector((state) => state.auth.loginStatus);

  const getCookie = (CName) => {
    const name = `${CName}=`;
    const cDecoded = decodeURIComponent(document.cookie); // to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  };

  // --------------------- get dropdown select value
  React.useEffect(() => {
    const getAccounts = async () => {
      const result = await AuthService.ssoAccounts();
      setAccounts(result.data.accounts);
    };

    getAccounts();
  }, [loginStatus]);

  const optionHandler = (acc) => {
    console.warn(acc);
    const request = {
      scopes: ['User.Read'],
    };
    const config = {
      auth: {
        clientId: acc.client,
        authority: acc.authority,
      },
    };
    const accountID = acc.id;
    try {
      const msalObj = new msal.PublicClientApplication(config);
      msalObj.loginPopup(request).then((loginResponse) => {
        // Display signed-in user content, call API, etc.
        dispatch(userLoginAD({ accessToken: loginResponse.accessToken, account: accountID }));
      });
    } catch (err) {
      /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: getCookie('staySignedIn') ? JSON.parse(getCookie('staySignedIn')).email : '',
      staySignedIn: !!getCookie('staySignedIn'),
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^\S*$/, 'Email can not contain space')
        .email('Invalid email address')
        .required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async ({ email, password, staySignedIn }) => {
      console.warn(password); // ignore no-console
      let date = new Date(); // Now
      date = new Date(date.setDate(date.getDate() + 30)).toUTCString();
      if (staySignedIn) {
        document.cookie = `staySignedIn=${JSON.stringify({
          email,
          password,
        })}; expires=${date};`;
      } else {
        document.cookie = 'staySignedIn=; max-age=0';
      }
      dispatch(userLogin({ email, password }));
    },
  });

  return (
    <Grid container spacing={1} style={{ height: '100%' }}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {/* <AuthLogo src={Logo} alt="Cartovid Logo" /> */}
        <div className={classes.leftSection}>
          <img src={Logo} alt="Cartovid Logo" className={classes.logo} />
          <Typography variant="caption" className={classes.copyRight}>
            <span style={{ marginRight: 4 }}>Powered by</span>
            <SamIcon size={40} />
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.rightSection}>
        <div className={classes.loginContent}>
          <Typography variant="h1" mb={1}>
            Login to your account
          </Typography>
          <Typography variant="h5" className={classes.subtitle}>
            Welcome back! Please enter your details.
          </Typography>
          <form onSubmit={formik.handleSubmit} noValidate style={{ marginTop: 20 }}>
            {error && (
              <Typography variant="h5" color="error">
                {error}
              </Typography>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel>Email</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    value={formik.values.email}
                    error={!!(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Email"
                    type="email"
                    name="email"
                    id="email"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel mt={2}>Password</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    name="password"
                    id="password"
                    type={passwordShown ? 'text' : 'password'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={!!(formik.touched.password && formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          color="secondary"
                          aria-label="toggle password visibility"
                          onClick={() => setPasswordShown(!passwordShown)}
                          edge="end"
                        >
                          {passwordShown ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.loginWidgets}>
              <FormControlLabel
                label="Remember me"
                control={<Checkbox color="secondary" />}
                name="staySignedIn"
                id="staySignedIn"
                onChange={formik.handleChange}
                checked={formik.values.staySignedIn}
              />
              <Link to="request-reset" className={classes.link}>
                Forgot Password
              </Link>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loginStatus === 'loading'}
              fullWidth
              className={classes.signInButton}
            >
              Sign in
            </Button>
            <Divider orientation="horizontal" flexItem className={classes.divider}>
              OR
            </Divider>
            <div className={classes.formButton}>
              {accounts.length > 0 && <SignInButton options={accounts} optionHandler={optionHandler} />}
              <Typography variant="caption">
                By entering CartoVid you are agreeing to our
                <br />
                <a
                  target="_blank"
                  href="https://app.termly.io/document/terms-of-use-for-website/88be8162-c09e-4d54-84f8-4f8e28640bea"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  href="https://app.termly.io/document/cookie-policy/dcc9fe8d-76c7-4921-8ffd-104e58bbfb7c"
                  rel="noreferrer"
                >
                  Cookie Policy
                </a>
              </Typography>
              <Typography variant="caption" className={classes.block}>
                Interested in joining CartoVid?
                <a target="_blank" href="https://theoremgeo.com/contact-2/" rel="noreferrer" className={classes.link}>
                  Contact Us
                </a>
              </Typography>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};
