/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { useDispatch } from 'react-redux';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { Button, FormControl, FormLabel, Checkbox, FormControlLabel, Stack } from '@mui/material'; // , RadioGroup, FormControlLabel, Radio,
import { CalendarModal } from 'components/CalendarModal';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { setAssetsData, setPageSize, setRefetchLoading } from 'slices/profileProjectAssetReducer';
import ProjectService from 'services/ProjectService';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { MapExtentModal, distanceInKm } from './MapExtentModal/MapExtentModal';

export const searchPanelSection = {
  PROJECTASSETS: 'project_content',
  ADMINCONTENT: 'admin_content',
  PROFILECONTENT: 'profile_content',
  EXTERNALCONTENT: 'external_content',
};

export const SearchPanelBody = ({
  loaded,
  filters,
  users,
  accounts,
  organizations,
  projects,
  project,
  teams,
  selectedAsset,
  setFilters,
  section,
}) => {
  const dispatch = useDispatch();
  const { config } = useContext(SidebarContext);
  const projectId = config.search?.projectId;
  const [openCalendar, setOpenCalendar] = useState(false);
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    date_range: null,
    userId: '',
    acctId: '',
    teamId: '',
    ownership: '',
  });
  const [modalMapOpen, setModalMapOpen] = useState(false);
  const [extentCoords, setExtentCoords] = useState([]);
  const [auxCoords, setAuxCoords] = useState([]);

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update checkbox filters
  const checkBoxFilters = (value, checked, filter) => {
    const auxFilters = { ...localFilters };
    if (checked) {
      if (typeof auxFilters[filter] === 'string' || !Array.isArray(auxFilters[filter]) || !auxFilters[filter])
        auxFilters[filter] = [];
      auxFilters[filter] = [...auxFilters[filter]];
      auxFilters[filter].push(value);
    } else {
      auxFilters[filter] = auxFilters[filter].filter((element) => element !== value);
    }
    setLocalFilters({ ...auxFilters });
  };

  // update filters
  const updateFIlter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // -------------- clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          pastId: '',
          teamId: '',
          userId: '',
          acctId: '',
          organizationId: '',
          date_range: null,
          sort_by: {},
          type: '',
          status: '',
          ownership: '',
          contributorId: '',
          getExtent: false,
          activated: false,
        })
      );
    }
  };

  const filterAssets = async (limits, defaultAsset, auxFilters) => {
    // send the request to the new service
    dispatch(setRefetchLoading(true));
    ProjectService.getExtentProjectAssets(projectId, limits.minLat, limits.minLon, limits.maxLat, limits.maxLon).then(
      (res) => {
        const idsToExport = [];
        const assetsIds = res.data.data;
        if (assetsIds.length > 0) {
          assetsIds.map((asset) => idsToExport.push(asset.past_id));

          // Get assets data
          dispatch(setFilters({ ...auxFilters, pastId: idsToExport, activated: filterActions.length > 0 }));
        } else {
          const distanceToMin = distanceInKm(
            selectedAsset.longitude,
            selectedAsset.latitude,
            limits.minLon,
            limits.minLat
          );
          const distanceToMax = distanceInKm(
            selectedAsset.longitude,
            selectedAsset.latitude,
            limits.maxLon,
            limits.maxLat
          );
          if (distanceToMin >= 10 || distanceToMax >= 10) {
            dispatch(setAssetsData([]));
          } else {
            dispatch(setAssetsData([defaultAsset]));
            dispatch(setPageSize(1));
          }
        }
      }
    );
  };

  const handleGetExtent = (coords, auxFilters) => {
    if (coords.length > 0) {
      const finalCoords = { minLat: 999, maxLat: -999, minLon: 999999999999999, maxLon: 0 };
      coords.map((coor) => {
        if (coor.lat < finalCoords.minLat) finalCoords.minLat = coor.lat;
        if (coor.lat > finalCoords.maxLat) finalCoords.maxLat = coor.lat;
        if (Math.abs(coor.long) < Math.abs(finalCoords.minLon)) finalCoords.minLon = coor.long;
        if (Math.abs(coor.long) > Math.abs(finalCoords.maxLon)) finalCoords.maxLon = coor.long;
        return coor;
      });
      filterAssets(finalCoords, selectedAsset, auxFilters);
    }
  };

  const clearExtent = () => {
    const auxFilters = { ...localFilters };
    auxFilters.getExtent = false;
    auxFilters.pastId = null;
    setLocalFilters({ ...auxFilters });
    setExtentCoords([]);
  };

  // -------------- apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    if (auxFilters.getExtent && extentCoords.length > 0) {
      handleGetExtent(extentCoords, auxFilters);
    } else {
      dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
    }
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters?.pastId && !clearFilters.getExtent)
      auxFilters.push({ text: 'Clear By Content', callBack: () => updateNormalFilters(null, 'pastId') });
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.getExtent) auxFilters.push({ text: 'Clear By Get Extent', callBack: () => clearExtent() });
    if (clearFilters.date_range !== null && clearFilters.date_range)
      auxFilters.push({ text: 'Clear Date Range', callBack: () => updateNormalFilters(null, 'date_range') });
    if (clearFilters?.ownership)
      auxFilters.push({ text: 'Clear By Ownership', callBack: () => updateNormalFilters('', 'ownership') });
    if (clearFilters?.type?.length > 0)
      auxFilters.push({ text: 'Clear By Type', callBack: () => updateNormalFilters('', 'type') });
    if (clearFilters?.status?.length > 0)
      auxFilters.push({ text: 'Clear By Storage Status', callBack: () => updateNormalFilters('', 'status') });
    if (clearFilters?.organizationId?.length > 0)
      auxFilters.push({ text: 'Clear By Organization', callBack: () => updateNormalFilters(null, 'organizationId') });
    if (clearFilters?.acctId?.length > 0)
      auxFilters.push({ text: 'Clear By Account', callBack: () => updateNormalFilters(null, 'acctId') });
    if (clearFilters?.userId?.length > 0)
      auxFilters.push({ text: 'Clear By User', callBack: () => updateNormalFilters(null, 'userId') });
    if (clearFilters?.teamId?.length > 0)
      auxFilters.push({ text: 'Clear By Team', callBack: () => updateNormalFilters(null, 'teamId') });
    if (clearFilters?.projectId?.length > 0)
      auxFilters.push({ text: 'Clear By Project', callBack: () => updateNormalFilters(null, 'projectId') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  // aplly coordinates get extent
  const setApplyExtentCoords = () => {
    updateNormalFilters(true, 'getExtent');
    setExtentCoords(auxCoords);
    setModalMapOpen(false);
  };

  return (
    <>
      {modalMapOpen && (
        <MapExtentModal
          project={project}
          selectedAsset={selectedAsset}
          setApplyExtentCoords={setApplyExtentCoords}
          setAuxCoords={setAuxCoords}
          handleClose={() => setModalMapOpen(false)}
        />
      )}
      <SidebarPanel
        title="Filter Project Content"
        subTitle="Apply filters to table data."
        filters
        handleClearFilters={handleClearFilters}
        handleApplyFilters={handleApplyFilters}
      >
        <div className="searchBody">
          <ClearFilterAction actions={filterActions} />
          <FormControl>
            <FormLabel>Sort</FormLabel>
            <FormRadioGroup
              name="alphabetical"
              value={localFilters.sort_by.name}
              onChecked={(checkedVal) => {
                const sortBy = { ...localFilters.sort_by };
                sortBy.name = checkedVal;
                updateNormalFilters(sortBy, 'sort_by');
              }}
              options={[
                { label: 'A - Z', value: '+name', disabled: false },
                { label: 'Z - A', value: '-name', disabled: false },
                { label: 'None', value: '', disabled: false },
              ]}
              view="horizontal"
            />
            <FormRadioGroup
              name="date-by"
              value={localFilters.sort_by.createdAt}
              onChecked={(checkedVal) => {
                const sortBy = { ...localFilters.sort_by };
                sortBy.createdAt = checkedVal;
                updateNormalFilters(sortBy, 'sort_by');
              }}
              options={[
                { label: 'Newest', value: '-createdAt', disabled: false },
                { label: 'Oldest', value: '+createdAt', disabled: false },
                { label: 'None', value: '', disabled: false },
              ]}
              view="horizontal"
            />
            {searchPanelSection.EXTERNALCONTENT === section && (
              <FormRadioGroup
                name="accountSort"
                value={localFilters.sort_by.account}
                onChecked={(checkedVal) => {
                  const sortBy = { ...localFilters.sort_by };
                  sortBy.account = checkedVal;
                  updateNormalFilters(sortBy, 'sort_by');
                }}
                options={[
                  {
                    label: 'Sort by Leading Account',
                    value: 'account',
                    disabled: false,
                    columns: 2,
                  },
                  { label: 'None', value: '', disabled: false },
                ]}
                view="horizontal"
              />
            )}
          </FormControl>
          <FormControl className="marginTop">
            <Stack spacing={2} direction="row">
              {searchPanelSection.PROJECTASSETS === section && (
                <Button color="secondary" variant="contained" onClick={() => setModalMapOpen(true)}>
                  Get Extent
                </Button>
              )}
              <Button
                color={searchPanelSection.PROJECTASSETS === section ? 'primary' : 'secondary'}
                variant="contained"
                onClick={() => setOpenCalendar(true)}
              >
                By Date Range
              </Button>
            </Stack>
          </FormControl>
          {false && (
            <FormControl className="marginTop">
              <FormLabel>Favorites</FormLabel>
              <FormControlLabel control={<Checkbox />} label="Only Show Favorites" />
            </FormControl>
          )}
          {section !== searchPanelSection.EXTERNALCONTENT && section !== searchPanelSection.PROFILECONTENT && (
            <FormControl className="marginTop">
              <FormLabel>Ownership</FormLabel>
              <FormRadioGroup
                name="ownership"
                value={localFilters.ownership}
                onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'ownership')}
                options={[
                  { label: 'Your Content', value: 'yourContent', disabled: false },
                  ...(searchPanelSection.PROJECTASSETS === section
                    ? [
                        {
                          label: 'Shared Content',
                          value: 'sharedContent',
                          disabled: false,
                        },
                      ]
                    : []),
                  { label: 'All', value: '', disabled: false },
                ]}
                view="horizontal"
              />
            </FormControl>
          )}
          <FormControl className="marginTop">
            <FormLabel>File Type</FormLabel>
            <Stack spacing={2} direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label="Video"
                onChange={(event) => checkBoxFilters('VIDEO', event.target.checked, 'type')}
                checked={localFilters?.type?.length > 0 && localFilters.type.includes('VIDEO')}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Image"
                onChange={(event) => checkBoxFilters('PHOTO', event.target.checked, 'type')}
                checked={localFilters?.type?.length > 0 && localFilters.type.includes('PHOTO')}
              />
            </Stack>
          </FormControl>
          <FormControl className="marginTop">
            <FormLabel>Storage Status</FormLabel>
            <Stack spacing={2} direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label="Available"
                onChange={(event) => checkBoxFilters('NEAR', event.target.checked, 'status')}
                checked={localFilters?.status?.length > 0 && localFilters.status.includes('NEAR')}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Archived"
                onChange={(event) => checkBoxFilters('ARCHIVED', event.target.checked, 'status')}
                checked={localFilters?.status?.length > 0 && localFilters.status.includes('ARCHIVED')}
              />
            </Stack>
          </FormControl>
          {false && (
            <FormControl className="marginTop">
              <FormLabel>Visibility</FormLabel>
              <FormRadioGroup
                name="visibility"
                value={localFilters.visibility}
                onChecked={(checkedVal) => {
                  const sortBy = { ...localFilters.visibility };
                  sortBy.visibility = checkedVal;
                  updateNormalFilters(sortBy, 'sort_by');
                }}
                options={[
                  { label: 'Hidden', value: 'hidden', disabled: false },
                  { label: 'Visible', value: 'visible', disabled: false },
                  { label: 'All', value: '', disabled: false },
                ]}
                view="horizontal"
              />
            </FormControl>
          )}
          {(section === searchPanelSection.PROJECTASSETS || section === searchPanelSection.EXTERNALCONTENT) && (
            <SearchList
              title="Account"
              fullList={accounts}
              filter="acctId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
          )}
          {(section === searchPanelSection.ADMINCONTENT || searchPanelSection.PROFILECONTENT === section) && (
            <SearchList
              title="Find Content by Organization"
              fullList={organizations}
              filter="organizationId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
          )}
          {searchPanelSection.EXTERNALCONTENT !== section && searchPanelSection.PROFILECONTENT !== section && (
            <>
              <SearchList
                title="User"
                fullList={users}
                filter="userId"
                localFilters={localFilters}
                onChangeSelect={updateFIlter}
                loaded={loaded}
              />
              <SearchList
                title="Team"
                fullList={teams}
                filter="teamId"
                localFilters={localFilters}
                onChangeSelect={updateFIlter}
                loaded={loaded}
              />
            </>
          )}
          {section !== searchPanelSection.PROJECTASSETS && (
            <SearchList
              title="Find Content by Project"
              fullList={projects}
              filter="projectId"
              localFilters={localFilters}
              onChangeSelect={updateFIlter}
              loaded={loaded}
            />
          )}
          {openCalendar && (
            <CalendarModal
              rangeDate={localFilters.date_range}
              handleClose={() => setOpenCalendar(false)}
              handleConfirm={(rangeDate) => {
                if (rangeDate.length > 0 && rangeDate[0].startDate !== null && rangeDate[0].endDate !== null)
                  updateNormalFilters([rangeDate[0].startDate, rangeDate[0].endDate], 'date_range');
                setOpenCalendar(false);
              }}
            />
          )}
        </div>
      </SidebarPanel>
    </>
  );
};

SearchPanelBody.propTypes = {
  loaded: PropTypes.bool,
  filters: PropTypes.object,
  users: PropTypes.array,
  accounts: PropTypes.array,
  organizations: PropTypes.array,
  projects: PropTypes.array,
  teams: PropTypes.array,
  selectedAsset: PropTypes.any,
  setFilters: PropTypes.func,
  section: PropTypes.string,
};

SearchPanelBody.defaultProps = {
  loaded: false,
  filters: null,
  users: [],
  teams: [],
  accounts: [],
  projects: [],
  organizations: [],
  selectedAsset: null,
  setFilters: () => {},
  section: '',
};
