import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const CloseIcon2 = ({ size }) => (
  <Svg viewBox="0 0 21 20" size={size}>
    <path
      id="Icon"
      d="M15.5 5L5.5 15M5.5 5L15.5 15"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

CloseIcon2.propTypes = {
  size: PropTypes.number,
};

CloseIcon2.defaultProps = {
  size: 24,
};
