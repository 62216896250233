import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const SelectAllIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      d="M7 5C7 6.10457 6.10457 7 5 7C3.89543 7 3 6.10457 3 5C3 3.89543 3.89543 3 5 3C6.10457 3 7 3.89543 7 5Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M8 4.75C8 5.16421 8.33579 5.5 8.75 5.5L15.25 5.5C15.6642 5.5 16 5.16421 16 4.75C16 4.33579 15.6642 4 15.25 4L8.75 4C8.33579 4 8 4.33579 8 4.75Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M4.75 8C4.33579 8 4 8.33579 4 8.75V15.25C4 15.6642 4.33579 16 4.75 16C5.16421 16 5.5 15.6642 5.5 15.25V8.75C5.5 8.33579 5.16421 8 4.75 8Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M19.25 8C18.8358 8 18.5 8.33579 18.5 8.75V15.25C18.5 15.6642 18.8358 16 19.25 16C19.6642 16 20 15.6642 20 15.25V8.75C20 8.33579 19.6642 8 19.25 8Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M8.75 20C8.33579 20 8 19.6642 8 19.25C8 18.8358 8.33579 18.5 8.75 18.5H15.25C15.6642 18.5 16 18.8358 16 19.25C16 19.6642 15.6642 20 15.25 20H8.75Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M5 21C6.10457 21 7 20.1046 7 19C7 17.8954 6.10457 17 5 17C3.89543 17 3 17.8954 3 19C3 20.1046 3.89543 21 5 21Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M21 5C21 6.10457 20.1046 7 19 7C17.8954 7 17 6.10457 17 5C17 3.89543 17.8954 3 19 3C20.1046 3 21 3.89543 21 5Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21Z"
      stroke="currentColor"
      fill="none"
    />
  </Svg>
);

SelectAllIcon.propTypes = {
  size: PropTypes.number,
};

SelectAllIcon.defaultProps = {
  size: 24,
};
