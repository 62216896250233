import ApiService from './ApiService';

export default class ProjectService {
  static getProjects = (filters) => ApiService.get('/projects', filters);

  static getProject = async (projId) => ApiService.get(`/projects/${projId}`);

  static deleteProject = async (projectId) => ApiService.delete(`/projects/${projectId}`);

  static getProjectAssets = async (projId, filters = {}) => ApiService.get(`/projects/${projId}/assets`, filters);

  static getArcgisAssets = async () => ApiService.get('/arcgis-cartovid');

  static getArcgisLatLon = async (webmapId) => ApiService.get(`/arcgis-wrapper/layers/spatial-geometry/${webmapId}`);

  static getProjectAssetAccounts = (projId) => ApiService.get(`/projects/${projId}/assets/accounts`);

  static getSpecificProjectAssets = async (projId, assetIds = []) =>
    ApiService.get(`/projects/${projId}/assets`, { assets: assetIds });

  static getSpecificAssets = async (projId, assetIds = []) =>
    ApiService.post(`/projects/${projId}/assets/getSpecificAssets`, { assets: assetIds });

  static addSequence = async (projId, assetId, data) =>
    ApiService.post(`/projects/${projId}/assets/${assetId}/addSequence`, data);

  static deleteSequence = async (projId, assetId, sequenceId) =>
    ApiService.delete(`/projects/${projId}/assets/${assetId}/deleteSequence/${sequenceId}`);

  static updateSequence = async (projId, assetId, sequencesData) =>
    ApiService.patch(`/projects/${projId}/assets/${assetId}/updateSequences`, sequencesData);

  static getExtentProjectAssets = async (projId, minLat, minLon, maxLat, maxLon) =>
    ApiService.get(`/projects/${projId}/assets/getextent/${minLat}/${minLon}/${maxLat}/${maxLon}`);

  static getProjectAsset = async (projId, assetId) => ApiService.get(`/projects/${projId}/assets/${assetId}`);

  static getProjectAccounts = (filter) => ApiService.get('/projects/all/accounts', filter);

  static getProjectOrganizations = (filter) => ApiService.get('/projects/all/organizations', filter);

  static getProjectUsers = (projId) => ApiService.get(`/projects/${projId}/users`);

  static deleteProjectAsset = async (projId, assetId) => ApiService.delete(`/projects/${projId}/assets/${assetId}`);

  static ignorePerms = async (projId, assetId) =>
    ApiService.patch(`/projects/${projId}/assets/${assetId}/ignorePerms`, {});

  static viewArchive = async (projId, assetId, credits) =>
    ApiService.post(`/projects/${projId}/assets/${assetId}/viewArchive`, { credits });

  static updateProjectAsset = async (projId, assetId, data) =>
    ApiService.patch(`/projects/${projId}/assets/${assetId}`, data);

  static createProject = async (project) => ApiService.post(`/projects`, project);

  static getSharedProjectAsset = async (projId, assetId, external) =>
    ApiService.get(`/projects/${projId}/assets/${assetId}/share`, { external });

  static ShareAssetWithProject = async (projId, assetId, data) =>
    ApiService.post(`/projects/${projId}/assets/share?assetId=${assetId}`, data);

  static DeleteShareAssetWithProject = async (projId, assetId, sharedId) =>
    ApiService.delete(`/projects/${projId}/assets/${assetId}/share/${sharedId}`);

  static CopyAssetWithProject = async (projId, assetId, destinationProjectId) =>
    ApiService.post(`/projects/${projId}/assets/copy/${assetId}/${destinationProjectId}`);

  static addTeamToProject = async (projId, teamId) =>
    ApiService.post(`/projects/${projId}/roles/${teamId}`, {
      roles: [13, 14, 15],
    });

  /**
   * Cancel an upload after it has started
   *
   * @param {string} projId
   * @param {string} assetId
   * @returns
   */
  static deleteProjectAssetUpload = async (projId, assetId) =>
    ApiService.delete(`/projects/${projId}/assets/${assetId}/cancelUpload`);

  /**
   * Update a project
   *
   * @param {string} projId
   * @param {{
   *  name?: string;
   *  description?: string;
   *  isVisible?: boolean;
   *  isOpen?: boolean;
   *  isZoomEnalbed?: boolean;
   *  isCopyAllowed?: boolean;
   *  thumbnailAssetId?: string;
   *  thumbnailType?: string;
   * }} data
   * @returns
   */
  static updateProject = async (projId, data) => ApiService.patch(`/projects/${projId}`, data);

  /**
   * Update the project roles for a user or team
   * @param {string} projId
   * @param {string} userTeamId Can be either a user id or a team id
   * @param {number[]} roles
   * @returns
   */
  static updateUserOrTeamRoles = async (projId, userTeamId, roles, bySelf) =>
    ApiService.post(`/projects/${projId}/roles/${userTeamId}`, { roles, bySelf });

  /**
   *
   * @param {string} projId
   * @param {{
   *  projectAssetSequenceId: string;
   *  type: string;
   *  name: string;
   *  description: string;
   *  lat: number;
   *  lng: number;
   *  time: number;
   * }} data
   * @returns
   */
  static getAssetObservations = async (projectId, assetId) =>
    ApiService.get(`/projects/${projectId}/assets/${assetId}/observation/getAll`);

  static createObservationPoint = async (projId, data) =>
    ApiService.post(`/projects/${projId}/assets/observation`, data);

  static updateObservationPoint = async (projId, observationId, data) =>
    ApiService.patch(`/projects/${projId}/assets/observation/${observationId}`, data);

  static deleteObservationPoint = async (projId, observationId) =>
    ApiService.delete(`/projects/${projId}/assets/observation/${observationId}`);

  static emailObservationPoint = async (projId, observationId, data) =>
    ApiService.post(`/projects/${projId}/assets/observation/${observationId}/email`, data);

  static singUrlObservationPoint = async (projId, observationId) =>
    ApiService.get(`/projects/${projId}/assets/observation/${observationId}/signUrl`);

  static getImageUrlObservationPoint = async (projId, observationId) =>
    ApiService.get(`/projects/${projId}/assets/observation/${observationId}/imageUrl`);

  static uploadObservationPointAsset = async (url, blob) => {
    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.open('PUT', url);
      xhr.setRequestHeader('Content-Type', blob.type);
      xhr.onload = () => {
        resolve(xhr.response);
      };
      xhr.onerror = () => {
        reject(xhr.response);
      };
      xhr.send(blob);
    });
  };

  /**
   * Get a still image zoom from a a project asset
   *
   * @param {string} projId
   * @param {string} assetId
   * @param {number} time
   * @param {'VIDEO' | 'PHOTO' | 'DOCUMENT'} type
   * @returns
   */
  static getProjectAssetZoomFrame = async (projId, assetId, time, type = 'VIDEO') =>
    ApiService.get(`/projects/${projId}/assets/${assetId}/${type}/frame/${time}`, null, { responseType: 'blob' });
  /**
   * Get a observation point image
   *
   * @param {string} observationId
   * @returns
   */

  static getProjectAssetObservationImage = async (projId, assetId, observationId) =>
    ApiService.get(`/projects/${projId}/assets/${assetId}/observation/${observationId}/image`, null, {
      responseType: 'blob',
    });

  static addAssetToProjectViaUuid = async (projId, assetId) =>
    ApiService.post(`/projects/${projId}/assets/${assetId}/addViaUuid`, null);

  static getKMLFile = async (projId, type) =>
    ApiService.get(`/projects/${projId}/assets/kml`, null, { params: { type } });

  static getProjectObservations = async (projId) => ApiService.get(`/projects/${projId}/observations`);
}
