import { List } from '@mui/material';
import { ContextMenuContext } from 'components/ContextMenu';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

/**
 * Determine placement for the menu
 *
 * @param {object} positionData
 * @param {string} position
 */
const getPositionStyle = (positionData, position) => {
  const { menu, button } = positionData;

  switch (position) {
    case 'top':
      return {
        top: button.top - menu.height,
        left: button.left,
      };
    case 'right':
      return {
        top: button.top,
        left: button.left + button.width,
      };
    case 'left':
      return {
        top: button.top,
        left: button.left - menu.width,
      };
    case 'top-right':
      return {
        top: button.top - menu.height,
        left: button.left + button.width - menu.width,
      };
    case 'bottom-right':
      return {
        top: button.height + button.top,
        left: button.left + button.width - menu.width,
      };
    case 'bottom':
    default:
      return {
        top: button.height + button.top,
        left: button.left,
      };
  }
};

const useStyles = makeStyles({
  list: (props) => ({
    width: '250px',
    border: `1px solid ${theme.palette.primary[1000]}`,
    borderRadius: '8px',
    overFlowX: 'visible',
    position: 'absolute !important',
    padding: 0,
    boxShadow: '0px 6px 13px 0px rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.palette.primary.dark,
    zIndex: 999,
    top: `${props?.position?.top}px`,
    left: `${props?.position?.left}px`,
    color: theme.palette.white,
  }),
});

/**
 * Render a list of menu items when the context value for isOpen is true
 */
export const ContextMenuList = ({ position, children }) => {
  const { isOpen, menuRef, buttonRef, positionData } = useContext(ContextMenuContext);

  let positionStyle = {};

  if (buttonRef.current) {
    positionStyle = getPositionStyle(positionData, position);
  }
  const classes = useStyles({ position: positionStyle });

  return (
    <>
      {isOpen && (
        <List ref={menuRef} className={classes.list} data-testid="menu-list">
          {children}
        </List>
      )}
    </>
  );
};

ContextMenuList.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left', 'top-right', 'bottom-right']),
  children: PropTypes.node.isRequired,
};

ContextMenuList.defaultProps = {
  position: 'bottom-right',
};
