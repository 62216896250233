import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const FileCheckIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <g id="file-check-02">
      <path
        id="Icon"
        d="M20 12.5081V6.80806C20 5.1279 20 4.28782 19.673 3.64609C19.3854 3.0816 18.9265 2.62266 18.362 2.33504C17.7202 2.00806 16.8802 2.00806 15.2 2.00806H8.8C7.11984 2.00806 6.27976 2.00806 5.63803 2.33504C5.07354 2.62266 4.6146 3.0816 4.32698 3.64609C4 4.28782 4 5.1279 4 6.80806V17.2081C4 18.8882 4 19.7283 4.32698 20.37C4.6146 20.9345 5.07354 21.3935 5.63803 21.6811C6.27976 22.0081 7.11984 22.0081 8.8 22.0081H12M14 11.0081H8M10 15.0081H8M16 7.00806H8M14.5 19.0081L16.5 21.0081L21 16.5081"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </Svg>
);

FileCheckIcon.propTypes = {
  size: PropTypes.number,
};

FileCheckIcon.defaultProps = {
  size: 24,
};
