import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const SkipBackIcon = ({ size }) => (
  <Svg viewBox="0 0 21 20" size={size}>
    <path
      id="Icon"
      d="M4.66602 15.8337V4.16699M14.1664 5.06667L9.3008 8.95917C8.85593 9.31506 8.63349 9.49301 8.55296 9.70799C8.48235 9.89649 8.48235 10.1042 8.55296 10.2927C8.63349 10.5076 8.85593 10.6856 9.3008 11.0415L14.1664 14.934C14.8599 15.4888 15.2066 15.7662 15.4984 15.7665C15.7522 15.7667 15.9923 15.6514 16.1506 15.453C16.3327 15.2249 16.3327 14.7809 16.3327 13.8928V6.10783C16.3327 5.21974 16.3327 4.7757 16.1506 4.54764C15.9923 4.34929 15.7522 4.23391 15.4984 4.23418C15.2066 4.2345 14.8599 4.51189 14.1664 5.06667Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

SkipBackIcon.propTypes = {
  size: PropTypes.number,
};

SkipBackIcon.defaultProps = {
  size: 24,
};
