import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const CirclePlusIcon2 = ({ size }) => (
  <Svg viewBox="0 0 21 20" size={size}>
    <path
      id="Icon"
      d="M10.4993 6.66699V13.3337M7.16602 10.0003H13.8327M18.8327 10.0003C18.8327 14.6027 15.1017 18.3337 10.4993 18.3337C5.89698 18.3337 2.16602 14.6027 2.16602 10.0003C2.16602 5.39795 5.89698 1.66699 10.4993 1.66699C15.1017 1.66699 18.8327 5.39795 18.8327 10.0003Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

CirclePlusIcon2.propTypes = {
  size: PropTypes.number,
};

CirclePlusIcon2.defaultProps = {
  size: 24,
};
