import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  content: {
    background: 'none',
    color: theme.palette.white,
    height: '110vh',
    paddingTop: '20px',
  },
  panel: {
    paddingTop: theme.spacing(1),
  },
  containerDetail: {
    position: 'sticky',
    height: '95vh',
    bottom: 0,
    backgroundColor: '#303030',
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 1000,
    transform: `translateX('110%'})`,
    transition: 'transform 0.3s ease-out',
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    color: '#acacac',
  },
  header: {
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    backgroundColor: '#2b2b2b',
    boxShadow: '0 1px 2px 0 #000000',
  },
  inputForm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '10px',
  },
  userName: {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    color: 'white',
  },
  container: {
    overflow: 'scroll',
    height: '84vh',
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    padding: theme.spacing(1.5),
    paddingTop: '0px',
  },
});
