import PropTypes from 'prop-types';
import React from 'react';
import * as uuid from 'uuid';
import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';

export const SecondaryHeader = ({ title, children, actions, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.secondaryHeaderContent}>
      <Typography variant="h4" className={classes.secondaryHeaderText}>
        {title}
      </Typography>
      {children}
      <Typography className={classes.SecondaryHeaderSubtitleText}>{subtitle}</Typography>

      {actions && actions.length > 0 && (
        <div className={classes.buttonsContent}>
          {actions.map((action) => (
            <Button
              key={uuid.v4()}
              sx={
                // eslint-disable-next-line no-nested-ternary
                action?.variant === 'buttonLeft'
                  ? {
                      marginRight: '0!important',
                      borderRadius: '10px 0 0 10px!important',
                    }
                  : action?.variant === 'buttonRight'
                  ? {
                      borderRadius: '0px 10px 10px 0px!important',
                    }
                  : action?.sx
              }
              variant="contained"
              onClick={action.onClick}
              color={action?.color ? action?.color : 'secondary'}
              className={classes.button}
            >
              {action.icon && (
                <div className={classes.icon}>
                  <action.icon size={16} />
                </div>
              )}{' '}
              {action.text}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

SecondaryHeader.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.instanceOf(Array),
};

SecondaryHeader.defaultProps = {
  children: null,
  subtitle: '',
  actions: [],
};
