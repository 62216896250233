import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  panelLayout: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(2),
  },
  panelLayoutV2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '20px 0',
  },
  activeSort: {
    color: '#7f7f7f',
  },
  nothingToShow: {
    maxHeight: 200,
    borderRadius: '12px',
    backgroundColor: '#303030',
    border: '2px solid #404040',
    width: '75%',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
