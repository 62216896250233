import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Avatar } from 'components/Avatar';
import { Card } from 'components/Card/Card';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { PermGuard } from 'components/Guards/PermGuard';
import { CirclePlusIcon, DoubleCheckIcon, InfoIcon, PlusIcon, UploadIcon, UserIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import OrganizationService from 'services/OrganizationService';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/UsersSplash.png';
import { Permissions } from 'lib/permissions';
import { PermissionsValidator } from 'lib/permValidator';
import libPhone from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import Papa from 'papaparse';
import Dropzone from 'react-dropzone';
import UserService from 'services/UserService';
import { deleteUser, getUser, getUsers, setPageFilter, setSearch } from 'slices/userReducer';
import { ModalNewUser } from 'smartComponents/ModalNewUser';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { getExternalUsers, setExternalPageFilter } from 'slices/externalUserReducer';
import { DeleteUuid } from 'components/AddUuid/DeleteUuid/DeleteUuid';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { externalActionType, externalDataType } from 'lib/external-data';
import { DialogProjectInvite, typeInviteData } from 'components/DialogProjectInvite';
import { DialogTeamInviteUser } from 'components/DialogTeamInviteUser';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { externalUsersOptions, userOptions } from 'lib/topBarOptions';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import AccountService from 'services/AccountService';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useGlobalStyles } from 'styles';
import { getInitials } from 'lib/generalFunctions';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';
import { AdminPanel } from './AdminPanel';
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';
import { UploadAreaContainer, UploadFilesOuterContainer, UploadFilesInnerContainer } from './styles';
import moment from 'moment';
import { Permissions as PermsSlide } from 'pages/Admin/Admins/Permissions/Permissions';
import { createPortal } from 'react-dom';

const UserInfo = ({ firstName, lastName }) => {
  const classes = useGlobalStyles();

  return (
    <div className={classes.nameContainer}>
      <Avatar image="" size={30} initials={getInitials(`${firstName} ${lastName}`)} /> {` ${firstName} ${lastName}`}
    </div>
  );
};

UserInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

UserInfo.defaultProps = {
  firstName: '',
  lastName: '',
};

const tableColumns = (external) => [
  {
    Header: 'Name',
    accessor: (rowData) => {
      const { firstName, lastName, user } = rowData;
      return (
        <UserInfo firstName={external ? user.firstName : firstName} lastName={external ? user.lastName : lastName} />
      );
    },
  },
  {
    Header: 'Email',
    accessor: (rowData) => {
      const { user, email } = rowData;
      return external ? user.email : email;
    },
  },
  {
    Header: 'Last Login',
    id: 'createdAt',
    accessor: (rowData) => {
      const { lastLogin } = rowData;
      return lastLogin ? moment(lastLogin).format('MM/DD/YY') : '';
    },
  },
  {
    Header: 'Phone',
    accessor: (rowData) => {
      const { phone, user } = rowData;
      if (external) return user.phone && libPhone(user.phone, 'US').formatNational();
      return phone && libPhone(phone, 'US').formatNational();
    },
  },
  {
    Header: external ? 'Lending Account' : 'Organization',
    accessor: (rowData) => {
      if (external) {
        return rowData.user?.accounts?.length && rowData.user?.accounts[0].name;
      }
      return rowData.organizations?.length && rowData.organizations[0].name;
    },
  },
];

let debounceTimeout = null;

export const Users = ({ external }) => {
  const { loading, data, filters, userCount } = useSelector((state) => state[external ? 'externalUsers' : 'users']);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [selectUserId, setSelectUserId] = useState('');
  const [userName, setUseName] = useState('');
  const [openSendInvitation, setOpenSendInvitation] = useState(false);
  const [openNoSeatsAvailable, setOpenNoSeatsAvailable] = useState(false);
  const [loadInvitation, setLoadInvitation] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [orgSelected, setOrgSelected] = useState('');
  const [file, setFile] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [msgSuccess, setMsgSuccess] = useState('');
  const [openUuid, setOpenUuid] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [accountOrg, setAccountOrg] = useState('');
  const dispatch = useDispatch();

  const columns = React.useMemo(() => tableColumns(external), []);
  const search = useSelector((state) => state[external ? 'externalUsers' : 'users'].filters.search || '');
  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(userCount / filters.page_size),
      totalCount: userCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const onFileCancel = () => {
    setShowModal(false);
  };

  const getAdminsDetails = () => {
    AccountService.getAccount().then((res) => {
      setAccountOrg(res.data.data);
    });
  };

  const onFileSave = () => {
    Papa.parse(file[0], {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        results.data.map(async (row) => {
          const dataII = {
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            message: row.message,
          };
          try {
            const userResponse = await UserService.createUser(dataII);
            await OrganizationService.addUser(orgSelected.value, {
              userId: userResponse.data.data.id,
              roles: [9],
            });
            setOpenSuccess(true);
            setMsgSuccess(`Successfull !`);
          } catch (err) {
            const result = err?.response?.data?.message;
            setMsgError(result);
            setOpenError(true);
            // eslint-disable-next-line
            console.error('error', JSON.stringify(err));
          }
          return true;
        });
      },
    });
  };

  const onBulkUsersUpload = () => {
    setShowModal(true);
  };

  const responseParser = (organizations) =>
    organizations.map((org) => ({
      value: org.id,
      label: org.name,
    }));

  const fetchOrg = () => {
    OrganizationService.getOrganizations({ page_size: 500 }).then((res) => setOrgs(responseParser(res.data.data)));
  };

  useEffect(() => {
    getAdminsDetails();
    dispatch(setSearch());
    if (!external) {
      fetchOrg();
    }
  }, []);

  const gotSomeFiles = async (acceptedFiles) => {
    setFile(acceptedFiles);
  };

  const fetchData = (filterData) => {
    const sendFilters = {
      ...filterData,
      sort_by: Object.values(filterData.sort_by),
    };
    dispatch(
      external
        ? getExternalUsers({ ...sendFilters, include: ['orgs'] })
        : getUsers({ ...sendFilters, include: ['orgs', 'orgValidation'] })
    );
  };

  // delete user
  const handleDeleteMember = async () => {
    await dispatch(deleteUser({ userId: selectUserId }));
    fetchData(filters);
    setOpenDelete(false);
  };

  const handleCancelMember = () => {
    setOpenDelete(false);
  };
  // -------

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      fetchData(filters);
    }, 150);
  }, [filters]);

  useEffect(() => {
    dispatch(external ? setExternalPageFilter(pageIndex + 1) : setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
        external,
      },
      header: [],
      actions: [
        {
          icon: PlusIcon,
          onClick: () => {
            if (external) {
              setOpenUuid({ type: externalActionType.ADD });
            } else {
              setOpenAddUser(true);
            }
          },
        },
      ],
    },
  });

  const adminSectionPerms = [
    Permissions.ACCOUNT_USER_GRANT,
    Permissions.ACCOUNT_USER_MODIFY,
    Permissions.ACCOUNT_USER_REMOVE,
    Permissions.ACCOUNT_USER_VIEW,
    Permissions.ACCOUNT_USER_ADD,
  ];

  const isTeamAdmin = PermissionsValidator.validate({ scopes: adminSectionPerms });

  const handleRowClick = (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    dispatch(getUser(!external ? rowData.original.id : rowData.original.user.id));
    if (isTeamAdmin) {
      setHeaderPanels([
        {
          name: 'info',
          component: InfoPanel,
        },
        {
          name: 'admin',
          component: AdminPanel,
        },
      ]);
    } else {
      setHeaderPanels([
        {
          name: 'info',
          component: InfoPanel,
        },
      ]);
    }

    displayPanel('info');
  };

  // Manage the Open Add a New user Modal
  const handleAddUser = async () => {
    setOpenAddUser(true);
  };

  const handleCancelAddUser = () => {
    setOpenAddUser(false);
  };

  const EmptyUsersContainer = () => (
    <>
      <WidgetSplash
        alt="Users Splash"
        title="You have no user yet."
        image={SplashImage}
        cta="Add user now."
        onClick={() => (external ? setOpenUuid({ type: externalActionType.ADD }) : handleAddUser())}
      />
      {openAddUser && <ModalNewUser isOpen={openAddUser} onConfirm={handleAddUser} onCancel={handleCancelAddUser} />}
    </>
  );

  const handleOpenEditUser = (id) => {
    setSelectUserId(id);
    setOpenEditUser(true);
  };

  const handleSendInvitation = () => {
    setLoadInvitation(true);
    UserService.resendInvitation(selectUserId).then(() => {
      setOpenSendInvitation(false);
      setLoadInvitation(false);
    });
  };

  const handleCancelSendInvitation = () => {
    setOpenSendInvitation(false);
  };

  // ------------------------------- UUID functions
  const handleCloseUuid = (flag) => {
    setOpenUuid(false);
    if (flag) fetchData(filters);
  };

  const UserActions = ({ row }) => (
    <PermGuard scopes={adminSectionPerms}>
      <ContextMenu>
        <ContextMenuButton />
        <ContextMenuList position="bottom-right">
          {!external && (
            <ContextMenuItem onClick={() => setOpenUuid({ id: row.original.id, type: externalActionType.COPY })}>
              Copy UUID
            </ContextMenuItem>
          )}
          <ContextMenuItem
            onClick={() => {
              if (external) {
                setOpenUuid({ ...row.original.user, type: externalDataType.PROJ });
              } else {
                handleOpenEditUser(row.original.id);
              }
            }}
          >
            {external ? 'Add to Team' : 'Edit User Info & Participation'}
          </ContextMenuItem>
          <WidgetDivider />
          <ContextMenuItem
            onClick={() => {
              if (external) {
                setOpenUuid({ ...row.original.user, type: externalActionType.REMOVE });
                setUseName(`${row.original.user.firstName} ${row.original.user.lastName}`);
              } else {
                setOpenSendInvitation(true);
                setSelectUserId(row.original.id);
                setUseName(`${row.original.firstName} ${row.original.lastName}`);
              }
            }}
          >
            {external ? 'Remove from Account' : 'Resend Invitation'}
          </ContextMenuItem>
          {!external && (
            <ContextMenuItem
              onClick={() => {
                setSelectUserId(row.original.id);
                setOpenDelete(true);
                setUseName(`${row.original.firstName} ${row.original.lastName}`);
              }}
            >
              Remove User
            </ContextMenuItem>
          )}
        </ContextMenuList>
      </ContextMenu>
    </PermGuard>
  );

  UserActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  const UsersContainer = () => (
    <>
      {showModal && (
        <Dialog open onClose={onFileCancel}>
          <DialogTitle>
            <Typography variant="h4">Import Users</Typography>
            <Typography variant="h5">Upload and attach files to this project</Typography>
          </DialogTitle>
          <DialogContent>
            <Button
              color="secondary"
              variant="outlined"
              href="https://storage.googleapis.com/cvdev-assets/sample_users.csv"
              download
            >
              Get Template
            </Button>
            <UploadFilesOuterContainer style={{ padding: `10px` }}>
              <FormControl fullWidth>
                <Autocomplete
                  label="Add to an Organization"
                  name="organization"
                  options={orgs}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Add to an Organization" placeholder="Select One" required />
                  )}
                  value={orgs.find((u) => u.value === orgSelected)}
                  onChange={(e, value) => {
                    setOrgSelected(value?.value || '');
                  }}
                />
              </FormControl>
            </UploadFilesOuterContainer>
            <UploadFilesOuterContainer>
              <Dropzone
                onDrop={gotSomeFiles}
                acceptedFiles=".csv,.xls,.xlsx"
                style={{ padding: 50, backgroundColor: 'orange' }}
              >
                {({ getRootProps, getInputProps }) => (
                  <UploadFilesInnerContainer>
                    <div {...getRootProps()}>
                      <UploadIcon size={46} className="UploadIcon" />
                      <input {...getInputProps()} />
                      <p>Drag and drop some files here, or click to select files</p>
                    </div>
                    <UploadAreaContainer style={{ width: file.length > 0 ? '80%' : '1%' }}>
                      <p style={{ color: 'orange', display: 'inline-block' }}> </p>
                    </UploadAreaContainer>{' '}
                    <p style={{ color: 'white', display: 'inline-block', position: 'relative', top: '30%' }}>
                      {file.length > 0 ? '100%' : '0%'}
                    </p>
                    <div style={{ position: 'relative' }}>
                      {file.map((i) => (
                        <div>
                          <b>File:</b> {i.name} (size: {i.size})
                        </div>
                      ))}
                    </div>
                  </UploadFilesInnerContainer>
                )}
              </Dropzone>
            </UploadFilesOuterContainer>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button color="primary" variant="contained" onClick={onFileCancel} size="large" fullWidth>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button color="secondary" variant="contained" onClick={onFileSave} size="large" fullWidth>
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
          {openError && (
            <DialogMessage
              title={msgError}
              isOpen={openError}
              icon={DoubleCheckIcon}
              confirmText="Ok"
              onConfirm={() => {
                setOpenError(false);
                setShowModal(false);
              }}
            />
          )}
          {openSuccess && (
            <DialogMessage
              title={msgSuccess}
              isOpen={openSuccess}
              icon={InfoIcon}
              confirmText="Ok"
              onConfirm={() => {
                setOpenSuccess(false);
                setShowModal(false);
              }}
            />
          )}
        </Dialog>
      )}
      <>
        <GeneralTable
          table={table}
          selectedRow={selectedRow}
          handleRowClick={handleRowClick}
          rowComponent={UserActions}
        />
        <TablePagination table={table} data={filters} />
        {openAddUser && <ModalNewUser isOpen={openAddUser} onConfirm={handleAddUser} onCancel={handleCancelAddUser} />}
        {openEditUser &&
          createPortal(
            <PermsSlide
              handleClose={(val) => {
                setOpenEditUser(false);
                if (val) fetchData(filters);
              }}
              userId={selectUserId}
            />,
            document.getElementById('layout-area')
          )}
        <DialogMessage
          title="User Invitation"
          content={`Are you sure you want resent invitation to this ${userName}?`}
          icon={UserIcon}
          isOpen={openSendInvitation}
          onConfirm={handleSendInvitation}
          onCancel={handleCancelSendInvitation}
          activeBtn={loadInvitation}
        />
        <DialogMessage
          title="No Seats are Available"
          content="There are no more licenses available. Please contact your Account Billing Coordinator to purchase more seats or remove existing users."
          icon={UserIcon}
          isOpen={openNoSeatsAvailable}
          onConfirm={() => setOpenNoSeatsAvailable(false)}
          onCancel={() => setOpenNoSeatsAvailable(false)}
          activeBtn={loadInvitation}
        />
        <DialogMessage
          title={`Delete ${userName}`}
          content={`Are you sure you want to delete this ${userName}? This action cannot be undone`}
          icon={DoubleCheckIcon}
          isOpen={openDelete}
          onConfirm={handleDeleteMember}
          onCancel={handleCancelMember}
          action={dialogMessageAction.delete}
        />
        {openUuid?.id && openUuid?.type === externalActionType.REMOVE && (
          <DeleteUuid externalData={openUuid} type={externalDataType.USER} handleClose={handleCloseUuid} />
        )}
        {openUuid?.id && openUuid?.type === externalDataType.TEAM && (
          <DialogTeamInviteUser isOpen={openUuid?.id} onCancel={handleCloseUuid} title="Team" userId={openUuid?.id} />
        )}
        {openUuid?.id && openUuid?.type === externalDataType.PROJ && (
          <>
            <DialogProjectInvite
              isOpen={openUuid?.id}
              handleClose={handleCloseUuid}
              objectId={openUuid.id}
              itemName={`${openUuid.firstName} ${openUuid.lastName}`}
              type={typeInviteData.PROJET}
              textDescription="User"
            />
          </>
        )}
      </>
      {openUuid?.id && openUuid?.type === externalActionType.COPY && (
        <CopyUuidAlert id={openUuid.id} handleClose={() => setOpenUuid(false)} />
      )}
    </>
  );

  if (loading && data.length === 0) {
    return (
      <AccountSettingsContainer
        title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL USERS' : 'USERS'} />}
        actions={[
          {
            icon: external ? CirclePlusIcon : UserPlusIcon,
            text: external ? 'Add Via UUID' : 'Add New',
            onClick: () => {
              if (external) {
                setOpenUuid({ type: externalActionType.ADD });
              } else if (accountOrg?.seatLimit >= data.length) {
                setOpenAddUser(true);
              } else {
                setOpenNoSeatsAvailable(true);
              }
            },
          },
          {
            icon: UserPlusIcon,
            text: 'Import Users',
            onClick: () => onBulkUsersUpload(),
          },
        ]}
        showSidebar
      >
        <Loader loading={loading} height={250} />
        {openUuid?.type === externalActionType.ADD && (
          <AddUuid handleClose={handleCloseUuid} type={externalDataType.USER} />
        )}
      </AccountSettingsContainer>
    );
  }

  return (
    <AccountSettingsContainer
      title={<AdminHeaderTitle buttonName="ADMINISTRATION" section={external ? 'EXTERNAL USERS' : 'USERS'} />}
      actions={[
        {
          icon: external ? CirclePlusIcon : UserPlusIcon,
          text: external ? 'Add Via UUID' : 'Add New',
          onClick: () => {
            if (external) {
              setOpenUuid({ type: externalActionType.ADD });
            } else {
              setOpenAddUser(true);
            }
          },
        },
        {
          icon: UserPlusIcon,
          text: 'Import Users',
          onClick: () => onBulkUsersUpload(),
        },
      ]}
      showSidebar
    >
      <Card noPadding>
        <TopBar
          options={external ? externalUsersOptions : userOptions}
          searchValue={search}
          handleSearch={handleSearch}
          activatedFilter={filters.activated}
        />
        {data.length > 0 ? <UsersContainer /> : <EmptyUsersContainer />}
      </Card>
      {openUuid?.type === externalActionType.ADD && (
        <AddUuid handleClose={handleCloseUuid} type={externalDataType.USER} />
      )}
    </AccountSettingsContainer>
  );
};

Users.propTypes = {
  external: PropTypes.bool,
};

Users.defaultProps = {
  external: false,
};
