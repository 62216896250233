import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import detailMapInfo from 'images/detailMapInfo.png';
import { useGlobalStyles } from 'styles';
import { SidebarPanel } from 'components/SidebarPanel';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components/TabPanel';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import { InfoPanelBodyHeader } from 'components/InfoPanelBodyHeader/InfoPanelBodyHeader';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { getInitials } from 'lib/generalFunctions';
import { Avatar } from 'components/Avatar';
import { Link } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
export const InfoPanel = () => {
  const globalClasses = useGlobalStyles();
  const [value, setValue] = useState(0);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const { config } = useContext(SidebarContext);
  const component = config.header.find((item) => item.name === 'info');
  const {
    data: { inspection },
    actions,
  } = component;

  const onMount = () => {
    setLoading(true);
    actions.getProjects().then((res) => {
      setProjects(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    onMount();
  }, [inspection]);

  const projectLinkStyles = { marginLeft: 'auto', color: 'white', textDecoration: 'none' };

  return (
    <SidebarPanel title="Inspection Template" loading={loading}>
      <InfoPanelBodyHeader
        image={detailMapInfo}
        name={resolveUserInitials({
          firstName: inspection.name,
        })}
      />
      <div className={globalClasses.infoPanelBody}>
        <Typography mb={1} variant="h1" className={globalClasses.infoPanelName}>
          {inspection?.name}
        </Typography>
        <Typography mb={0.5} variant="h5">
          Created by:
          {`${inspection?.uploader?.firstName} ${inspection?.uploader?.lastName}`}
        </Typography>
        <Typography mb={0.5} variant="h5">
          Created: {moment(inspection?.createdAt).format('MM/DD/YY')}
        </Typography>
        <Typography mb={0.5}> Projects Assigned to: {projects.length}</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => actions.exportFindings()}
          size="medium"
          sx={{ marginTop: '1rem', marginRight: '1.5rem' }}
          className={globalClasses.openDetail}
        >
          Export All Findings
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={actions.assignToProjects}
          size="medium"
          sx={{ marginTop: '1rem' }}
          className={globalClasses.openDetail}
        >
          Assign to Project
        </Button>
        <Typography mt={1} className={globalClasses.infoPanelAboutText}>
          Description
        </Typography>
        <Typography mb={1} variant="body2" className={globalClasses.infoPanelDescription}>
          {inspection?.description}
        </Typography>
        <Tabs value={value} className={globalClasses.infoPanelTabs} sx={{ width: '100%' }}>
          <Tab label="Projects" onClick={() => setValue(0)} sx={{ width: '100%' }} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={globalClasses.infoTabPanel}>
            {projects.map((project) => (
              <Link
                to={`/projects?projectId=${project?.id}&inspectionId=${inspection?.id}`}
                target="_blank"
                style={projectLinkStyles}
              >
                <div
                  key={project?.id}
                  className={globalClasses.nameContainer}
                  style={{ margin: '10px', cursor: 'pointer' }}
                  aria-hidden="true"
                >
                  <Avatar key={project?.id} initials={getInitials(project?.name)} size={48} />
                  <div>
                    <Typography>{project.name}</Typography>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </TabPanel>
      </div>
    </SidebarPanel>
  );
};
