import React from 'react';
import { Button } from '@mui/material';
import { useStyles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export const CollapseButton = ({ active, children, ...rest }) => {
  const classes = useStyles({ active });
  return (
    <Button sx={{ width: '100%' }} className={classes.btn} {...rest}>
      {children}
      {active ? (
        <FontAwesomeIcon size="xs" style={{ position: 'absolute', right: '12px' }} icon={faChevronUp} />
      ) : (
        <FontAwesomeIcon size="xs" style={{ position: 'absolute', right: '12px' }} icon={faChevronDown} />
      )}
    </Button>
  );
};
