import React, { useState } from 'react';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components/TabPanel';
import { useStyles as useGlobalStyles } from 'components/LayoutPageContent/LayoutPageContent';
import { useStyles } from './styles';

export const FaqPage = () => {
  const globalClasses = useGlobalStyles({ faqPage: true });
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const Icon = () => <FontAwesomeIcon icon={faChevronCircleDown} size="lg" />;

  return (
    <div className={globalClasses.content}>
      <div className={classes.faqContent}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value}>
            <Tab label="ALL USERS" onClick={() => setValue(0)} />
            <Tab label="ADMIN USERS" onClick={() => setValue(1)} />
          </Tabs>
        </Box>

        {/* --------------
          Tab 1
      ---------------- */}
        <TabPanel value={value} index={0}>
          {/* ----- Card 1 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel1a-header" aria-controls="panel1a-content">
              <Typography>Documents and Support Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Please{' '}
                <a href="pdf/CartoVid_v6_Org_Participant_2023_Official.pdf" target="_blank" rel="noreferrer">
                  click here{' '}
                </a>
                to download a printable version of our how-to guide for CartoVid Users.
              </p>
              <p>
                If you&#39;re unable to find the answer to your question here or you&#39;re looking for information
                outside the scope of what has been covered, please email us at{' '}
                <a href="mailto:support@cartovid.com">support@cartovid.com </a>
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ----- Card 2 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel2a-header" aria-controls="panel2a-content">
              <Typography>Change Your Profile or Password</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/736261101?h=fc8e21f373&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="All - Change Profile or Password"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To change your profile:
                <br />
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    From your personal Dashboard click the <span className={classes.boldFont}>pencil</span> icon to the
                    right of your name.
                  </li>
                  <li>
                    Fill out the desired fields and choose the <span className={classes.boldFont}>Save</span> button.{' '}
                  </li>
                </ol>
              </p>
              <br />
              <p>
                To change your password:
                <br />
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    From your personal Dashboard click the submenu <span className={classes.boldFont}>Security</span> on
                    the left side of your screen.
                  </li>
                  <li>
                    Fill out the required fields and choose the{' '}
                    <span className={classes.boldFont}>Update Settings</span> button.{' '}
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 3 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel3a-header" aria-controls="panel3a-content">
              <Typography>Create a Project</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754758?h=3c530e38bb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="All - Create a project"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To create a project:
                <br />
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Click <span className={classes.boldFont}>Projects</span> button on the main menu.{' '}
                  </li>
                  <li>
                    On the <span className={classes.boldFont}>All Projects</span> page click the{' '}
                    <span className={classes.boldFont}>+</span> icon at the top or bottom right.{' '}
                  </li>
                  <li>
                    In the <span className={classes.boldFont}>Create New Project</span> window go to{' '}
                    <span className={classes.boldFont}>Select Organization</span> and choose your Org from the
                    drop-down.{' '}
                  </li>
                  <li>Name the new project and provide a description. </li>
                  <li>Select the project&#39;s Visibility (i.e., Hide or Open)</li>
                  <li>
                    Click the <span className={classes.boldFont}>Save</span> button.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 4 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel4a-header" aria-controls="panel4a-content">
              <Typography>Add/Remove Users &amp; Teams from a Project</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713755105?h=dfb744616d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="PA - Projects - Add or Remove Users and Teams"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                Who can make these changes to a project? The project&#39;s creator, Project Participant Coordinator, Org
                Administrator, Org Participant Coordinator and Account Administrator
              </p>
              <br />
              <p>
                To add users or teams to a project:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Click on the <span className={classes.boldFont}>Shield</span> icon at the bottom of the the main
                    menu.{' '}
                  </li>
                  <li>
                    Select the <span className={classes.boldFont}>Projects</span> tab in the submenu.
                  </li>
                  <li>
                    Click the Edit <span className={classes.boldFont}>[…]</span> button for project wish to add a user
                    or team to.{' '}
                  </li>
                  <li>A window will open allowing you to choose the Team or User to the project. </li>
                  <li>
                    Click <span className={classes.boldFont}>Confirm</span>.{' '}
                  </li>
                </ol>
              </p>
              <p>
                To adjust roles or remove users/teams from a project:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Click on the <span className={classes.boldFont}>Shield</span> icon at the bottom of the the main
                    menu.{' '}
                  </li>
                  <li>
                    Select the <span className={classes.boldFont}>Projects</span> tab in the submenu.
                  </li>
                  <li>
                    From the <span className={classes.boldFont}>Projects</span> page select the project you wish to make
                    changes to.{' '}
                  </li>
                  <li>
                    In the Information Panel select the <span className={classes.boldFont}>Shield</span> icon at the top
                    right.{' '}
                  </li>
                  <li>
                    Select the <span className={classes.boldFont}>Participant Coordination</span> option.
                  </li>
                  <li>
                    At the top right of the page you can select the option to edit{' '}
                    <span className={classes.boldFont}>Individuals or Teams</span>. It defaults to Individuals.
                  </li>
                  <li>
                    Click the Edit Project Role <span className={classes.boldFont}>[…]</span> button for the Individual
                    or Team you wish to edit.{' '}
                  </li>
                  <li>
                    From the <span className={classes.boldFont}>Edit Roles</span> window, add or remove permissions.
                  </li>
                  <li>Saving is not required.</li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 5 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel5a-header" aria-controls="panel5a-content">
              <Typography>Uploading Content</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754824?h=744bb7e5e8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="All - Upload Content"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              To add content to a project:
              <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                <li>
                  Go to the <span className={classes.boldFont}>All Projects</span> page on the main menu.{' '}
                </li>
                <li>
                  Select the <span className={classes.boldFont}>Project</span> that you&#39;d like to add content to.
                </li>
                <li>
                  In the <span className={classes.boldFont}>Information Panel</span> select the{' '}
                  <span className={classes.boldFont}>View this Project Page</span> button.{' '}
                </li>
                <li>
                  Once in the project, select the <span className={classes.boldFont}>&#39;+&#39;</span> icon at the top
                  or bottom right of the page.{' '}
                </li>
                <li>
                  From the <span className={classes.boldFont}>Create Content</span> page select the upload type from the
                  dropdown menu under the &#34;What would you like to Upload?&#34; section in the upper left corner of
                  the page. (Select from Series, Related Grouping, or Individual/General Bulk)
                  <br />
                  <p className="addPad">
                    a. <span className={classes.boldFont}>Series</span>: Use this option when your camera segments a
                    single video into multiple segments due to file size limitations. <br />
                    b. <span className={classes.boldFont}>Related Grouping</span>: To save time, this can be used when
                    you have videos and images that are directly related and can share the same name, description, and
                    date. Files are renamed and numbered sequentially.
                    <br />
                    c. <span className={classes.boldFont}>Individual/General Bulk</span>: Use this option when you have
                    a single file or would like to upload several items at once.
                  </p>
                </li>
                <li>
                  After selecting the upload type either drag the item(s) into the upload area or navigate to the
                  item(s) using your browser. If applicable, be sure the{' '}
                  <span className={classes.boldFont}>correct camera type</span> is selected from the drop down.
                  Supported Camera Types:
                  <p className="addPad">
                    {' '}
                    a. <span className={classes.boldFont}>Anafi:</span> This option should only be used for Parrot Anafi
                    drone videos. This will not support any other Parrot drones as the rate of telemetry capture
                    differs. <br />
                    b. <span className={classes.boldFont}>Mapir</span>: This option should be used with Mapir Survey 3
                    cameras only. Will not work with Kernel or any other Mapir cameras.
                    <br />
                    c. <span className={classes.boldFont}>GoPro Hero</span>: This should be the default script for GoPro
                    Hero 8 and Hero 9 cameras. This should be the default option for all the GoPro videos.
                    <br />
                    d. <span className={classes.boldFont}>DJI with SRT</span>: This should be used only for DJI drone
                    (Matrice 100, 200, 300; Phantom 4 Pro; Mavic; Mavic Mini) videos with an external .SRT file. Any
                    other kind of .SRT file will give the user an error when trying to upload.
                    <br />
                    e. <span className={classes.boldFont}>Vid with CSV</span> (header: lat, long, time): This option
                    should only be selected for CSV files with the column headers latitude, longitude, and time.
                    <br />
                    f. <span className={classes.boldFont}>Vid with GPX</span>: This option should be used for .mp4 files
                    with associated .gpx file containing the videos telemetry.
                  </p>
                </li>
                <li>
                  Choose <span className={classes.boldFont}>Upload</span>.{' '}
                </li>
                <li>
                  Once the Upload button is selected, you will see a pop up in the bottom right corner indicating the
                  status of the items you&apos;ve selected. This pop-up shows the status of your content landing in the
                  cue. Please do not close this window until your items are 100% in the cue.
                </li>
                <li>
                  While your content is being processed, you will{' '}
                  <span className={classes.boldFont}>&apos;Content Processing&apos;</span> on the placeholder and will
                  be unable to interact with the content.
                </li>
                <li>Finally, please be patient as the transcoding process requires time.</li>
              </ol>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 6 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel6a-header" aria-controls="panel6a-content">
              <Typography>Copy or Share Content to a Project</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/718019475?h=8d3cd21961&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="All - Copying or Sharing"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              To copy or share content to a project:
              <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                <li>
                  Go to the <span className={classes.boldFont}>All Projects</span> page on the main menu.{' '}
                </li>
                <li>
                  Select the <span className={classes.boldFont}>Project</span> containing the content that you&apos;d
                  like to copy or share to another project.
                </li>
                <li>
                  In the <span className={classes.boldFont}>Information Panel</span> select the{' '}
                  <span className={classes.boldFont}>View this Project Page</span> button.{' '}
                </li>
                <li>
                  Once in the project, go to the card representing the file you would like to copy or share and select
                  the <span className={classes.boldFont}>&apos;more&apos;</span> icon to the right of the file&apos;s
                  title. (Look for three vertical dots.){' '}
                </li>
                <li>
                  From the dropdown menu, choose to <span className={classes.boldFont}>Share</span> or{' '}
                  <span className={classes.boldFont}>Copy</span> depending on your needs and/or permissions.
                  <br />
                  <p className="addPad">
                    <span className={classes.boldFont}>Sharing</span>: Use this option when you would like for the file
                    to appear within a project but do not need an independent copy of the file. The advantage of this
                    method is cost savings due to storage of files. The disadvantage of this method is that, should the
                    original file be removed from the system, you may lose access to it. <br />
                    <span className={classes.boldFont}>Copying</span>: Use this option when you would like a copy of the
                    file saved to your project that is independent of the original. Note that using this method will
                    require more storage over time.
                    <br />
                  </p>
                </li>
              </ol>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel7a-header" aria-controls="panel7a-content">
              <Typography>Create, Email, and Export Observations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/772104576?h=6d68cbcd95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="All - Observations"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              To create an observation point:
              <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                <li>
                  Click on the pencil icon in the bottom left corner of the Content you want to make an observation on.
                </li>
                <li>Create an observation by adding a name, description, and dropping a pin.</li>
              </ol>
            </AccordionDetails>
          </Accordion>
        </TabPanel>

        {/* --------------
          Tab 2
      ---------------- */}
        <TabPanel value={value} index={1}>
          {/* ----- Card 1 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel1b-header" aria-controls="panel1b-content">
              <Typography>Documents and Support Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              Please{' '}
              <a
                className="card-link"
                href="pdf/CartoVid_v6_Account_Admin_2023_Official.pdf"
                target="_blank"
                rel="noreferrer"
              >
                click here{' '}
              </a>
              to download a printable version of our how-to guide for CartoVid Users.
              <br />
              <br />
              <p>
                If you&apos;re unable to find the answer to your question here or you&apos;re looking for information
                outside the scope of what has been covered, please email us at{' '}
                <a href="mailto:support@cartovid.com">support@cartovid.com</a>{' '}
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 2 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel2b-header" aria-controls="panel2b-content">
              <Typography>Administration Dashboard Overview</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                To verify you are on the Administrative Dashboard and not your Personal Dashboard:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Click <span className={classes.boldFont}>Shield</span> icon button lower left corner.{' '}
                  </li>
                  <li>
                    Ensure <span className={classes.boldFont}>Administration</span> appears in page title.{' '}
                  </li>
                  <li>Submenu tabs allow you to see data and perform tasks.</li>
                  <li>Add a note if desired.</li>
                  <li>
                    <span className={classes.boldFont}>Quick Actions</span> section allows access to some key tasks.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 3 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel3b-header" aria-controls="panel3b-content">
              <Typography>Add Account Admins</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754010?h=809da6da65&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="AA - Add Account Admins"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To add Account Administrators:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Go to the <span className={classes.boldFont}>Administrators</span> tab from the Admin menu.{' '}
                  </li>
                  <li>
                    Click the <span className={classes.boldFont}>+</span> icon at the top or bottom right.{' '}
                  </li>
                  <li>
                    In the <span className={classes.boldFont}>Add New Administrator</span> window you can (a) check the
                    &apos;Add an existing user as Administrator&apos; box if the user is already in the system or (b)
                    fill out the required information to invite a new administrator into the tool.
                  </li>
                  <li>
                    Click the <span className={classes.boldFont}>Save</span> button.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 4 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel4b-header" aria-controls="panel4b-content">
              <Typography>Create an Organization</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754656?h=c8430d4378&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="AA - Create an Organization"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To create an organization:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Go to the <span className={classes.boldFont}>Organizations</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Click the <span className={classes.boldFont}>+</span> icon at the top or bottom right.{' '}
                  </li>
                  <li>
                    In the <span className={classes.boldFont}>Add New Organization</span> window fill out the required
                    information.
                  </li>
                  <li>
                    Click the <span className={classes.boldFont}>Save</span> button.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 5 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel5b-header" aria-controls="panel5b-content">
              <Typography>Edit an Organization</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Edit an Organization:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Go to the <span className={classes.boldFont}>Organizations</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Click the &apos;more&apos; Edit <span className={classes.boldFont}>[…]</span> menu button for the
                    Organization you wish to edit.{' '}
                  </li>
                  <li>
                    Here you have the ability to <span className={classes.boldFont}>Edit Details</span> ((description)
                    from the Edit Details Window). Click <span className={classes.boldFont}>Save</span>.
                  </li>
                  <li>
                    Manage Users and permissions (from the Manage Users Window). Click{' '}
                    <span className={classes.boldFont}>Okay</span>.
                  </li>
                  <li>
                    Or Delete Org and click <span className={classes.boldFont}>Confirm</span> from the Delete Org
                    Confirmation Window.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 6 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel6b-header" aria-controls="panel6b-content">
              <Typography>Add/Edit Organization Admins</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754143?h=fd4aa49254&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="AA - Add Org Admins"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To edit an organization:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Go to the <span className={classes.boldFont}>Organizations</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Click the &apos;more&apos; Edit <span className={classes.boldFont}>[…]</span> menu button for the
                    Organization you wish to edit.{' '}
                  </li>
                  <li>
                    Select <span className={classes.boldFont}>Manage Users</span>Click{' '}
                    <span className={classes.boldFont}>Save</span>.
                  </li>
                  <li>Use the search bar to find users if they are not in the current list.</li>
                  <li>
                    Select <span className={classes.boldFont}>Choose Role</span> to add permissions for users who are in
                    the current list.
                  </li>
                  <li>
                    User the circular <span className={classes.boldFont}>&apos;x&apos;</span> to remove a permission for
                    users who are in the current list.
                  </li>
                  <li>
                    Click <span className={classes.boldFont}>Okay</span>.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 7 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel7b-header" aria-controls="panel7b-content">
              <Typography>Add System Users</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754200?h=34bf685438&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="AA - Add Users"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To add users to the Account:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Navigate to the <span className={classes.boldFont}>Users</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Select the <span className={classes.boldFont}>&apos;+&apos;</span> button from the top or bottom
                    right.{' '}
                  </li>
                  <li>
                    Add the required information and press <span className={classes.boldFont}>Save</span>.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 8 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel8b-header" aria-controls="panel8b-content">
              <Typography>Edit or Delete Users</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754411?h=43e9b4c0a8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="AA - Edit or Delete Users"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To edit a user:
                <ol className="card-list">
                  <li>
                    Navigate to the <span className={classes.boldFont}>Users</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    For a simple edit (name, bio, phone number) click the Edit{' '}
                    <span className={classes.boldFont}>[…]</span> menu button for the user you wish to edit.{' '}
                  </li>
                  <li>
                    Change the information and press <span className={classes.boldFont}>Save</span>.
                  </li>
                </ol>
              </p>
              <p>
                To edit the user&apos;s memberships (more advanced editing):
                <ol className="card-list">
                  <li>
                    Navigate to the <span className={classes.boldFont}>Users</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Click on the user you would like to edit. In the information panel, click the{' '}
                    <span className={classes.boldFont}>pencil</span> icon at the top information panel.{' '}
                  </li>
                  <li>Change the membership permission levels. Saving is not required.</li>
                </ol>
              </p>
              <p>
                To delete a user:
                <ol className="card-list">
                  <li>
                    Navigate to the <span className={classes.boldFont}>Users</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Click on the user you would like to edit. Click the <span className={classes.boldFont}>Shield</span>{' '}
                    icon at the top of the Information Panel
                  </li>
                  <li>
                    Select <span className={classes.boldFont}>Remove User</span> and confirm.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 9 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel9b-header" aria-controls="panel9b-content">
              <Typography>Create a Team</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754251?h=e9241a0792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="AA - Create Team"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To create a Team::
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Navigate to the <span className={classes.boldFont}>Teams</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Select the <span className={classes.boldFont}>&apos;+&apos;</span> button from the top or bottom
                    right.{' '}
                  </li>
                  <li>
                    Add the required information and press <span className={classes.boldFont}>Save</span>.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
          {/* ----- Card 10 ----- */}
          <Accordion>
            <AccordionSummary expandIcon={<Icon />} id="panel9b-header" aria-controls="panel9b-content">
              <Typography>Add/Edit Team Members</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/713754548?h=09961f94c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="AA - Teams - Add and Edit Members"
                />
              </div>
              <script src="https://player.vimeo.com/api/player.js" />
              <br />
              <p>
                To add or edit team members:
                <ol style={{ marginTop: '10px', listStyleType: 'decimal', paddingLeft: '40px', lineHeight: '2.7rem' }}>
                  <li>
                    Navigate to the <span className={classes.boldFont}>Teams</span> page from the Admin menu.{' '}
                  </li>
                  <li>
                    Click on the Teams you would like to edit. Click the{' '}
                    <span className={classes.boldFont}>Shield</span> icon at the top of the Information Panel
                  </li>
                  <li>
                    Select <span className={classes.boldFont}>Manage Members</span>.
                  </li>
                  <li>In the window, search for users within the Org that you would like to add to the team.</li>
                  <li>
                    Assign the user&apos;s <span className={classes.boldFont}>team role(s)</span>.
                  </li>
                  <li>
                    Likewise, you can <span className={classes.boldFont}>remove</span> a user or change their role.
                  </li>
                  <li>
                    Click <span className={classes.boldFont}>Okay</span>.
                  </li>
                </ol>
              </p>
            </AccordionDetails>
          </Accordion>
        </TabPanel>
      </div>
    </div>
  );
};
