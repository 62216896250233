import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ViewRoleValidation } from 'components/ViewRoleValidation/ViewRoleValidation';
import { Permissions } from 'lib/permissions';
import { Button } from '@mui/material';
import { EmailIcon } from 'components/Icons';
import { ObservationEmail } from './ObservationEmail';

export const EmailButtonV2 = ({ observation, loading, map, color }) => {
  const [open, setOpen] = useState(false);
  const [screenshot, setScreenshot] = useState('');
  const [validateRole, setValidateRole] = useState({ open: false, callBack: () => {} });

  const openEmail = () => {
    map.takeScreenshot({}).then((image) => {
      setScreenshot(image.dataUrl);
      setOpen(true);
    });
  };

  return (
    <>
      <Button
        color={color}
        variant="outlined"
        size="small"
        onClick={() => {
          setValidateRole({ open: true, action: [Permissions.PROJ_OBSERVATION], callBack: openEmail });
        }}
        disabled={loading || !map}
      >
        <EmailIcon size={20} />
      </Button>
      {open && (
        <ObservationEmail
          loading={loading}
          handleClose={() => setOpen(false)}
          observation={observation}
          screenshot={screenshot}
        />
      )}
      {validateRole.open && (
        <ViewRoleValidation
          action={validateRole.action}
          data={{ id: observation?.projectId }}
          callBack={validateRole.callBack}
          handleClose={() => setValidateRole({ open: false, callBack: () => {} })}
        />
      )}
    </>
  );
};

EmailButtonV2.propTypes = {
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  observation: PropTypes.object,
  map: PropTypes.node,
  color: PropTypes.string,
};

EmailButtonV2.defaultProps = {
  loading: false,
  observation: { id: null },
  map: null,
  color: 'secondary',
};
