import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const TrashIcon2 = ({ size }) => (
  <Svg viewBox="0 0 21 20" size={size}>
    <path
      id="Icon"
      d="M13.8333 5.00033V4.33366C13.8333 3.40024 13.8333 2.93353 13.6517 2.57701C13.4919 2.2634 13.2369 2.00844 12.9233 1.84865C12.5668 1.66699 12.1001 1.66699 11.1667 1.66699H9.83333C8.89991 1.66699 8.4332 1.66699 8.07668 1.84865C7.76308 2.00844 7.50811 2.2634 7.34832 2.57701C7.16667 2.93353 7.16667 3.40024 7.16667 4.33366V5.00033M8.83333 9.58366V13.7503M12.1667 9.58366V13.7503M3 5.00033H18M16.3333 5.00033V14.3337C16.3333 15.7338 16.3333 16.4339 16.0608 16.9686C15.8212 17.439 15.4387 17.8215 14.9683 18.0612C14.4335 18.3337 13.7335 18.3337 12.3333 18.3337H8.66667C7.26654 18.3337 6.56647 18.3337 6.03169 18.0612C5.56129 17.8215 5.17883 17.439 4.93915 16.9686C4.66667 16.4339 4.66667 15.7338 4.66667 14.3337V5.00033"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

TrashIcon2.propTypes = {
  size: PropTypes.number,
};

TrashIcon2.defaultProps = {
  size: 24,
};
