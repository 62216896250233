/* eslint-disable no-nested-ternary */
import Graphic from '@arcgis/core/Graphic';
import { useHistory, Link } from 'react-router-dom';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import ArcGISMap from '@arcgis/core/Map';
import KMLLayer from '@arcgis/core/layers/KMLLayer';
import MapView from '@arcgis/core/views/MapView';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
import Search from '@arcgis/core/widgets/Search';
import { FollowButton } from 'components/FollowButton/FollowButton';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  projectAssetSection as projectAssetObjectSection,
  setSelectedSequence,
} from 'slices/profileProjectAssetReducer';
import {
  createMapGeoFeatureLayer,
  currentHeadingSymbol,
  currentSymbol,
  fetchMapGeoData,
  findingPoints,
  mainPointGraphics,
  observationPoints,
} from 'components/MapFeatureLayer/MapFeatureLayer';
import { ObservationPopup } from 'components/Observation/ObservationPopup';
import { TrackZoom } from 'components/TrackZoom/TrackZoom';
import { useStyles } from './styles';
import { Button } from '@mui/material';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import alertTriangleOrange from 'components/Observation/ObservationMap/alert-triangle-orange 1.png';
import alertTriangleRed from 'components/Observation/ObservationMap/alert-triangle-red 1.png';
import alertTriangleYellow from 'components/Observation/ObservationMap/alert-triangle-yellow 1.png';

export const Map = ({ points, videoRef, videoView, sequenceLength, geometryOrgId, includeAccountGeometry }) => {
  const ctx = useContext(SidebarDetailContext);
  const classes = useStyles({ sequenceLength });
  const {
    player: { selectedSequence },
    projectAsset: { findings },
    projectAssetSection,
  } = useSelector((state) => state.profileProjectAssets);
  const { observations, projectKmls, projectObservations } = useSelector((state) => state.assetObservations);
  const mapDiv = useRef(null);
  const [follow, setFollow] = useState(true);
  const [track, setTrack] = useState(true);
  const [locateIcon, setLocateIcon] = useState('esri-icon-locate');
  const [title, setTitle] = useState('Follow Map');
  const [observation, setObservation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const mapGeoLayer = useMemo(() => new GraphicsLayer({ id: 'mainLine' }), []);
  const observationLayer = useMemo(() => new GraphicsLayer({ id: 'observationPoints' }), []);
  const findingLayer = useMemo(() => new GraphicsLayer({ id: 'findingPoints' }), []);
  // eslint-disable-next-line
  let selectedPoint = history.location.state?.data;
  const lastPoint = points.length - 1;
  // create the layer for the spatial points and the associating markers
  const polylineGraphicLayer = useMemo(() => new GraphicsLayer({ id: 'mainPoints' }), []);
  const observationsMapPoints = useMemo(() => new GraphicsLayer({ id: 'mainObservationsPoints' }));
  const [viewMap, setViewMap] = useState(null);
  const [searchMap, setSearchMap] = useState(null);
  const [time, setTime] = useState(0);
  const zoom = 19;
  const [coords, setCoords] = useState({});

  // This function maps through the spatial points on the backend, and creates a collection of orange dot graphics.
  const pointGraphics = mainPointGraphics(points, videoView, selectedSequence, lastPoint);

  // This function toggles the css classes for the following button
  const desactiveFollow = () => {
    // toggle map following function
    // set icon
    setLocateIcon('esri-icon-locate');
    setTitle('Follow Map');
    setFollow(false);
  };

  const toggleIsFollowing = () => {
    // followButton.classList.remove('esri-icon-locate');
    if (follow) {
      desactiveFollow();
    } else {
      // toggle map following function
      // set icon
      setLocateIcon('esri-icon-close-circled');
      setTitle('Stop Following Map');
      setTrack(false);
      setFollow(true);
    }
  };

  // When the Map finishes loading, zoom to the video point path
  const defaultZoom = (view, featureLayerData) => {
    setTrack(true);
    // desactiveFollow();
    let target = {};
    const mainPoint = polylineGraphicLayer.graphics.items[0].geometry;
    setCoords({
      longitude: mainPoint.longitude,
      latitude: mainPoint.latitude,
    });
    if (points.length < 2) {
      target = { target: polylineGraphicLayer.graphics, zoom };
    } else {
      target = { target: polylineGraphicLayer.graphics };
    }
    view.when(
      view
        .goTo(target, { animate: true, easing: 'ease', duration: 2000 })
        .then(() =>
          // -------------------- call map geometry data
          fetchMapGeoData(featureLayerData)
        )
        .catch((error) => {
          if (error.name !== 'AbortError') {
            // eslint-disable-next-line
            console.error(error);
          }
        })
    );
  };

  // create kml
  const createKML = (projKmls) => {
    const kmls = [];
    if (projKmls && Array.from(projKmls).length) {
      projKmls.map((kml) => {
        kmls.push(
          new KMLLayer({
            id: kml.id,
            url: kml.url,
          })
        );
        return kml;
      });
    }
    return kmls;
  };

  // get the observation icon by classification
  const observationClassificationIcon = (classification) => {
    const classifications = {
      '1-Minor': alertTriangleYellow,
      '2-Major': alertTriangleOrange,
      '3-Critical': alertTriangleRed,
    };
    return {
      type: 'picture-marker',
      url: classifications[classification],
    };
  };

  // create observations points
  const createObservationsPoints = (observationsList) => {
    const observationsPoints = [];
    observationsList.map((observationItem) => {
      const mapPoint = {
        type: 'point',
        longitude: observationItem?.lng,
        latitude: observationItem?.lat,
      };
      observationsPoints.push(
        new Graphic({
          geometry: mapPoint,
          symbol: observationClassificationIcon(observationItem?.classification),
          attributes: observationItem,
        })
      );
      return observationItem;
    });
    return observationsPoints;
  };

  // ---------------------- track zoom function
  const handleTrackZoomClick = (desabledZoom = false) => {
    if (viewMap !== null) {
      // ------------------- get data to get lines and poles
      const featureLayerData = {
        view: viewMap,
        mapGeoFeatureLayer: null,
        mapGeoFeatureLayerLines: null,
        mapGeoLayer,
        searchWidget: searchMap,
        geometryOrgId,
        includeAccountGeometry,
      };
      viewMap.map.layers.forEach((layer) => {
        switch (layer.id) {
          case 'inital-points-1':
            featureLayerData.mapGeoFeatureLayer = layer;
            break;
          case 'inital-lines-1':
            featureLayerData.mapGeoFeatureLayerLines = layer;
            break;
          default:
            break;
        }
      });
      // -------------- default zoom
      if (!desabledZoom) defaultZoom(viewMap, featureLayerData);
    }
  };

  // ---------------------- zoom to point in click and select sequence
  const zoomToPoint = (listPoints, sequenceNumber, video) => {
    const point = listPoints.find((item) => item.attributes === sequenceNumber)?.geometry;
    if (point?.longitude && viewMap !== null && follow) {
      viewMap.when(
        viewMap
          .goTo(
            {
              center: [point.longitude, point.latitude],
              zoom: video ? viewMap.zoom : zoom,
            },
            { animate: true, easing: 'ease', duration: 2000 }
          )
          .then(() => ((video && !track) || !video) && setTrack(false))
          .catch((error) => {
            if (error.name !== 'AbortError') {
              // eslint-disable-next-line
              console.error(error);
            }
          })
      );
    }
  };

  // ------------------- update observations points
  useEffect(() => {
    observationLayer.removeAll();
    if (
      projectAssetSection === projectAssetObjectSection.OBSERVATIONS ||
      projectAssetSection !== projectAssetObjectSection.INSPECTIONS
    ) {
      observationLayer.addMany(observationPoints(observations));
    }
  }, [observations, projectAssetSection]);

  // ------------------- update finding points
  useEffect(() => {
    findingLayer.removeAll();
    if (
      projectAssetSection === projectAssetObjectSection.INSPECTIONS ||
      projectAssetSection !== projectAssetObjectSection.OBSERVATIONS
    ) {
      findingLayer.addMany(findingPoints(findings));
    }
  }, [findings, projectAssetSection]);

  // ------------------- update main points
  useEffect(() => {
    polylineGraphicLayer.removeAll();
    polylineGraphicLayer.addMany(pointGraphics);
    handleTrackZoomClick();
  }, [points]);

  // ------------------- update sequence in spatial points for image series
  useEffect(() => {
    polylineGraphicLayer.removeAll();
    polylineGraphicLayer.addMany(pointGraphics);
    zoomToPoint(pointGraphics, selectedSequence);
  }, [selectedSequence]);

  // ------------ update zoom in video points
  useEffect(() => {
    zoomToPoint(pointGraphics, time, true);
  }, [time]);

  // show observations points
  useEffect(() => {
    observationsMapPoints.removeAll();
    if (projectObservations?.length) {
      observationsMapPoints.addMany(createObservationsPoints(projectObservations));
    }
  }, [projectObservations]);

  // on page load
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mapDiv.current) {
      /**
       * Initialize application
       */
      const map = new ArcGISMap({
        basemap: 'hybrid',
        layers: [...createKML(projectKmls)],
      });

      // const home = new Home();

      const view = new MapView({
        map,
        container: mapDiv.current,
      });
      setViewMap(view);
      // create the map selector
      const basemapGallery = new BasemapGallery({
        view,
        container: document.createElement('div'),
      });

      const bgExpand = new Expand({
        view,
        content: basemapGallery.container,
        expandIconClass: 'esri-icon-basemap',
      });

      // ------------------------ add the feature layer to the map
      const mapGeoFeatureLayer = createMapGeoFeatureLayer('point', { id: 'inital-points-1' });
      const mapGeoFeatureLayerLines = createMapGeoFeatureLayer('polyline', { id: 'inital-lines-1' });
      map.add(mapGeoFeatureLayerLines);
      map.add(mapGeoLayer);
      map.add(mapGeoFeatureLayer, 0);
      map.add(observationsMapPoints);

      // create the layer for the obsrvation points
      map.add(observationLayer);
      // create the layer for the findings points
      map.add(findingLayer);

      const searchWidget = new Search({
        view,
      });

      view.ui.add(searchWidget, {
        position: 'top-right',
        index: 2,
      });
      setSearchMap(searchWidget);

      // ----------------------------------------------- zoom in point after searching
      searchWidget.goToOverride = (view2, goToParams) => {
        goToParams.options.duration = 300;
        const target = {
          ...goToParams.target,
          ...(goToParams.target.target?.attributes?.ObjectID &&
            goToParams.target.target?.layer?.uid === mapGeoFeatureLayer.uid && { zoom }),
        };
        return view.goTo(target, goToParams.options);
      };

      view.ui.add([bgExpand], 'top-left');

      const followButton = document.getElementById('follow');

      let innerFollow = false;

      const featureLayerData = {
        view,
        mapGeoFeatureLayer,
        mapGeoFeatureLayerLines,
        mapGeoLayer,
        searchWidget,
        geometryOrgId,
        includeAccountGeometry,
      };

      // When the Map finishes loading, zoom to the video point path
      defaultZoom(view, featureLayerData);
      // if (process.env.NODE_ENV !== 'production') {
      // } else {
      // defaultZoom(view, featureLayerData);
      // view.on('layerview-create', () => {
      //   if (view.layerViews.length === map.layers.length) {
      //   }
      // });
      // }

      view.popup.autoOpenEnabled = false;

      view.on('drag', (event) => {
        if (event.action === 'end') {
          setTrack(false);
          desactiveFollow();
          // -------------------- call map geometry data
          fetchMapGeoData(featureLayerData);
        }
      });

      view.on('mouse-wheel', () => {
        setTrack(false);
        // desactiveFollow();
        // -------------------- call map geometry data
        fetchMapGeoData(featureLayerData);
      });

      followButton.onclick = () => {
        if (innerFollow) {
          innerFollow = false;
        } else {
          innerFollow = true;
        }
      };

      // create the layer for the spatial points and the associating markers
      polylineGraphicLayer.addMany(pointGraphics);

      // view.graphics.addMany([firstPointGraphic, lastPointGraphic]);
      map.add(polylineGraphicLayer);

      // create the blue gpsMarker at the first coordinate on map load
      const startingCoord = {
        type: 'point',
        longitude: points[0].location[0],
        latitude: points[0].location[1],
      };
      // load the blue marker at the first coordinate of the spatial points array
      let updatedPoint = new Graphic({
        geometry: startingCoord,
        symbol: !points[0].heading ? currentSymbol : currentHeadingSymbol(points[0].heading),
      });

      // This function creates the dynamic point that follows the current time of the video
      // eslint-disable-next-line no-unused-vars
      const updateGraphic = (currentTime) => {
        view.graphics.remove(updatedPoint);
        updatedPoint = null;
        let updatedCoord = null;
        const newPoint = points[currentTime];

        if (newPoint) {
          updatedCoord = {
            type: 'point',
            longitude: newPoint.location[0],
            latitude: newPoint.location[1],
          };
          updatedPoint = new Graphic({
            geometry: updatedCoord,
            symbol: !newPoint.heading ? currentSymbol : currentHeadingSymbol(newPoint.heading),
          });
        }

        view.graphics.add(updatedPoint);
        // If the follow button is toggled, the motion of the current map point will be tracked.
        if (document.querySelector('#follow')?.classList.contains('follow-active')) {
          // However, tracking will only happen if the blue point is no longer visible on the map view.
          if (!view.extent.contains(updatedPoint.geometry)) {
            view.goTo({
              center: [updatedCoord.longitude, updatedCoord.latitude],
            });
          }
        }
      };

      if (videoRef) {
        const params = new URLSearchParams(window.location.search);
        selectedPoint = params.get('sequenceId') ? params.get('sequenceId') : selectedPoint;
        if (selectedPoint !== undefined && typeof selectedPoint !== 'object') {
          let timeout;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            if (videoRef && videoRef.current) videoRef.current.currentTime(selectedPoint);
            updateGraphic(selectedPoint);
          }, 2000);
        }
        // When the time updates on the video player every second, update the marker on the map with updateGraphic
        view.when(() => {
          let timeoout;
          clearTimeout(timeoout);
          timeoout = setTimeout(() => {
            if (!videoRef || !videoRef.current) return;
            videoRef.current.on('timeupdate', () => {
              const currentTime = videoRef.current.currentTime();
              const roundedTime = Math.round(currentTime);
              updateGraphic(roundedTime);
              setTime(roundedTime);
            });
          }, 2000);
        });

        // When a path point is clicked, set the time of the video to the point time that is clicked
        view.on('click', (event) => {
          view
            .hitTest(event, [polylineGraphicLayer])
            .then((response) => {
              const second = response.results[0].graphic.attributes;
              if (second !== undefined && typeof second !== 'object') {
                videoRef.current.currentTime(second);
              }
              if ((second.id && second.projectAssetSequenceId) || second.finding) {
                setObservation(second);
              }
              if (typeof second?.id === 'number') {
                view.popup.open({
                  title: second?.name,
                  location: event.mapPoint,
                  content: second?.description,
                });
              }
              if (second?.id && second?.classification) {
                setObservation(second);
              }
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error);
            });
          // eslint-disable-next-line no-underscore-dangle
        });
      } else {
        // photo onClick
        view.on('click', (event) => {
          view
            .hitTest(event, [polylineGraphicLayer])
            .then((response) => {
              const sequenceNumber = response.results[0].graphic.attributes;
              if (sequenceNumber !== undefined && sequenceNumber !== null && typeof sequenceNumber !== 'object') {
                dispatch(setSelectedSequence(sequenceNumber));
              }
              if ((sequenceNumber.id && sequenceNumber.projectAssetSequenceId) || sequenceNumber.finding) {
                setObservation(sequenceNumber);
              }
              if (typeof sequenceNumber?.id === 'number') {
                view.popup.open({
                  title: sequenceNumber?.name,
                  location: event.mapPoint,
                  content: sequenceNumber?.description,
                });
              }
              if (sequenceNumber?.id && sequenceNumber?.classification) {
                setObservation(sequenceNumber);
              }
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.error(error);
            });
          // eslint-disable-next-line no-underscore-dangle
        });
      }
      view.on('pointer-move', (event) => {
        view.hitTest(event).then((response) => {
          if (response.results.length > 0) {
            document.getElementById('mapContainer').style.cursor = 'pointer';
          } else {
            document.getElementById('mapContainer').style.cursor = 'default';
          }
        });
      });

      // Add effect to zoom out to the points layer
      view.when().then(() => {
        const { layers } = view.map;
        view.watch('updating', (value) => {
          if (!value) {
            layers.items.map((item) => {
              if (['graphics', 'kml'].includes(item.type)) {
                item.effect = [
                  {
                    scale: 10489297,
                    value: 'drop-shadow(0px, 0px, 50px, #FFC700) brightness(170%) blur(10px)',
                  },
                  {
                    scale: 2622324,
                    value: 'drop-shadow(2px, 2px, 3px) brightness(100%) blur(5px)',
                  },
                  {
                    scale: 108895.277144,
                    value: 'drop-shadow(0px, 0px, 0px)',
                  },
                ];
              }
              if (['kml'].includes(item.type)) {
                const path = item?.parsedUrl?.path.split('/');
                const type = path[6];
                if (['Points'].includes(type) && view.scale > 9027.977411) {
                  item.visible = false;
                } else {
                  item.visible = true;
                }
              }
              return item;
            });
          }
        });
      });

      return () => {
        polylineGraphicLayer.removeAll();
        observationLayer.removeAll();
        findingLayer.removeAll();
        observationsMapPoints.removeAll();
        map.remove(polylineGraphicLayer);
        map.remove(mapGeoFeatureLayerLines);
        map.remove(mapGeoLayer);
        map.remove(mapGeoFeatureLayer);
        map.remove(observationLayer);
        map.remove(findingLayer);
        map.remove(observationsMapPoints);
      };
    }
  }, [projectKmls]);

  const getCoords = () => {
    const { longitude, latitude } = coords;
    return `latitude=${latitude}&longitude=${longitude}`;
  };

  return (
    <div className={classes.container}>
      <ObservationPopup observation={observation} handleObservationClose={() => setObservation(false)} />
      <div className={`${classes.map} mapDiv`} id="mapContainer" ref={mapDiv} />
      <TrackZoom type="map" handleTrackZoomClick={handleTrackZoomClick} title={title} active={track} />
      <FollowButton onClick={toggleIsFollowing} locateIcon={locateIcon} title={title} active={follow} />
      {ctx.activePanel !== 'assetPanel' && coords && (
        <Link to={`/search?${getCoords()}`} target="_blank">
          <Button
            type="button"
            color="secondary"
            variant="contained"
            title="Proximity"
            className={classes.proximityButton}
          >
            <FontAwesomeIcon style={{ margin: '2px' }} icon={faSearch} /> Proximity
          </Button>
        </Link>
      )}
    </div>
  );
};
Map.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.arrayOf(PropTypes.number),
      sequenceIndex: PropTypes.number,
      heading: PropTypes.number,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  videoRef: PropTypes.shape({ current: PropTypes.object }),
  videoView: PropTypes.bool,
  sequenceLength: PropTypes.number,
  geometryOrgId: PropTypes.string,
  includeAccountGeometry: PropTypes.bool,
};

Map.defaultProps = {
  points: [],
  videoRef: null,
  videoView: false,
  sequenceLength: 0,
  geometryOrgId: null,
  includeAccountGeometry: false,
};
