import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ProjectService from 'services/ProjectService';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { DialogMessage } from 'components/DialogMessage';
import { roles, UserProjectRoleOptions } from 'components/UserProjectRoleOptions/UserProjectRoleOptions';
import { UserOrgRoleOptions } from 'components/UserOrgRoleOptions/UserOrgRoleOptions';
import { getProjects } from 'slices/adminProjectReducer';
import { fetchTeams } from 'slices/adminTeamsReducer';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { InfoIcon, LayersIcon } from 'components/Icons';
import OrganizationService from 'services/OrganizationService';
import TeamService from 'services/TeamService';
import { UserTeamRoleOptions } from 'components/UserTeamRoleOptions/UserTeamRoleOptions';

export const typeInviteData = {
  PROJET: 'project',
  TEAM: 'team',
  ORG: 'org',
};

const text = {
  project: 'Project',
  team: 'Team',
  org: 'Organization',
};

export const DialogAddUser = ({ isOpen, handleClose, type, userId, userName, initialValue }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const optionsData = useSelector((state) =>
    // eslint-disable-next-line no-nested-ternary
    type === typeInviteData.PROJET
      ? state.adminProjects.data
      : type === typeInviteData.ORG
      ? state.organizations.data
      : state.adminTeams.teams
  );
  const { filters } = useSelector((state) =>
    // eslint-disable-next-line no-nested-ternary
    type === typeInviteData.PROJET
      ? state.adminProjects
      : type === typeInviteData.ORG
      ? state.organizations
      : state.adminTeams
  );

  const validationSchema = Yup.object().shape({
    id: Yup.string().required(`${text[type]} is required`),
    role: type === typeInviteData.ORG ? Yup.array().required() : Yup.number().required('Role is required'),
  });

  useEffect(() => {
    switch (type) {
      case typeInviteData.PROJET:
        // eslint-disable-next-line no-case-declarations
        const sendFilters = {
          ...filters,
          sort_by: Object.values(filters.sort_by),
          page_size: 500,
        };
        dispatch(getProjects(sendFilters));
        break;
      case typeInviteData.TEAM:
        dispatch(fetchTeams({ page_size: 500 }));
        break;
      default:
        break;
    }
  }, []);

  const rootOptions = optionsData.map((proj) => ({
    label: type === typeInviteData.USER ? `${proj.firstName} ${proj.lastName}` : proj.name,
    value: proj.id,
  }));

  const submitFormData = async (values) => {
    setIsLoading(true);
    if (type === typeInviteData.ORG) {
      OrganizationService.updateUserRoles(values.id, userId, values.role)
        .then(() => {
          setIsLoading(false);
          handleClose(true);
        })
        .catch(() => {
          setIsLoading(false);
          setOpenDialog('An error occurred while trying to edit the user.');
        });
    }
    if (type === typeInviteData.TEAM) {
      TeamService.addTeamMember(values.id, userId, [Number(values.role)])
        .then(() => {
          setIsLoading(false);
          handleClose(true);
        })
        .catch(() => {
          setIsLoading(false);
          setOpenDialog('An error occurred while trying to edit the user.');
        });
    }
    if (type === typeInviteData.PROJET) {
      const userRoles = roles(values);
      ProjectService.updateUserOrTeamRoles(values.id, userId, userRoles, false)
        .then(() => {
          setIsLoading(false);
          handleClose(true);
        })
        .catch(() => {
          setIsLoading(false);
          setOpenDialog('An error occurred while trying to edit the user.');
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      role: 0,
    },
    validationSchema,
    onSubmit(values) {
      submitFormData(values);
    },
  });

  return (
    <>
      <Dialog open={isOpen} maxWidth="xs" onClose={() => handleClose(false)}>
        <DialogTitle sx={{ display: 'none' }} />
        <DialogContent sx={{ padding: '0px' }}>
          <div style={{ padding: '15px 25px 0 25px' }}>
            <ModalHeaderIcon icon={LayersIcon} text={`Add User to ${text[type]}`} />
            {(type === typeInviteData.TEAM || type === typeInviteData.PROJET) && (
              <>
                <Typography mb={1} mt={0.5}>
                  {`Select the ${type} you would like to add ${userName} to and set any special permissions`}
                </Typography>
                <InputLabel>{text[type]} *</InputLabel>
                <FormControl error={formik.touched.id && formik.errors.id} fullWidth>
                  <Autocomplete
                    options={rootOptions}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`Select ${text[type]}`}
                        onBlur={formik.handleBlur}
                        required
                        // error={!!(formik.touched.orgId && formik.errors.orgId)}
                      />
                    )}
                    onChange={(e, value) => {
                      formik.handleChange('id')(value?.value ? value?.value : '');
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText>{formik.touched.id && formik.errors.id ? formik.errors.id : null}</FormHelperText>
                </FormControl>
                <Box sx={{ padding: '10px 5px' }}>
                  {type === typeInviteData.TEAM && (
                    <UserTeamRoleOptions
                      formik={formik}
                      // checkBoxValidation={checkBoxValidation}
                      handleChange={(label, value) => formik.handleChange(label)(value)}
                      bySelf={false}
                    />
                  )}
                  {type === typeInviteData.PROJET && (
                    <UserProjectRoleOptions
                      formik={formik}
                      // checkBoxValidation={checkBoxValidation}
                      handleChange={(label, value) => formik.handleChange(label)(value)}
                      bySelf={false}
                    />
                  )}
                </Box>
              </>
            )}
            {type === typeInviteData.ORG && (
              <>
                <Typography mb={1} mt={0.5}>
                  Select the roles you would like to add to the user
                </Typography>
                <InputLabel>Organization roles</InputLabel>
                <Box sx={{ padding: '5px 0px' }}>
                  <UserOrgRoleOptions
                    initialRoles={initialValue.roles.map((el) => el.roleId)}
                    handleChange={(val) => {
                      formik.setValues({
                        id: initialValue.id,
                        role: val,
                      });
                    }}
                  />
                </Box>
              </>
            )}
            <LoadingOverlay loading={isLoading} />
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: '15px 25px 25px 25px' }}>
          <Grid container spacing={0.7}>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={() => handleClose(false)} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" variant="contained" onClick={formik.handleSubmit} fullWidth>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogMessage
        title={openDialog}
        isOpen={openDialog}
        icon={InfoIcon}
        confirmText="Ok"
        onConfirm={() => setOpenDialog(false)}
      />
    </>
  );
};
DialogAddUser.propTypes = {
  /**
   * is a boolean that checks if the modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * this is what action is needed to be handled when clicking confirm
   */
  handleClose: PropTypes.func,
  // get the specific data to link
  type: PropTypes.string,
  // id of user or team
  // specific text in the desscriptio
  userName: PropTypes.string,
  userId: PropTypes.string,
  initialValue: PropTypes.any,
};

DialogAddUser.defaultProps = {
  isOpen: false,
  handleClose: null,
  // projectId: '',
  // bySelf: false,
  type: '',
  userName: '',
  userId: '',
  initialValue: null,
};
