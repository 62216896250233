import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const MoreThanIcon = ({ size, className }) => (
  <Svg viewBox="0 0 16 16" size={size} className={className}>
    <path
      id="Icon"
      d="M6 12L10 8L6 4"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

MoreThanIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

MoreThanIcon.defaultProps = {
  size: 24,
  className: '',
};
