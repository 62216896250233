import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const CartovidLight = ({ size }) => (
  <Svg viewBox="0 0 159 32" size={size}>
    <path
      d="M22.7324 26.1624C22.8684 26.1624 22.984 26.2108 23.0793 26.3076L23.8955 27.1994C23.2969 27.8493 22.6439 28.4369 21.9365 28.9623C21.2291 29.4739 20.4537 29.9163 19.6102 30.2896C18.7804 30.6491 17.8621 30.9257 16.8554 31.1192C15.8623 31.3266 14.7672 31.4303 13.57 31.4303C11.5702 31.4303 9.7337 31.0778 8.0604 30.3726C6.4007 29.6536 4.97228 28.6443 3.77512 27.3446C2.59157 26.031 1.6665 24.4479 0.999898 22.5951C0.333299 20.7424 0 18.6822 0 16.4146C0 14.1885 0.340101 12.156 1.0203 10.3171C1.71411 8.47813 2.68 6.90189 3.91797 5.58837C5.16954 4.26101 6.65918 3.23784 8.3869 2.51885C10.1282 1.78604 12.0464 1.41963 14.1414 1.41963C15.1889 1.41963 16.148 1.50259 17.0187 1.66851C17.8893 1.82061 18.6988 2.04874 19.447 2.35293C20.2088 2.64329 20.923 3.0097 21.5896 3.45215C22.2562 3.8946 22.9092 4.40619 23.5486 4.98691L22.916 5.9202C22.8072 6.08612 22.6371 6.16908 22.4059 6.16908C22.2834 6.16908 22.127 6.09995 21.9365 5.96168C21.7461 5.80959 21.5012 5.62984 21.2019 5.42245C20.9162 5.20122 20.5625 4.96617 20.1408 4.71729C19.7327 4.45458 19.2429 4.21953 18.6716 4.01213C18.1002 3.7909 17.4404 3.61116 16.6922 3.47289C15.9439 3.3208 15.0937 3.24475 14.1414 3.24475C12.3865 3.24475 10.7744 3.55585 9.30517 4.17805C7.83593 4.78642 6.57076 5.66441 5.50964 6.81202C4.44852 7.94581 3.61868 9.32847 3.0201 10.96C2.43512 12.5777 2.14264 14.3959 2.14264 16.4146C2.14264 18.4886 2.43512 20.3414 3.0201 21.9729C3.60507 23.6045 4.41451 24.9871 5.44842 26.1209C6.48233 27.2409 7.69989 28.105 9.10111 28.7134C10.5159 29.308 12.0464 29.6052 13.6925 29.6052C14.7264 29.6052 15.6447 29.5361 16.4473 29.3978C17.2635 29.2596 18.0118 29.0522 18.692 28.7756C19.3722 28.4991 20.0048 28.1603 20.5897 27.7594C21.1747 27.3584 21.7529 26.8883 22.3242 26.3491C22.3923 26.2938 22.4535 26.2523 22.5079 26.2246C22.5759 26.1831 22.6507 26.1624 22.7324 26.1624Z"
      fill="#FCFCFD"
    />
    <path
      d="M43.067 31.0985C42.6589 31.0985 42.4072 30.9049 42.312 30.5178L42.0263 27.8838C41.4685 28.4369 40.9108 28.9346 40.353 29.3771C39.8088 29.8195 39.2375 30.1929 38.6389 30.497C38.0403 30.8012 37.3941 31.0294 36.7003 31.1815C36.0065 31.3474 35.2447 31.4303 34.4148 31.4303C33.721 31.4303 33.0476 31.3266 32.3946 31.1192C31.7416 30.9118 31.1635 30.5938 30.6601 30.1652C30.1568 29.7366 29.7487 29.1904 29.4358 28.5268C29.1365 27.8493 28.9868 27.0404 28.9868 26.1002C28.9868 25.2291 29.2317 24.4202 29.7214 23.6736C30.2112 22.927 30.973 22.2771 32.0069 21.724C33.0544 21.171 34.3944 20.7285 36.0269 20.3967C37.673 20.0649 39.6456 19.8713 41.9447 19.816V17.659C41.9447 15.7509 41.5366 14.2853 40.7203 13.2621C39.9177 12.2252 38.7205 11.7067 37.1288 11.7067C36.1494 11.7067 35.3127 11.8449 34.6189 12.1215C33.9387 12.398 33.3605 12.7022 32.8844 13.034C32.4082 13.3658 32.0205 13.67 31.7212 13.9466C31.422 14.2231 31.1703 14.3614 30.9662 14.3614C30.6941 14.3614 30.4901 14.2369 30.354 13.988L30.0071 13.3866C31.0682 12.3081 32.1838 11.4923 33.3537 10.9393C34.5237 10.3862 35.8501 10.1097 37.3329 10.1097C38.4212 10.1097 39.3735 10.2894 40.1898 10.6489C41.006 10.9946 41.6794 11.4993 42.21 12.1629C42.7541 12.8128 43.1622 13.6078 43.4343 14.548C43.7064 15.4744 43.8424 16.5114 43.8424 17.659V31.0985H43.067ZM34.9046 29.9993C35.6936 29.9993 36.4146 29.9163 37.0676 29.7504C37.7342 29.5707 38.3464 29.3287 38.9042 29.0245C39.4755 28.7065 40.0061 28.3332 40.4959 27.9046C40.9856 27.4759 41.4685 27.0127 41.9447 26.515V21.1433C40.0129 21.1986 38.3464 21.3507 36.9452 21.5996C35.5576 21.8347 34.408 22.1596 33.4966 22.5744C32.5987 22.9892 31.9321 23.4869 31.4968 24.0677C31.075 24.6346 30.8642 25.2844 30.8642 26.0172C30.8642 26.7086 30.973 27.31 31.1907 27.8216C31.422 28.3194 31.7212 28.7342 32.0886 29.066C32.4559 29.384 32.8844 29.6191 33.3741 29.7712C33.8639 29.9232 34.374 29.9993 34.9046 29.9993Z"
      fill="#FCFCFD"
    />
    <path
      d="M50.5949 31.0985V10.4623H51.6152C51.8465 10.4623 52.0098 10.5106 52.105 10.6074C52.2138 10.7042 52.275 10.8701 52.2886 11.1052L52.4519 15.4606C53.1049 13.7876 53.9755 12.4809 55.0639 11.5407C56.1522 10.5867 57.4922 10.1097 59.0839 10.1097C59.6961 10.1097 60.2606 10.1788 60.7776 10.3171C61.2945 10.4415 61.7843 10.6282 62.2468 10.8771L61.9815 12.2459C61.9407 12.4671 61.8047 12.5777 61.5734 12.5777C61.4918 12.5777 61.3762 12.5501 61.2265 12.4948C61.0769 12.4395 60.8864 12.3772 60.6551 12.3081C60.4375 12.239 60.1654 12.1768 59.8389 12.1215C59.5124 12.0661 59.1383 12.0385 58.7166 12.0385C57.1793 12.0385 55.9141 12.5224 54.921 13.4903C53.9415 14.4582 53.1457 15.8546 52.5335 17.6798V31.0985H50.5949Z"
      fill="#FCFCFD"
    />
    <path
      d="M73.0043 31.4303C71.6303 31.4303 70.5556 31.0432 69.7802 30.2689C69.0183 29.4946 68.6374 28.2986 68.6374 26.6809V12.5777H65.7193C65.5697 12.5777 65.4473 12.5362 65.352 12.4533C65.2568 12.3703 65.2092 12.2528 65.2092 12.1007V11.3333L68.6782 11.0845L69.168 3.70103C69.1816 3.57659 69.2292 3.46598 69.3108 3.36919C69.406 3.2724 69.5285 3.22401 69.6781 3.22401H70.5964V11.1052H76.9835V12.5777H70.5964V26.5772C70.5964 27.1441 70.6644 27.6349 70.8005 28.0497C70.9501 28.4507 71.1474 28.7825 71.3922 29.0453C71.6507 29.308 71.95 29.5015 72.2901 29.626C72.6302 29.7504 72.9975 29.8126 73.392 29.8126C73.8818 29.8126 74.3035 29.7435 74.6572 29.6052C75.0109 29.4531 75.317 29.2941 75.5755 29.1282C75.834 28.9485 76.0448 28.7895 76.2081 28.6512C76.3713 28.4991 76.5006 28.4231 76.5958 28.4231C76.7046 28.4231 76.8134 28.4922 76.9223 28.6305L77.4528 29.5015C76.9359 30.0823 76.2761 30.5524 75.4735 30.9118C74.6844 31.2575 73.8614 31.4303 73.0043 31.4303Z"
      fill="#FCFCFD"
    />
    <path
      d="M90.3727 10.1304C91.8284 10.1304 93.1276 10.3862 94.2703 10.8978C95.4267 11.3956 96.3993 12.1076 97.1884 13.034C97.9774 13.9604 98.576 15.0803 98.9841 16.3939C99.4058 17.6936 99.6167 19.1523 99.6167 20.77C99.6167 22.3877 99.4058 23.8464 98.9841 25.1461C98.576 26.4458 97.9774 27.5589 97.1884 28.4853C96.3993 29.4117 95.4267 30.1237 94.2703 30.6215C93.1276 31.1192 91.8284 31.3681 90.3727 31.3681C88.9171 31.3681 87.6111 31.1192 86.4548 30.6215C85.312 30.1237 84.3394 29.4117 83.5367 28.4853C82.7477 27.5589 82.1423 26.4458 81.7206 25.1461C81.3125 23.8464 81.1084 22.3877 81.1084 20.77C81.1084 19.1523 81.3125 17.6936 81.7206 16.3939C82.1423 15.0803 82.7477 13.9604 83.5367 13.034C84.3394 12.1076 85.312 11.3956 86.4548 10.8978C87.6111 10.3862 88.9171 10.1304 90.3727 10.1304ZM90.3727 29.8126C91.5835 29.8126 92.6378 29.6052 93.5357 29.1904C94.4472 28.7618 95.2022 28.1534 95.8008 27.3653C96.413 26.5772 96.8687 25.6301 97.168 24.5239C97.4673 23.404 97.6169 22.1527 97.6169 20.77C97.6169 19.4012 97.4673 18.1568 97.168 17.0368C96.8687 15.9169 96.413 14.9628 95.8008 14.1747C95.2022 13.3728 94.4472 12.7575 93.5357 12.3288C92.6378 11.9002 91.5835 11.6859 90.3727 11.6859C89.162 11.6859 88.1009 11.9002 87.1894 12.3288C86.2915 12.7575 85.5365 13.3728 84.9243 14.1747C84.3258 14.9628 83.87 15.9169 83.5571 17.0368C83.2578 18.1568 83.1082 19.4012 83.1082 20.77C83.1082 22.1527 83.2578 23.404 83.5571 24.5239C83.87 25.6301 84.3258 26.5772 84.9243 27.3653C85.5365 28.1534 86.2915 28.7618 87.1894 29.1904C88.1009 29.6052 89.162 29.8126 90.3727 29.8126Z"
      fill="#FCFCFD"
    />
    <path
      d="M102.357 1.75147H104.01C104.2 1.75147 104.357 1.80678 104.479 1.91739C104.602 2.01418 104.697 2.14553 104.765 2.31145L114.6 26.6602C114.859 27.2962 115.07 27.9737 115.233 28.6927C115.383 27.946 115.58 27.2685 115.825 26.6602L125.64 2.31145C125.708 2.15936 125.803 2.02801 125.926 1.91739C126.062 1.80678 126.225 1.75147 126.416 1.75147H128.048L116.131 31.0985H114.274L102.357 1.75147Z"
      fill="#FFA826"
    />
    <path
      d="M134.809 10.4623V31.0985H132.87V10.4623H134.809ZM135.666 3.34845C135.666 3.59733 135.612 3.83238 135.503 4.05361C135.407 4.26101 135.278 4.44767 135.115 4.61359C134.952 4.77951 134.761 4.91086 134.544 5.00765C134.326 5.10443 134.095 5.15283 133.85 5.15283C133.605 5.15283 133.374 5.10443 133.156 5.00765C132.938 4.91086 132.748 4.77951 132.585 4.61359C132.421 4.44767 132.292 4.26101 132.197 4.05361C132.102 3.83238 132.054 3.59733 132.054 3.34845C132.054 3.09957 132.102 2.86452 132.197 2.64329C132.292 2.40824 132.421 2.20775 132.585 2.04183C132.748 1.87591 132.938 1.74456 133.156 1.64777C133.374 1.55099 133.605 1.50259 133.85 1.50259C134.095 1.50259 134.326 1.55099 134.544 1.64777C134.761 1.74456 134.952 1.87591 135.115 2.04183C135.278 2.20775 135.407 2.40824 135.503 2.64329C135.612 2.86452 135.666 3.09957 135.666 3.34845Z"
      fill="#FFA826"
    />
    <path
      d="M157.008 31.0985C156.668 31.0985 156.47 30.9188 156.416 30.5593L156.212 27.3031C155.314 28.5475 154.26 29.5361 153.049 30.2689C151.852 31.0017 150.505 31.3681 149.009 31.3681C146.519 31.3681 144.574 30.4901 143.173 28.7342C141.785 26.9782 141.091 24.3442 141.091 20.8322C141.091 19.3251 141.282 17.9217 141.662 16.622C142.057 15.3085 142.628 14.1747 143.377 13.2207C144.138 12.2528 145.063 11.4923 146.152 10.9393C147.254 10.3862 148.519 10.1097 149.947 10.1097C151.321 10.1097 152.512 10.3724 153.518 10.8978C154.525 11.4094 155.396 12.1629 156.13 13.1584V0.921875H158.089V31.0985H157.008ZM149.56 29.7712C150.906 29.7712 152.124 29.4186 153.212 28.7134C154.301 28.0083 155.273 27.0266 156.13 25.7683V14.8799C155.341 13.7046 154.471 12.875 153.518 12.3911C152.58 11.9071 151.512 11.6652 150.315 11.6652C149.117 11.6652 148.07 11.8864 147.172 12.3288C146.274 12.7713 145.519 13.4004 144.907 14.2162C144.308 15.0181 143.853 15.986 143.54 17.1198C143.241 18.2397 143.091 19.4772 143.091 20.8322C143.091 23.9017 143.649 26.1624 144.764 27.6142C145.88 29.0522 147.478 29.7712 149.56 29.7712Z"
      fill="#FFA826"
    />
  </Svg>
);

CartovidLight.propTypes = {
  size: PropTypes.number,
};

CartovidLight.defaultProps = {
  size: 159,
};
