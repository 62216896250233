import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UpdateButton } from 'smartComponents/ModalProjectSettings/UpdateButton';
import { Typography } from '@mui/material';
import { ProjectRetentionForm } from 'smartComponents/ModalProjectSettings/ProjectRetentionForm';
import { useStyles } from '../styles';

const validationSchema = Yup.object({
  retentionMonths: Yup.number(),
  overrideRetention: Yup.boolean(),
});

export const ProjectRetention = ({ project }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const [change, setChange] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      retentionMonths: project.retentionMonths,
      overrideRetention: project.overrideRetention,
    },
    validationSchema,
    enableReinitialize: true,
  });

  const stringView = () => {
    if (project.accountOverrideRetention) {
      if (project.accountRetentionMonths < 1) {
        return ['Your Account', 'to Never Archive', false];
      }
      return [
        'Your Account',
        `${
          project.accountRetentionMonths === 12 || project.accountRetentionMonths === 18
            ? `${project.accountRetentionMonths} Months`
            : `${project.accountRetentionMonths / 12} Years`
        }`,
        true,
      ];
    }
    return ['The System default', '3 Years', true];
  };

  return (
    <div>
      <Typography mb={0.3}>Content Retention policy</Typography>
      <Typography color="primary.300" paragraph align="justify">
        {stringView()[0]} retention policy is {stringView()[1]}.
        {stringView()[2] ? ' Content of that age will automatically be archived. ' : ' '}It will require extra credits
        to view content that has been archived. Changing this will only affect non-archived content.
      </Typography>
      <div className={classes.sharingContent}>
        <ProjectRetentionForm
          formik={formik}
          loading={loading}
          overrideLabel={project.accountOverrideRetention ? 'Account' : 'System'}
          handleChange={(value) => setChange(value)}
        />
        {submitError && (
          <Typography color="error" mt={0.5} mb={0.5}>
            {submitError}.
          </Typography>
        )}
      </div>
      <div className={classes.visibilityButton}>
        <UpdateButton
          values={formik.values}
          projectId={project.id}
          setLoading={(value) => setLoading(value)}
          setSubmitError={(value) => setSubmitError(value)}
          handleChange={(value) => setChange(value)}
          isChanging={change}
          detail
        />
      </div>
    </div>
  );
};

ProjectRetention.propTypes = {
  project: PropTypes.object,
};

ProjectRetention.defaultProps = {
  project: { id: null, name: '' },
};
